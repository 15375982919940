import React, { useEffect, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./CompanyMembers.settingsForm";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";

import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
/*
This component get all facilities, transporters, supervisors or inspectors within user company
 with additional text fields to fill their info in as per user request
*/
const CompanyUsersCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });
	const [t, i18n] = useTranslation();
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event
			let state = { ...myState }

			//get the associated record from options based on the value selected and set it in form data
			let selectedValues = state["options"].filter((v) => v.value === newValue)[0];

			if (selectedValues) {
				let json = { value: newValue };
				//fill value with the fields requested
				if (props.component.companyGrid) {
					for (let i = 0; i < props.component.companyGrid.length; i++) {
						json[props.component.companyGrid[i].companyFieldPath] = selectedValues[props.component.companyGrid[i].companyFieldPath];
					}
				}
				json["label"] = selectedValues.label;
				state["selectedValues"] = json;
				setMyState(state);
				props.setComponentState(state);
				props.onChange(json, null);
			} else {
				state["selectedValues"] = {};
				setMyState(state);
				props.setComponentState(state);
				props.onChange("", null);
			}
		}
	};
	const initialize = () => {
		let state = { ...myState }
		let initialValue = props.component.defaultValue || props.form._data[props.component.key];
		i18next.language=i18next.language.includes("-")? i18next.language.split("-")[0]:i18next.language;
		if (state.initialDisabled !== props.disabled) {
			//if component has default value and so its additional fields, get their values and set them in state
			if (initialValue) {
				state["selectedValues"] = initialValue;

				let label = state["selectedValues"]?.label;
				let value = state["selectedValues"]?.value;

				//label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
				label=props.form.i18next.store.data[i18next.language][label]||[label]
			//	Object.keys(props.form._form.settings.translatedData).map((key) => {
					//let allLabels = props.form._form.settings.translatedData[key];
					let allLabels = props.form.i18next.store.data[i18next.language].translation;
					Object.keys(allLabels).map((key2) => {
						if (allLabels[key2] === label) {
						//	label = props.form._form.settings.translatedData[props.form._form.settings.language][key2];
							label =props.form.i18next.store.data[i18next.language].translation[key2];
						}
					});

		//		});

				state["selectedValues"].value = props.disabled === true ? state["selectedValues"]?.label : value;
			}
			else {
				state["selectedValues"] = {};
			}
			state.initialDisabled = props.disabled;
			//if component has no default value and it is required get options
			if (props.disabled !== true) {
				if (!state.loaded) {
					loadData(state);
				}
				else {
					setMyState(state);
					props.setComponentState(state);
				}
			} else {
				let options = [
					{
						label: state["selectedValues"]?.label,
						value: props.disabled === true ? state["selectedValues"]?.label : state["selectedValues"]?.value,
					},
				];
				state["options"] = options;
				setMyState(state);
				props.setComponentState(state);

			}
		}
		else {
			let newValue = props.disabled ? initialValue?.label : initialValue?.value;
			if (props.disabled === false && state?.selectedValues?.value !== newValue) {
				state.selectedValues = initialValue || {};
				state.label = initialValue?.label
				state.value = newValue

				
				setMyState(state);
				props.setComponentState(state);
			}

		}
	};

	useEffect(() => {
		initialize();
	}, [props.disabled , props.form]);



	const loadData = async (state) => {
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		}
		let companyID = props.component.formDataCompanyIdPath ? _.get(props.form?._form?.settings?.data, props.component.formDataCompanyIdPath, "") : encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";

		fetch(Constants.base_url+props.component.requestUrl + "/&&companyId=eq." + companyID + "&&tbl_users.tbl_roles_users.roleId=eq." + props.component.roleName, { method: "GET", headers: headers })
			.then((response) => response.json())
			.then((res) => {
				let path = props.component.dataPath || "";
				let result = path ? _.get(res, path, "") : res;

				let options = result?.map((e) => ({
					label: _.get(e, props.component.labelPath || "", ""),
					value: _.get(e, props.component.valuePath || "", ""),
					...e,
				}));
				if (props.component.checkEmpty) {
					options = options.filter((u) => u[props.component.checkEmpty].length !== 0);
				}
				if (props.component.isHiddenDropdown === true && options.length > 0) {
					state.selectedValues = options[0];
					props.onChange(state.selectedValues, null);
				}
				state["options"] = options;
				state.loaded = true;


				setMyState(state);
				props.setComponentState(state);
			})
			.then(() => { })
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	//add page data in global form data
	useEffect(() => {

		let keys = Object.keys(props.data);
		for (let i = 0; i < keys.length; i++) {
			props.form._data[keys[i]] = props.data[keys[i]];
			props.form._submission.data[keys[i]] = props.data[keys[i]];
		}

		if (props.insideGrid === true) {
			delete props.form._data[props.component.key];
			delete props.form._submission.data[props.component.key];
		}
	}, [props.data]);
	return (
		<div>
			<div className="companyUsersCustomComp">
				{props.component.isHiddenDropdown !== true && <label style={{ display: t(props.component.dropdownLabel) ? "block" : "none",fontSize:"16px" }}>
					{props.component.translateData && props.form._form.settings ? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] : t(props.component.dropdownLabel)}
				</label>}
				{
					props.component.isHiddenDropdown !== true && <CustomSelect
						component={props.component}
						disabled={props.disabled && props.disabled === true}
						value={myState["selectedValues"].value}
						options={myState.options}
						updateValue={updateValue}
					/>
				}


				{props.component.companyGrid?.map((grid, index) => {
					return (
						!grid.isHidden && (
						
							<div key={props.component.id + "_" + index} className="col-md-4 companyCustomComp_custom-group">
								<label className="companyCustomComp_label">
								{props.form._form.settings && props.form._form.settings.translatedData
								? props.form._form.settings.translatedData[props.form._form.settings.language]
									? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] || grid.companyFieldName
									: grid.companyFieldName
								: grid.companyFieldName}

								</label>
								{/* <input type="text" className="companyUsersCustomComp_label" value={myState.selectedValues[grid.companyFieldPath] || ""} disabled={true} /> */}

								<textarea
									className="companyCustomComp_textarea"
									value={myState.selectedValues[grid.companyFieldPath] || ""}
									disabled={true}
								/>
							</div>
							
						)
					)
				})}
			</div>
		</div>
	);
};

export default class CompanyMembers extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			initialDisabled: null,
			requestUrl: "",
			loaded: false,
			loading: false,
			selectedValues: {},
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "companyMembersCustomComp",
			label: "Company Members",
		});
	}
	static get builderInfo() {
		return {
			title: "Company Members",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: CompanyMembers.schema(),
		};
	}
	static editForm = settingsForm;


	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		let tempComponent = this.component;
		const root = createRoot(element);
		let grid = "";
		let disabled = this.component.disabled;

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid" && !insideGrid) {
					grid = component;
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		if (insideGrid) {
			if (grid.editRows[this.rowIndex]?.state === "new") {
				this.component.defaultValue = null;
				grid.editRows[this.rowIndex].data[key] = null;
				this.options["oldState"].initializedInsideGrid = true;
				this.options["oldState"].rowIndex = this.rowIndex;
			} else {
				this.component.defaultValue = grid.editRows[this.rowIndex]?.data[key] || rootForm._data[this.rowIndex]?.data[key];
			}
		}
		Utils.eachComponent(
			rootForm?.currentPanel?.components,
			function (component) {
				if (component.components) {
					Utils.eachComponent(
						component.components,
						function (component) {

							if (component.input) {
								if (component.key === key) {
									disabled = component.disabled
								}
							} else if (component.type === "columns") {
								Utils.eachComponent(
									component.columns,
									function (component2) {
										if (component2.input) {
											if (component2.key === key) {
												disabled = component2.disabled


											}
										}
									},
									true
								);
							}

						},
						true
					);
				}
				else {
					if (component.columns) {
						Utils.eachComponent(
							component.columns,
							function (component2) {
								if (component2.input) {
									if (component2.key === key) {

										disabled = component2.disabled


									}
								}
							},
							true
						);
					}
					else{
						if (component.input) {
							if (component.key === key) {
								disabled = component.disabled
							}
						} 
					}
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);
			root.render(
				<CompanyUsersCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					form={rootForm}
					data={this.data}
					insideGrid={insideGrid}
					tempComponent={tempComponent}
					disabled={disabled}

					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		}

		root.render(
			<CompanyUsersCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				disabled={disabled}

				form={rootForm}
				data={this.data}
				insideGrid={insideGrid}
				tempComponent={tempComponent}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}


}
