import { RegistrationProcessDefinitionKey, DeleteProcessInstance } from "common/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "components/common/Table";
import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
//import { startProcess } from "components/workflow/VerifyEmailWorkflow";
import { useNavigate } from "react-router-dom";
import { parse } from "date-fns";
import {
	getCountTaskByAssignee,
	continueProcess,
	formatDate,
	getTaskVariables,
	getWorkflowListByAssignee,
	getWorkflowsHistoryByProcessInstance,
	modifyProcessInstanceVariable,
	parseDateAndTime,
	getDatabaseRecord,
	getTask,
	getExecutionLocalVariables,
	getAllDatabaseRecords,
	getFormById,
	executePendingTasks,
	getWorkflowListByGroups,
	getWorkflowListByUsers,
	claimTask,
} from "./WorkflowHelper";
import * as Constants from "common/constants";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import db from "indexedDB";
import * as em from "common/ErrorMessages";
import jwtDecode from "jwt-decode";
import { EncryptStorage } from "encrypt-storage";
import * as General from "common/general";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NavbarVerticalMenuItem from "components/navbar/vertical/NavbarVerticalMenuItem";

import { useDispatch, useSelector } from "react-redux";
import { DECREMENT_WORKFLOW_UNASSIGNED } from "reducers/flagUnassignedReducer";
import { INCREMENT_WORKFLOW } from "reducers/flagReducer";
import store from "reducers/store";
import LoaderIcon from "components/custom/Preloader";
import UpliftCss from "../common/upliftcss.module.css";

function GetWorkflowListByCandidateGroup() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const navigate = useNavigate();

	const [isCurrentUserEmail, setCurrentUserEmail] = useState("");
	const [isGeneratorAdmin, setisGeneratorAdmin] = useState(false);
	// const [isabledelete, setisabledelete] = useState(false);
	const [task_id, settask_id] = useState("");
	const [table_name, settable_name] = useState("");
	const [emanifestId, setemanifestId] = useState("");
	const [fetchFlag, setFetchFlag] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const [showNotification, setShowNotification] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);
	const [isFinish, setIsFinish] = useState(false);

	const [modal, setModal] = useState(false);
	const openModal = (id, tablename, emanifestid) => {
		setModal(!modal);
		settask_id(id);
		settable_name(tablename);
		setemanifestId(emanifestid);
	};

	const toggle = () => setModal(!modal);
	const [workflows, setWorkflows] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [processInformation, setProcessInformation] = useState("");
	const [t, i18n] = useTranslation();
	// add table headers and accessor is the name of the field in the json
	const parseDate = (dateString) => parse(dateString, "dd-MM-yyyy HH:mm:ss", new Date());
	const columns = [
		{
			Header: t("Process ID"),
			accessor: "generatedEmanifestID",
		},
		{ Header: t("Process Name"), accessor: "name" },
		{ Header: t("User Task Name"), accessor: "taskName" },
		{
			Header: t("Creation Date"),
			accessor: "created",
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.created);
				const dateB = parseDate(rowB.values.created);
				return dateA - dateB;
			},
		},
		{ Header: t("Action"), accessor: "action" },
	];
	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();
	const handleStartDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setStartDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setStartDateFilter("");
		}
	};
	const handleEndDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setEndDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setEndDateFilter("");
		}
	};
	const filterdata = (data) => {
		let filter = [];
		data.map((workflow) => {
			const searchgeneratedEmanifestID = t(workflow.generatedEmanifestID);
			const searchname = t(workflow.name);
			const searchtaskName = t(workflow.taskName);
			if (startDateFilter && endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isBetween(moment(startDateFilter, "DD-MM-YYYY"), moment(endDateFilter, "DD-MM-YYYY"), null, "[]")) {
					filter.push(workflow);
				}
			} else if (startDateFilter && !endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isSameOrAfter(moment(startDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else if (!startDateFilter && endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isSameOrBefore(moment(endDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else {
				filter.push(workflow);
			}
		});

		return filter;
	};

	useEffect(() => {
		let NewData = filterdata(originalData);
		setTableData(NewData);
		setWorkflows(NewData);
	}, [startDateFilter, endDateFilter]);

	useEffect(() => {
		async function fetchData() {
			setIsFinish(true);

			const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
			const loggedInUser = userInfo.userId;
			const companyId = encryptStorage1.getItem(Constants.SelectedCompany);
			let workflows = [];
			const workflowListbyUsers = await getWorkflowListByUsers(loggedInUser);
			const workflowListbyGroups = await getWorkflowListByGroups(companyId);

			const workflowList = [...(workflowListbyUsers.workflowsByAssigneeList || []), ...(workflowListbyGroups.workflowsByAssigneeList || [])];
			if (workflowList) {
				workflows = await Promise.all(
					workflowList.map(async (workflow) => {
						workflow.taskId = workflow.id;
						const executionId = workflow.executionId;
						const tableName = workflow.processDefinition.key;
						const processInstanceId = workflow.processInstanceId;
						const taskVariables = await getTaskVariables(processInstanceId);
						let executionLocalVariables = await getExecutionLocalVariables(executionId);

						const startUserId = taskVariables.find((variable) => variable.name === "startUserId");
						if (startUserId && startUserId.value === loggedInUser) {
							workflow.enableDelete = true;
						}

						executionLocalVariables = executionLocalVariables;

						if (executionLocalVariables.gridInformation !== undefined) {
							let grid_information = "";
							for (const element of Object.values(executionLocalVariables.gridInformation.value)) {
								grid_information += ", " + element;
							}
							workflow.processInformation = grid_information;
							setProcessInformation(grid_information);
						}

						let generatedEmanifestID = taskVariables.find((variable) => variable.name === "generatedEmanifestID");
						if (generatedEmanifestID != undefined) {
							workflow.generatedEmanifestID = generatedEmanifestID.value;
						}

						return workflow;
					})
				);
			}
			workflows.sort((a, b) => {
				const dateA = new Date(
					formatDate(a.created).slice(6, 10),
					formatDate(a.created).slice(3, 5) - 1,
					formatDate(a.created).slice(0, 2),
					formatDate(a.created).slice(11, 13),
					formatDate(a.created).slice(14, 16),
					formatDate(a.created).slice(17, 19)
				);
				const dateB = new Date(
					formatDate(b.created).slice(6, 10),
					formatDate(b.created).slice(3, 5) - 1,
					formatDate(b.created).slice(0, 2),
					formatDate(b.created).slice(11, 13),
					formatDate(b.created).slice(14, 16),
					formatDate(b.created).slice(17, 19)
				);
				return dateB - dateA; // Sort descending by date
			});

			for (let i = 0; i < workflows.length; i = i + 1) {
				workflows[i].created = moment(new Date(workflows[i].created)).format("DD-MM-YYYY HH:mm:ss");
				workflows[i].taskName = t(workflows[i].name);
				workflows[i].name = t(workflows[i].processDefinition.name) + (workflows[i].processInformation ? t(workflows[i].processInformation) : "");

				workflows[i].action = (
					<>
						{
							<OverlayTrigger
								overlay={
									<Tooltip style={{ position: "fixed" }} id="idsubmit">
										{t(em.execute_task)}
									</Tooltip>
								}
							>
								<div className="theme-control-toggle-label icon-position">
									<IconButton
										className={`play-hover  ${UpliftCss.forceButtonColorwhite}`}
										iconClassName="fs--2"
										variant="falcon-default"
										size="sm"
										icon="fa fa-play"
										style={{
											margin: "auto 0.25rem",
											backgroundColor: "#00a862",
											borderColor: "#00a862",
										}}
										onClick={() => assignTask(workflows[i])}
									></IconButton>
								</div>
							</OverlayTrigger>
						}
					</>
				);
				workflows[i].index = i;
				//	console.log("WF",workflows[i].index,"  :  ",workflows[i].generatedEmanifestID, "  i:",i)
			}

			if (!originalData || originalData.length === 0) {
				setOriginalData(workflows);
			}

			var sortedAssigneeList = workflows.sort((a, b) => {
				const dateA = new Date(
					formatDate(a.created).slice(6, 10), // YYYY
					formatDate(a.created).slice(3, 5) - 1, // MM (subtract 1 because months are zero-based)
					formatDate(a.created).slice(0, 2), // DD
					formatDate(a.created).slice(11, 13), // HH
					formatDate(a.created).slice(14, 16), // mm
					formatDate(a.created).slice(17, 19) // ss
				);

				const dateB = new Date(
					formatDate(b.created).slice(6, 10),
					formatDate(b.created).slice(3, 5) - 1,
					formatDate(b.created).slice(0, 2),
					formatDate(b.created).slice(11, 13),
					formatDate(b.created).slice(14, 16),
					formatDate(b.created).slice(17, 19)
				);
				return dateB - dateA; // Compare as Date objects
			});
			setIsFinish(false);
			setTableData(sortedAssigneeList);
		}
		fetchData();
	}, [fetchFlag]);

	const assignTask = async (workflow) => {
		const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		const loggedInUser = userInfo.userId;
		await claimTask(workflow.taskId, loggedInUser);
		store.dispatch({ type: DECREMENT_WORKFLOW_UNASSIGNED });
		store.dispatch({ type: INCREMENT_WORKFLOW });

		toast.success(t("Successfully Assigned"), {
			position: "top-right",
			autoClose: 3000,
		});
		navigate("/userTasks?generatedEmanifestId=" + workflow.generatedEmanifestID);
	};

	const viewSummary = async (workflow) => {
		if (workflow.hyperLinkVariables) {
			let form = await getFormById(formData.hyperLinkVariables.formKey, 1, formData.hyperLinkVariables, true, 1);
			if (form.status === 0) {
				return;
			}
			formData = {
				...JSON.parse(form.data.formData),
			};

			navigate("/workflow-history-view", { state: { data: formData } });
		}
	};

	return (
		<div className="container">
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(em.unassigne_task)}</h5>
					</div>
				</div>
			</div>
			{isFinish && <LoaderIcon />}
			<div className="row">
				<div className="col-12">
					{/* <h1>Workflow List</h1>*/}
					<div className={`card ${UpliftCss.BodyBorder}`}>
						<div className="card-body">
							<div className={`mb-3 ${UpliftCss.tableHeader}`}>
								<AdvanceTableWrapper columns={columns} data={tableData} sortable pagination perPage={10}>
									<Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox table className={`${UpliftCss.filterFieldBoxes}`} placeholder={t("Search")} />
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("Start Date")}
												selected={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : ""}
												maxDate={startDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleStartDateChange(date)}
											/>
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("End Date")}
												selected={endDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : ""}
												minDate={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleEndDateChange(date)}
											/>
										</Col>
									</Row>
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: "fs--1 mb-0 overflow-hidden",
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter rowCount={tableData.length} table rowInfo navButtons rowsPerPageSelection />
									</div>
								</AdvanceTableWrapper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GetWorkflowListByCandidateGroup;
