import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
    return baseEditForm(
        [
            {
                label: "Display",
                key: "display",
                weight: 0,
                components: [],
            },
            {
                label: "Data",
                key: "data",
                weight: 20,

                components: [
                    {
                        type: "editgrid",
                        label: "Dependencies",
                        key: "dependencies",
                        input: true,
                        inline: true,
                        templates: {
                            header:
                                '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
                            tableHeader:
                                '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
                            row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
                            tableRow:
                                '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
                            footer: "",
                        },
                        components: [
                            {
                                type: "columns",
                                label: "Data",
                                key: "columns",
                                input: true,
                                inline: true,
                                columns: [
                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [
                                            {
                                                type: "textfield",
                                                label: "Request Url",
                                                key: "requestUrl",
                                                input: true,
                                                inline: true,
                                                validate: {
                                                    required: true,
                                                },
                                            },
                                            {
                                                type: "textfield",
                                                label: "Response Data Path",
                                                key: "responseDataPath",
                                                input: true,
                                                inline: true,

                                            },
                                        ],
                                    },

                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [
                                            {
                                                type: "editgrid",
                                                label: "Dependent Api Keys",
                                                key: "dependentApiKeys",
                                                input: true,
                                                modal: true,
                                                inline: true,
                                                templates: {
                                                    header:
                                                        '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
                                                    tableHeader:
                                                        '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
                                                    row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
                                                    tableRow:
                                                        '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
                                                    footer: "",
                                                },
                                                components: [
                                                    {
                                                        type: "columns",
                                                        label: "Data",
                                                        key: "columns",
                                                        input: true,
                                                        inline: true,
                                                        columns: [
                                                            {
                                                                currentWidth: 4,
                                                                width: 4,
                                                                components: [
                                                                    {
                                                                        type: "textfield",
                                                                        label: "Field Form Api",
                                                                        key: "fieldFormApi",
                                                                        input: true,
                                                                        inline: true,
                                                                        validate: {
                                                                            required: true,
                                                                        },
                                                                    },
                                                                ],
                                                            },

                                                            {
                                                                currentWidth: 4,
                                                                width: 4,
                                                                components: [
                                                                    {
                                                                        type: "textfield",
                                                                        label: "Field Database Column Name",
                                                                        key: "fieldDatabaseColumnName",
                                                                        input: true,
                                                                        inline: true,
                                                                        validate: {
                                                                            required: true,
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                currentWidth: 4,
                                                                width: 4,
                                                                components: [
                                                                    {
                                                                        type: "checkbox",
                                                                        label: "Append To Url",
                                                                        key: "appendToUrl",
                                                                        input: true,
                                                                        inline: true,

                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },

                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [
                                            {
                                                type: "textfield",
                                                label: "Label Path",
                                                key: "labelPath",
                                                tooltip: "Option Label in dropdown",
                                                input: true,
                                                inline: true,
                                                validate: {
                                                    required: true,
                                                },
                                            },
                                            {
                                                type: "textfield",
                                                label: "Value Path",
                                                key: "valuePath",
                                                tooltip: "Option Value in dropdown",
                                                input: true,
                                                inline: true,
                                                validate: {
                                                    required: true,
                                                },
                                            },

                                        ],
                                    },
                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [

                                            {
                                                type: "checkbox",
                                                label: "Translate Data",
                                                key: "translateData",
                                                input: true,
                                                inline: true,

                                            },

                                        ],
                                    },
                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [
                                            {
                                                type: "editgrid",
                                                label: "Response Fields To Append into Component Value",
                                                key: "appendedResponseFields",
                                                input: true,
                                                modal: true,
                                                inline: true,
                                                templates: {
                                                    header:
                                                        '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
                                                    tableHeader:
                                                        '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
                                                    row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
                                                    tableRow:
                                                        '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
                                                    footer: "",
                                                },
                                                components: [
                                                    {
                                                        type: "columns",
                                                        label: "Data",
                                                        key: "columns",
                                                        input: true,
                                                        inline: true,
                                                        columns: [
                                                            {
                                                                currentWidth: 4,
                                                                width: 4,
                                                                components: [
                                                                    {
                                                                        type: "textfield",
                                                                        label: "Field Name",
                                                                        key: "fieldName",
                                                                        input: true,
                                                                        inline: true,
                                                                        validate: {
                                                                            required: true,
                                                                        },
                                                                    },
                                                                ],
                                                            },


                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        currentWidth: 4,
                                        width: 4,
                                        components: [
                                            {
                                                type: "editgrid",
                                                label: "Set Chosen Option Label to Another Component as its Value",
                                                key: "componentsToFill",
                                                input: true,
                                                modal: true,
                                                inline: true,
                                                templates: {
                                                    header:
                                                        '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
                                                    tableHeader:
                                                        '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
                                                    row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
                                                    tableRow:
                                                        '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
                                                    footer: "",
                                                },
                                                components: [
                                                    {
                                                        type: "columns",
                                                        label: "Data",
                                                        key: "columns",
                                                        input: true,
                                                        inline: true,
                                                        columns: [
                                                            {
                                                                currentWidth: 4,
                                                                width: 4,
                                                                components: [
                                                                    {
                                                                        type: "textfield",
                                                                        label: "Field Api Key",
                                                                        key: "fieldApi",
                                                                        input: true,
                                                                        inline: true,
                                                                        validate: {
                                                                            required: true,
                                                                        },
                                                                    },
                                                                ],
                                                            },


                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        currentWidth: 4,
                                        width: 4,

                                        components: [
                                            {
                                                type: "content",
                                                html: "<label class='col-form-label'>Apply Options Filtering based on grid (Show already selected options)</label>",
                                                key: "content",
                                                input: true,
                                                inline: true,

                                            },
                                            {
                                                type: "textfield",
                                                label: "Grid Api Key",
                                                key: "gridApiKey",
                                                input: true,
                                                inline: true,

                                            },
                                            {
                                                type: "textfield",
                                                label: "Field Grid Path",
                                                key: "fieldGridDataPath",
                                                input: true,
                                                inline: true,

                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "checkbox",
                        label: "Add the DefaultValue option: 'initial value the first option in the list'",
                        key: "isDefaultValue",
                        input: true,
                        inline: true
                    },
                    {
                        type: "number",
                        label: "Default Value",
                        key: "fieldDefaultValue",
                        input: true,
                        inline: true,
                        defaultValue: 0,
                        conditional: {
                            json: {
                                "===": [
                                    { var: "data.isDefaultValue" }, true
                                ]
                            }
                        }
                    }
                ],
            },

            {
                label: "Validation",
                key: "validation",
                weight: 20,
                components: [],
            },
            {
                label: "API",
                key: "api",
                weight: 30,
                components: [],
            },
            {
                label: "Logic",
                key: "logic",
                weight: 50,
                components: [],
            },
            {
                label: "Layout",
                key: "layout",
                weight: 60,
                components: [],
            },
        ],
        ...extend
    );
};
