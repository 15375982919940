import React, { useContext, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import Logo from "components/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
/*import routes from 'routes/siteMaps';*/
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
//import PurchaseCard from './PurchaseCard';
import bgNavbar from "assets/img/generic/bg-navbar.png";
import * as General from "common/general";
import { useTranslation } from "react-i18next";

import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import TimerSessions from "components/timersessions/timerSessions";
import jwtDecode from "jwt-decode";
import UpliftCss from "../../common/upliftcss.module.css";

const NavbarVertical = () => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);

	const [routes, setRoutes] = useState([]);
	const [isFirst, setIsFirst] = useState(0);
	const [t, i18n] = useTranslation();
	const {
		setConfig,
		config: { navbarPosition, navbarStyle, isNavbarVerticalCollapsed, showBurgerMenu, systemProperties },
	} = useContext(AppContext);

	const HTMLClassList = document.getElementsByTagName("html")[0].classList;

	const [showTimer, setShowTimer] = useState(true);

	useEffect(() => {
		// CheckToken();
		GetSiteMaps();
		GetSystemProperties();

		if (isNavbarVerticalCollapsed) {
			HTMLClassList.add("navbar-vertical-collapsed");
		} else {
			HTMLClassList.remove("navbar-vertical-collapsed");
		}
		return () => {
			HTMLClassList.remove("navbar-vertical-collapsed-hover");
		};
	}, [isNavbarVerticalCollapsed, HTMLClassList]);

	//Control mouseEnter event

	//Control mouseEnter event
	let time = null;
	const handleMouseEnter = () => {
		if (isNavbarVerticalCollapsed) {
			time = setTimeout(() => {
				HTMLClassList.add("navbar-vertical-collapsed-hover");
			}, 100);
		}
	};

	const GetSystemProperties = () => {
		const GetSystemPropertiesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							let responseData = responseObject.data.dataBaseContent;
							setConfig("systemProperties", responseData);
							encryptStorage1.setItem("systemProperties", responseData);

							isSuccess = true;
							// General.SaveToken(responseObject);
						}
					}
				}
			}

			if (isSuccess == false) {
			} else {
			}
		};

		const GetSystemPropertiesFailure = (GetSystemPropertiesFailure) => {};

		var endPoint = Constants.GetAllEndPoint + "/" + Constants.SystemPropertiesTableName;

		General.GetFromEndPoint(endPoint, null, null, GetSystemPropertiesSuccess, GetSystemPropertiesFailure);
	};

	const GetSiteMaps = () => {
		const GetSiteMapsSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					setRoutes(responseObject.data);
					isSuccess = true;
				} else {
					console.log("error response");
				}
			}

			if (isSuccess == false) {
			} else {
			}
		};

		const GetSiteMapsFailure = (GetSiteMapsFailure) => {};
		var domain_id = 0;

		if (localStorage.getItem("domain") == "localhost") {
			switch (localStorage.getItem("platform")) {
				case "inspection":
					domain_id = 3;
					break;
				case "manifest":
					domain_id = 4;
					break;
			}
		} else {
			domain_id = localStorage.getItem("domain_id");
		}

		//      var endPoint = Constants.GetAllEndPoint + "/" + Constants.SiteMapsTableName;
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		const decodedAccessToken = jwtDecode(accessToken);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);

		var endPoint = Constants.GetMenu + domain_id + "?userId=" + decodedAccessToken.sub + "&companyId=" + selectedCompany;
		General.GetFromEndPoint(endPoint, null, null, GetSiteMapsSuccess, GetSiteMapsFailure);
	};

	const handleMouseLeave = () => {
		clearTimeout(time);
		HTMLClassList.remove("navbar-vertical-collapsed-hover");
	};

	const NavbarLabel = ({ label }) => {
		// <Nav.Item as="li">
		// 	<Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
		// 		<Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
		// 			{label}
		// 		</Col>
		// 		<Col className="ps-0">
		// 			<hr className="mb-0 navbar-vertical-divider"></hr>
		// 		</Col>
		// 	</Row>
		// </Nav.Item>
		return (
			<Nav.Item as="li">
				<Row className={`mt-3 mb-2 navbar-vertical-label-wrapper `}>
					<Col xs="" className={`navbar-vertical-label navbar-vertical-label ${UpliftCss.navLabelgreen}  ${UpliftCss.navCapitilize} ${UpliftCss.navBold}`}>
						{label}
					</Col>
				</Row>
			</Nav.Item>
		);
	};

	return (
		<Navbar
			expand={navbarBreakPoint}
			className={classNames("navbar-vertical", {
				[`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
			})}
			variant="light"
		>
			<TimerSessions />
			<Flex alignItems="center">
				<ToggleButton />
				{/* <Logo at="navbar-vertical" width={140} /> */}
			</Flex>

			<Navbar.Collapse
				in={showBurgerMenu}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{
					backgroundImage: navbarStyle === "vibrant" ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})` : "none",
				}}
			>
				<div className={`navbar-vertical-content scrollbar ${isDark ? UpliftCss.forceBackgrounddark : UpliftCss.forceBackgroundwhite}`}>
					<Nav className="flex-column" as="ul">
						{routes.map((route) => (
							<Fragment key={route.label}>
								{!route.labelDisable && <NavbarLabel label={capitalize(t(route.label))} />}
								<NavbarVerticalMenu routes={route.children} />

								<hr className={`mb-0 navbar-vertical-divider ${UpliftCss.hrColor}`}></hr>
							</Fragment>
						))}
					</Nav>

					<>
						{navbarPosition === "combo" && (
							<div className={`d-${topNavbarBreakpoint}-none`}>
								<div className="navbar-vertical-divider">
									<hr className="navbar-vertical-hr my-2" />
								</div>
								<Nav navbar>
									<NavbarTopDropDownMenus />
								</Nav>
							</div>
						)}
						{/*<PurchaseCard />*/}
					</>
				</div>
			</Navbar.Collapse>
		</Navbar>
	);
};

NavbarVertical.propTypes = {
	label: PropTypes.string,
};

export default NavbarVertical;
