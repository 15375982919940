import React from "react";
import { Card, Col, Row, Form } from "react-bootstrap";
import events from "../../../data/events/events";
import DownloadPdf from "./DownloadPdf";
import Flex from "components/common/Flex";
import { useTranslation } from "react-i18next";

const PdfDownload = () => {
	const [t, i18n] = useTranslation();
	return (
		<Card>
			<Card.Header as={Flex} justifyContent="between" alignItems="center">
				<h3 className="mb-0">{t("Download Manuals")}</h3>
			</Card.Header>
			<Card.Body className="fs--1">
				<Row>
					{Array.from({ length: 6 }, (_, index) => (
						<Col key={index} md={4} className="h-100">
							{/* <DownloadPdf title={t("Downlaod Pdf")} isLast={index === events.length} /> */}
							<DownloadPdf title={t("Downlaod Pdf")} />
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	);
};

export default PdfDownload;
