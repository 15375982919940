import React from "react";
import axios from "axios";
import {
	CompleteTaskEndPoint,
	GetExternalServiceTaskEndPoint,
	GetFormByIdEndPoint,
	GetFormByUserTaskEndPoint,
	GetServiceTask,
	FetchAndLockEndPoint,
	CompleteExternalTaskEndPoint,
	GetAllWorflowsEndpoint,
	CreateRegistrationEndPoint,
	SendNotificationEndPoint,
	PostEndPoint,
	PutEndPoint,
	VerifyEmailValidityEndPoint,
	PatchEndPoint,
	GetWorkflowListByAssigneeEndPoint,
	ClaimTaskEndPoint,
	PutProcessInstanceVariableEndPoint,
	CreateUserEndPoint,
	GetTaskVariables,
	UploadWorkflowEndPoint,
	EditUserEndPoint,
	EditUserWithoutIdEndPoint,
	GetWorkflowListHistoryByAssigneeEndPoint,
	GetWorkflowsHistoryByProcessInstance,
	ModifyProcessInstanceVariableEndPoint,
	GetEndPoint,
	CreateGroupEndPoint,
	CreateSubmissionEndPoint,
	GetCountTaskByAssigneeEndPoint,
	GetCountUnAssignedTaskEndPoint,
} from "common/constants";
import jQuery from "jquery";
import { GetFromEndPoint, PostToEndPoint, PatchToEndPoint, encryptStorage1 } from "common/general";
import moment from "moment";
import * as Constants from "common/constants";
import db from "indexedDB";
import { handleSubmit, handleTaskSubmit } from "components/forms/FormViewRenderer";
import { Utils } from "formiojs";
import { EncryptStorage } from "encrypt-storage";

export async function getFormByUserTask(processInstanceId) {
	const getFormByUserTask = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(GetFormByUserTaskEndPoint + processInstanceId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return getFormByUserTask;
}
export async function printPDF(formId, submissionId) {
	let response = null;
	await fetch(Constants.ExportPdfUrl + "/" + formId + "/submission/" + submissionId + "/export/pdf?timezone=Asia/Riyadh", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({}),
	})
		.then((res) => res.blob())
		.then((data) => {
			response = data;
		})
		.catch((err) => {});
	return response;
}

export async function getFormByUserTaskWithAssignee(processInstanceId, assignee) {
	const getFormByUserTask = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(GetFormByUserTaskEndPoint + processInstanceId + "/" + assignee, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return getFormByUserTask;
}

export async function getExternalServiceTask(processInstanceId) {
	const getExternalServiceTask = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		GetFromEndPoint(GetExternalServiceTaskEndPoint + processInstanceId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return getExternalServiceTask;
}

export async function fetchAndLock(topicName) {
	const fetchAndLock = await new Promise((resolve, reject) => {
		const PostSuccessCallBack = (response) => {
			resolve(response);
		};

		const PostFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(FetchAndLockEndPoint + topicName, {}, {}, PostSuccessCallBack, PostFailureCallBack);
	});
	return fetchAndLock;
}

export async function completeExternalTask(taskId, withError = false) {
	const completeExternalTask = await new Promise((resolve, reject) => {
		const PostSuccessCallBack = (response) => {
			resolve(response);
		};

		const PostFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(CompleteExternalTaskEndPoint + taskId + "/" + withError, {}, {}, PostSuccessCallBack, PostFailureCallBack);
	});
	return completeExternalTask;
}

export async function post(formData, tableName) {
	let response = null;
	const filesArray = [];
	for (const key in formData) {
		if (Array.isArray(formData[key]) && formData[key].length > 0) {
			const fileName = formData[key][0].name;
			const base64Data = formData[key][0].url.split(",")[1];
			const newFile = {
				fileName: fileName,
				data: base64Data,
			};
			filesArray.push(newFile);
			//formData = Object.fromEntries(  Object.entries(formData).filter(([keys]) => keys !== key));
			formData = Object.fromEntries([...Object.entries(formData), [key, fileName]]);
		}
	}
	//TO BE REMOVED AFTER THE DEMO ASAP
	let tobeRemovedCreate = false;
	if (tableName === "company") {
		tobeRemovedCreate = true;
	} else {
		tobeRemovedCreate = false;
	}
	const requestBody = {
		databasePostData: {
			tableName: tableName,
			fields: formData,
			files: filesArray,
			isCreate: tobeRemovedCreate,
		},
	};
	await fetch(PostEndPoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {});
	return response;
}

export async function createUser(formData, companyID, groupId) {
	let response = null;
	formData = {
		...formData,
		enabled: false,
		emailVerified: false,
		temporary: false,
		groupIDs: [groupId],
	};
	const requestBody = {
		userData: formData,
	};
	await fetch(CreateUserEndPoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function createGroup(companyName, companyID, companyType) {
	let response = null;
	const requestBody = {
		language: "en",
		group: {
			name: companyName,
			attributes: {
				DB_ID: [companyID],
			},
			parentGroupID: "",
		},
		dbParentGroupID: companyType,
	};
	await fetch(CreateGroupEndPoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function putProcessInstanceVariable(processInstanceId, variableName, json) {
	let response = null;

	await fetch(PutProcessInstanceVariableEndPoint + "/" + processInstanceId + "/" + variableName, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(json),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function modifyProcessInstanceVariable(processInstanceId, json) {
	let response = null;
	await fetch(ModifyProcessInstanceVariableEndPoint + "/" + processInstanceId, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(json),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function verifyEmailValidity(userName, hash, requestDateTime) {
	let response = null;
	const requestBody = {
		verifyEmailData: {
			userNameOrEmail: userName,
			hash: hash,
			dt: requestDateTime,
		},
	};
	await fetch(VerifyEmailValidityEndPoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function put(fields, tableName, fieldId) {
	let response = null;
	const requestBody = {
		databasePutData: {
			tableName: tableName,
			fields: fields,
			ItemId: fieldId,
		},
	};
	await fetch(PutEndPoint, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function editUser(email, enabled, emailVerified) {
	let response = null;
	const requestBody = {
		language: "en",
		userData: {
			enabled: enabled,
			emailVerified: emailVerified,
			email: email,
		},
	};
	await fetch(EditUserWithoutIdEndPoint, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function patch(fields, tableName, fieldId) {
	let response = null;
	let data = { ...fields };
	const requestBody = {
		databasePutData: {
			tableName: tableName,
			fields: JSON.parse(JSON.stringify(data)),
			ItemId: fieldId,
		},
	};
	await fetch(PatchEndPoint, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			console.log(data);
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function sendNotification(language, to, name, body) {
	const requestBody = {
		language: language,
		NotificationsData: {
			name: name,
			to: {
				email: to,
			},
			verificationEmail: to,
			payload: body,
		},
	};
	const sendNotification = await new Promise((resolve, reject) => {
		const PostSuccessCallBack = (response) => {
			resolve(response);
		};

		const PostFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(SendNotificationEndPoint, {}, JSON.stringify(requestBody), PostSuccessCallBack, PostFailureCallBack);
	});
	return sendNotification;
}

export async function sendSms(to, smsBody) {
	const requestBody = smsBody;
	const sendNotification = await new Promise((resolve, reject) => {
		const PostSuccessCallBack = (response) => {
			resolve(response);
		};

		const PostFailureCallBack = (response) => {
			reject(response);
		};

		PostToEndPoint(Constants.SendSmsEndPoint + "/" + to, {}, requestBody, PostSuccessCallBack, PostFailureCallBack);
	});
	return sendNotification;
}

export async function getFormById(form_key, pageNumber, formData, readonly = false, startingPage = -1, dataReadOnly = false, hideSubmitButton = null) {
	const getFormById = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			reject(response);
		};

		if ((pageNumber === null || pageNumber === undefined) && readonly === false) {
			PostToEndPoint(
				GetFormByIdEndPoint +
					form_key +
					"?dataReadOnly=" +
					dataReadOnly +
					(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
				{},
				{},
				GetSuccessCallBack,
				GetFailureCallBack
			);
		} else if (readonly === false && (pageNumber !== null || pageNumber !== undefined) && (startingPage === null || startingPage === undefined || startingPage === -1)) {
			PostToEndPoint(
				GetFormByIdEndPoint +
					form_key +
					"?pageNumber=" +
					pageNumber +
					"&dataReadOnly=" +
					dataReadOnly +
					(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
				{},
				JSON.stringify(formData),
				GetSuccessCallBack,
				GetFailureCallBack
			);
		} else if (readonly === false && (pageNumber !== null || pageNumber !== undefined) && (startingPage !== null || startingPage !== undefined || startingPage !== -1)) {
			PostToEndPoint(
				GetFormByIdEndPoint +
					form_key +
					"?pageNumber=" +
					pageNumber +
					"&initialPage=" +
					startingPage +
					"&dataReadOnly=" +
					dataReadOnly +
					(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
				{},
				JSON.stringify(formData),
				GetSuccessCallBack,
				GetFailureCallBack
			);
		} else if (readonly === true && (pageNumber === null || pageNumber === undefined)) {
			//	PostToEndPoint(GetFormByIdEndPoint + form_key + "?readonly=" + readonly + "&dataReadOnly=" + dataReadOnly+(hideSubmitButton!==null&&'&hideSubmitButton='+hideSubmitButton), {}, JSON.stringify(formData), GetSuccessCallBack, GetFailureCallBack);
			PostToEndPoint(
				GetFormByIdEndPoint +
					form_key +
					"?readonly=" +
					readonly +
					"&dataReadOnly=" +
					dataReadOnly +
					(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
				{},
				JSON.stringify(formData),
				GetSuccessCallBack,
				GetFailureCallBack
			);
		} else {
			if (startingPage != -1) {
				PostToEndPoint(
					GetFormByIdEndPoint +
						form_key +
						"?pageNumber=" +
						pageNumber +
						"&readonly=" +
						readonly +
						"&dataReadOnly=" +
						dataReadOnly +
						"&initialPage=" +
						startingPage +
						(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
					{},
					JSON.stringify(formData),
					GetSuccessCallBack,
					GetFailureCallBack
				);
			} else {
				PostToEndPoint(
					GetFormByIdEndPoint +
						form_key +
						"?pageNumber=" +
						pageNumber +
						"&readonly=" +
						readonly +
						"&dataReadOnly=" +
						dataReadOnly +
						(hideSubmitButton !== null && hideSubmitButton !== undefined && hideSubmitButton !== "" ? "&hideSubmitButton=" + hideSubmitButton : ""),
					{},
					JSON.stringify(formData),
					GetSuccessCallBack,
					GetFailureCallBack
				);
			}
		}
	});
	return getFormById;
}

export async function getTaskVariables(processInstanceId) {
	const getTaskVariables = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			console.log(response);
			reject(response);
		};

		GetFromEndPoint(GetTaskVariables + processInstanceId, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return getTaskVariables;
}

export async function getAllWorkflows() {
	const getAllWorkflows = await new Promise((resolve, reject) => {
		const GetSuccessCallBack = (response) => {
			resolve(response);
		};

		const GetFailureCallBack = (response) => {
			console.log(response);
			reject(response);
		};

		GetFromEndPoint(GetAllWorflowsEndpoint, {}, {}, GetSuccessCallBack, GetFailureCallBack);
	});
	return getAllWorkflows;
}

export async function uploadWorkflow(formData) {
	const requestBody = {
		body: formData,
	};

	const uploadWorkflow = await new Promise((resolve, reject) => {
		const PostSuccessCallBack = (response) => {
			resolve(response);
		};
		const PostFailureCallBack = (response) => {
			console.log(response);
			reject(response);
		};
		PostToEndPoint(UploadWorkflowEndPoint, {}, requestBody, PostSuccessCallBack, PostFailureCallBack);
	});
	return uploadWorkflow;
}

export async function getWorkflowListByAssignee(assignee) {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(GetWorkflowListByAssigneeEndPoint + assignee, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getPendingTasks(assignee, indexDbIds, pageNumber = 1, recordsByPage = 10, searchParam = "", startDate = "", endDate = "") {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			PostToEndPoint(
				Constants.GetPendingTasksEndPoint + assignee + `?pageNumber=${pageNumber}&recordsByPage=${recordsByPage}`,
				{},
				indexDbIds,
				PostSuccessCallBack,
				PostFailureCallBack
			);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getWorkflowListByGroups(userId) {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(Constants.GetWorkflowListByGroupsEndPoint + userId, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getWorkflowListByUsers(groupId) {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(Constants.GetWorkflowListByUsersEndPoint + groupId, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getDatabaseRecord(tableName, fieldName, itemId) {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(GetEndPoint + "/" + tableName + "/" + fieldName + "/" + itemId, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getAllDatabaseRecords(tableName, condition) {
	try {
		//CheckIfTokenExists();

		const workflowList = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(Constants.GetAllEndPoint + "/" + tableName + "/" + condition, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowList;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getExecutionLocalVariables(executionId) {
	try {
		//CheckIfTokenExists();

		const executionLocalVariables = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			GetFromEndPoint(Constants.GetExecutionLocalVariableEndPoint + "/" + executionId, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return executionLocalVariables;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getWorkflowListHistoryByAssignee(
	assignee,
	platform,
	selectedCompany,
	enableModifyStatus = false,
	pageNumber = 1,
	recordsByPage = 10,
	searchParam = "",
	startDate = "",
	endDate = ""
) {
	try {
		//CheckIfTokenExists();

		let response = null;
		let url = "";
		if (selectedCompany) {
			url = `${GetWorkflowListHistoryByAssigneeEndPoint}${assignee}/${platform}/${selectedCompany}/${enableModifyStatus}?pageNumber=${pageNumber}&recordsByPage=${recordsByPage}`;
		} else {
			url = `${GetWorkflowListHistoryByAssigneeEndPoint}${assignee}/${platform}/${enableModifyStatus}?pageNumber=${pageNumber}&recordsByPage=${recordsByPage}`;
		}

		if (searchParam) {
			url += `&searchParam=${encodeURIComponent(searchParam)}`;
		}
		if (startDate) {
			url += `&startDateTime=${encodeURIComponent(startDate)}`;
		}
		if (endDate) {
			url += `&endDateTime=${encodeURIComponent(endDate)}`;
		}

		await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				response = data;
			})
			.catch((err) => {
				console.log(err.message);
			});

		return response;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getWorkflowsSubprocess(processInstanceId) {
	try {
		//CheckIfTokenExists();

		let response = null;

		await fetch(Constants.GetWorkflowsSubprocessEndPoint + processInstanceId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				response = data;
			})
			.catch((err) => {
				console.log(err.message);
			});

		return response;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getWorkflowsHistoryByProcessInstance(processInstanceId) {
	try {
		//CheckIfTokenExists();
		let workflowListHistory = null;

		await fetch(GetWorkflowsHistoryByProcessInstance + processInstanceId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				workflowListHistory = data;
			})
			.catch((err) => {
				console.log(err.message);
			});
		return workflowListHistory;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function continueProcess(executionLocalVariables, taskVariables, taskID, processInstanceId, formKey, taskDefinitionKey, tableName, executionId) {
	try {
		//CheckIfTokenExists();

		// var taskVariables = await getTaskVariables(processInstanceId);
		var exist = false;
		var result = null;

		if (executionLocalVariables.recordInTableId !== undefined) {
			var recordInTableId = executionLocalVariables.recordInTableId.value;

			var databaseRecord = await getAllDatabaseRecords(
				tableName,
				"subProcessAssignee=eq." + recordInTableId.split(".")[0] + "&assignedFacility=eq." + recordInTableId.split(".")[1] + "&processInstanceId=eq." + processInstanceId
			);

			for (let i = 0; i < databaseRecord.data?.dataBaseContent.length; i++) {
				result = databaseRecord.data.dataBaseContent[i];
				exist = true;
				break;
			}
		}

		if (!exist) {
			result = taskVariables.reduce((result, item) => {
				result[item.name] = item.value;
				return result;
			}, {});
		}

		let getEndPointResponse = null;
		var dataReadOnly = false;
		var hideSubmitButton = false;
		if (executionLocalVariables.hasOwnProperty("readOnly")) {
			dataReadOnly = executionLocalVariables.readOnly.value;
		}
		if (executionLocalVariables.hasOwnProperty("hideSubmitButton")) {
			hideSubmitButton = executionLocalVariables.hideSubmitButton.value;
		}
		if (taskDefinitionKey.includes("-")) {
			if (taskDefinitionKey.split("-").length === 3) {
				getEndPointResponse = await getFormById(formKey, taskDefinitionKey.split("-")[1], result, false, taskDefinitionKey.split("-")[2], dataReadOnly, hideSubmitButton);
			} else {
				getEndPointResponse = await getFormById(formKey, taskDefinitionKey.split("-")[1], result, false, null, dataReadOnly, hideSubmitButton);
			}
		} else {
			getEndPointResponse = await getFormById(formKey);
		}

		let formData = {};
		if (getEndPointResponse.data) {
			formData = {
				...JSON.parse(getEndPointResponse.data.formData),
				taskID,
				processInstanceId,
			};
			let components = JSON.parse(getEndPointResponse.data.formData).components;
			Utils.eachComponent(
				components,
				function (component) {
					if (component.input) {
						if (component.type === "userCompanyCustomComp") {
							getData(component, result);
							renderCompanyData(component);
						}
					} else if (component.type === "columns") {
						Utils.eachComponent(
							component.columns,
							function (component2) {
								if (component2.type === "userCompanyCustomComp") {
									getData(component2, result);
									renderCompanyData(component);
								}
							},
							true
						);
					}
				},
				true
			);
		}

		return formData;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export const getData = async (component, result) => {
	if (component.companyRequestUrl) {
		let companyID = encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";
		let requestedUrl = component.companyRequestUrl + "/";
		let licenseCompanyRequestUrl = component.licenseCompanyRequestUrl;

		if (!component.queryParamsFromForm || component.queryParamsFromForm.length === 0) {
			requestedUrl += "tbl_company/companyId=eq." + companyID;
		} else {
			if (component.queryParamsFromForm) {
				for (let i = 0; i < component.queryParamsFromForm.length; i++) {
					let queryParamName = component.queryParamsFromForm[i].queryParamsFieldName;
					let queryParamValue = _.get(result, component.queryParamsFromForm[i].queryParamsFieldPath, "");
					requestedUrl += (i !== 0 ? "&&" : "") + queryParamName + "=eq." + queryParamValue;
				}
			}
		}
		let res = await fetchCompanyData(licenseCompanyRequestUrl || requestedUrl);
	}
};

const fetchCompanyData = async (requestedUrl) => {
	try {
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		}
		const response = await fetch(requestedUrl, {
			method: "GET",
			headers: headers,
		});

		if (!response) {
			console.log("error");
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.log(error);
		return null; // You may want to handle errors appropriately
	}
};

export const renderCompanyData = async (component) => {
	if (component.companyGrid) {
		if (component.companyGrid.length !== 0) {
			let grid = component.companyGrid;
			for (let i = 0; i < grid.length; i++) {
				let companyFieldType = grid[i].companyFieldType || "textfield";
				let companyFieldRequestedTable = grid[i].companyFieldRequestedTable;

				if (companyFieldType === "dropdown") {
					let data = [];
					if (companyFieldRequestedTable) {
						try {
							await fetchData(companyFieldRequestedTable);
						} catch (error) {
							console.error("Error fetching dropdown data:", error);
						}
					}
				}
			}
		}
	}
};

const fetchData = async (requestedTable) => {
	try {
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		}
		let url = Constants.GetAllEndPoint + "/" + requestedTable;

		const response = await fetch(url, {
			method: "GET",
			headers: headers,
		});

		const res = await response.json();

		if (res) {
			return res.data.dataBaseContent;
		}
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error; // Propagate the error to the calling function
	}
};

export async function claimTask(taskID, userID) {
	try {
		//CheckIfTokenExists();

		const claimTask = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			PostToEndPoint(ClaimTaskEndPoint + taskID + "/" + userID, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		const formData = {};

		return formData;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

function isValidDateFormat(date, format) {
	return moment(date, format, true).isValid();
}

export function formatDate(date) {
	try {
		if (isValidDateFormat(date, "YYYY-MM-DDTHH:mm:ss.SSSZ")) {
			return moment(new Date(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ"))).format("DD-MM-YYYY HH:mm:ss");
		} else {
			return moment(new Date(moment(date, "DD-MM-YYYY HH:mm:ss"))).format("DD-MM-YYYY HH:mm:ss");
		}
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export function parseDateAndTime(dateString) {
	const [datePart, timePart] = dateString.split(" "); // Split date and time
	const [day, month, year] = datePart.split("-").map(Number);
	const [hours, minutes, seconds] = timePart.split(":").map(Number);

	// Create a Date object with the parsed components
	return new Date(year, month - 1, day, hours, minutes, seconds);
}

export async function createSubmission(formKey, data) {
	let response = null;
	await fetch(CreateSubmissionEndPoint + "/" + formKey, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function createCompany(userId, data, authorization) {
	let response = null;
	await fetch(Constants.CreateCompanyEndPoint + "/" + userId, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + authorization,
		},
		body: JSON.stringify(data),
	})
		.then((res) => res.json())
		.then((data) => {
			console.log(data);
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function sendOtp(to, processInstanceId) {
	let response = null;
	await fetch(Constants.SendOtpEndPoint + "/" + processInstanceId + "/" + to, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.then((data) => {
			console.log(data);
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function executePendingTasks(setSubmitted, setshowPreloader, setShowNotification, navigate, location, t) {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const tempSubmissionData = await db.submission_data.toArray();
	var isLastIteration = false;
	let onlineStatusFormData = null;
	for (let i = 0; i < tempSubmissionData.length; i = i + 1) {
		onlineStatusFormData = await db.pending_tasks.get(parseInt(JSON.parse(tempSubmissionData[i].submissionData).index, 10));
		onlineStatusFormData = JSON.parse(onlineStatusFormData.pendingTasks);
		const { index, ...newObject } = JSON.parse(tempSubmissionData[i].submissionData);
		if (i === tempSubmissionData.length - 1) {
			await db.table("submission_data").clear();
			await db.table("pending_tasks").clear();
			isLastIteration = true;
		}
		handleTaskSubmit(newObject, onlineStatusFormData, setSubmitted, setshowPreloader, null, location, setShowNotification, null, navigate, null, encryptStorage1, t, isLastIteration);

		const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
		await delay(2000);
	}
}

export async function getTask(taskId) {
	try {
		//CheckIfTokenExists();

		let response = null;

		await fetch(Constants.GetTaskEndPoint + taskId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				response = data;
			})
			.catch((err) => {
				console.log(err.message);
			});

		return response;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function getCountTaskByAssignee(assignee) {
	try {
		//CheckIfTokenExists();

		const workflowCount = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				reject(response);
			};

			GetFromEndPoint(GetCountTaskByAssigneeEndPoint + assignee, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowCount;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export function replacePlaceholders(inputString, placeholderMap) {
	// Create a regular expression to match placeholders like {{USERNAME}}
	var placeholderRegex = /{{(.*?)}}/g;

	// Replace each matched placeholder with the corresponding value from the map
	var replacedString = inputString.replace(placeholderRegex, function (match, placeholder) {
		// Check if the placeholder exists in the map
		if (placeholderMap.hasOwnProperty(placeholder)) {
			// If yes, replace the placeholder with the corresponding value
			return placeholderMap[placeholder];
		} else {
			// If no corresponding value found, return the original placeholder
			return match;
		}
	});

	return replacedString;
}

export async function getkeycloackConnectionInformation() {
	let response = null;
	await fetch(Constants.GetkeycloackConnectionInformationfUrl, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export async function SubmitWorkflowData(userId, formData, workflowData, companyId, lang) {
	await db.table("current_workflow_step").clear();
	localStorage.removeItem(Constants.CurrentWorkflowStep);
	let response = null;
	workflowData.taskId = workflowData.taskId || workflowData.taskID;
	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	workflowData.accessToken = accessToken;
	var requestBody = { formData: formData, workflowData: workflowData };
	await fetch(Constants.SubmitWorkflowEndPoint + "/" + userId + "/" + companyId + "?lang=" + lang, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
		body: JSON.stringify(requestBody),
	})
		.then((res) => res.json())
		.then((data) => {
			//	console.log(data);
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export function parseQueryParams(url) {
	const queryString = url.split("?")[1]; // Get the query string part of the URL
	const queryParams = {};

	if (queryString) {
		const pairs = queryString.split("&"); // Split the query string into key-value pairs
		pairs.forEach((pair) => {
			const [key, value] = pair.split("="); // Split each pair into key and value
			const decodedKey = decodeURIComponent(key); // Decode the key
			const decodedValue = decodeURIComponent(value || ""); // Decode the value (handle cases where value is undefined)
			queryParams[decodedKey] = decodedValue; // Store the key-value pair in the object
		});
	}

	return queryParams;
}

export async function getCountUnAssignedTask() {
	try {
		//CheckIfTokenExists();

		const workflowCount = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				reject(response);
			};

			GetFromEndPoint(GetCountUnAssignedTaskEndPoint, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		return workflowCount;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}

export async function patchFormData(dataForm) {
	try {
		//CheckIfTokenExists();

		const patchData = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				console.log(response);
				reject(response);
			};

			PatchToEndPoint(Constants.PatchEndPoint, {}, dataForm, PostSuccessCallBack, PostFailureCallBack);
		});

		return patchData;
	} catch (error) {
		console.log("ERROR:", error);
		throw error;
	}
}
