import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Contact = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100}`}>
			<NavbarStandard />
			<Container className={`${styles.padding35em} ${styles.addPageHeight} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle} mb-4`}>{t("Contact us")}</h2>
				<div className="contact-info">
					<p>
						<FontAwesomeIcon icon={faEnvelope} /> {t("email")} <a href="mailto:emanifest@mwan.gov.sa">emanifest@mwan.gov.sa</a>
					</p>
				</div>
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Contact;
