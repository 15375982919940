import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const ViewTruck = ({ successCallBackFunction, allTrucks, hideViewTruck, selectedTrucks, RelatedCompany, showComponent }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [plateNumber, setPlateNumber] = useState("");
	const [type, setType] = useState("");
	const [subType, setSubType] = useState("");
	const [size, setSize] = useState("");
	const [tareWeight, setTareWeight] = useState("");
	const [capacity, setCapacity] = useState("");
	const [capacityUnit, setCapacityUnit] = useState("");
	const [transporterCompanyId, setTransporterCompanyId] = useState("truck");
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	useEffect(() => {
		if (selectedTrucks) {
			setPlateNumber(selectedTrucks.plateNumber || "");
			setType(selectedTrucks.type || "");
			setSubType(selectedTrucks.subType || "");
			setSize(selectedTrucks.size || "");
			setTareWeight(selectedTrucks.tareWeight || "");
			setCapacity(selectedTrucks.capacity || "");
			setCapacityUnit(selectedTrucks.capacityUnit || "");
		}
	}, [selectedTrucks]);

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (compTypeID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			console.log(responseObject);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		console.log(compTypeID);
		var endPoint = Constants.GetCompaniesByType + compTypeID;
		General.GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
	};

	/*************** LOAD COMPANIES END ************************/

	const handleDropDownChange = () => {};

	const handleCancelNew = () => {
		hideViewTruck();
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none"></div>
						<div className="ms-2">{t(`View Truck`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label class="form-label">{t(`Plate Number`)}</label>
							<input type="text" disabled id="plateNumber" className="form-control" value={plateNumber} onChange={(event) => setPlateNumber(event.target.value)} />
						</div>

						<div className="mb-3">
							<label class="form-label">{t(`Truck Type`)}</label>
							<input type="text" id="type" disabled className="form-control" value={type} onChange={(event) => setType(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Truck Subtype`)}</label>
							<input type="text" id="subType" disabled className="form-control" value={subType} onChange={(event) => setSubType(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Size`)}</label>
							<input type="text" id="size" disabled className="form-control" value={size} onChange={(event) => setSize(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Tare Weight`)}</label>
							<input type="text" id="tareWeight" disabled className="form-control" value={tareWeight} onChange={(event) => setTareWeight(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Capacity`)}</label>
							<input type="text" id="capacity" disabled className="form-control" value={capacity} onChange={(event) => setCapacity(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Capacity Unit`)}</label>
							<input type="text" id="capacityUnit" disabled className="form-control" value={capacityUnit} onChange={(event) => setCapacityUnit(event.target.value)} />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
				</div>

				{errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)}
			</Modal.Footer>
		</Modal>
	);
};

ViewTruck.propTypes = {
	showComponent: PropTypes.bool,
};

export default ViewTruck;
