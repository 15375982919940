export const invalid_email = "invalid_email";
export const format_phone_number = "format_phone_number";
export const id_number = "id_number";
export const error_later = "error_later";
export const error_credential = "error_credential";
export const submit_labels = "submit_labels";
export const new_user = "new_user";
export const new_roles = "new_roles";
export const new_label = "new_label";
export const contract_label = "contract_label";
export const transporter_label = "transporter_label";
export const new_form = "new_form";
export const pending_task = "pending_task";
export const workflow_list = "workflow_list";
export const design_view = "design_view";
export const edit_design = "edit_design";
export const upload = "upload";
export const new_workflow = "new_workflow";
export const process_history = "process_history";
export const assign_roles = "assign_roles";

export const workflow_failed = "workflow_failed";
export const workflow_complete = "workflow_complete";
export const select_workflow_upload = "select_workflow_upload";
export const uploaded_successfully = "uploaded_successfully";
export const failed_upload_workflow = "failed_upload_workflow";
export const upload_file_extensions_bpmn = "upload_file_extensions_bpmn";
export const deleted_successfully = "deleted_successfully";
export const failed_delete_workflow = "failed_delete_workflow";
export const loaded_successfully = "loaded_successfully";
export const failed_load_workflow_diagram = "failed_load_workflow_diagram";
export const loaded_successfully_editor = "loaded_successfully_editor";
export const failed_load_workflow_in_editor = "failed_load_workflow_in_editor";
export const login_new_password = "login_new_password";
export const fistName_Empty = "fistName_Empty";
export const lastname_empty = "lastname_empty";
export const password_required = "password_required";
export const confirm_password_required = "confirm_password_required";
export const id_type_unselected = "id_type_unselected";
export const password_no_match = "password_no_match";
export const companytypeid_unselected = "companytypeid_unselected";
export const companyid_unselected = "companyid_unselected";
export const diff_date = "diff_date";
export const form_submitted_please_wait = "form_submitted_please_wait";
export const delete_icon = "Delete";
export const execute_task = "execute_task";
export const assignTask = "assignTask";

export const password_format = "password_format";
export const startDate = "startDate";
export const endDate = "endDate";
export const upload_file_extensions_pdf = "upload_file_extensions_pdf";
export const viewFile = "viewFile";
export const areyousuredelete = "Are you sure you want to delete";
export const Cancel = "Cancel";
export const DownloadTemplate = "Download Template";
export const password_Changed_Successfully_with_Logout = "password_Changed_Successfully_with_Logout";
export const Change_Password = "Change_Password";
export const unassigne_task = "unassigne_task";
