import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card, Modal, Nav, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import Login from "components/authentication/simple/Login";
import Registration from "components/authentication/simple/Registration";
import AppContext from "context/Context";
import { useTranslation } from "react-i18next";
import team3 from "assets/img/team/avatar.png";
import Avatar from "components/common/Avatar";

const LandingRightSideNavItem = () => {
	const [t, i18n] = useTranslation();
	const [showRegistrationModal, setShowRegistrationModal] = useState(false);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const {
		config: { isDark },
		setConfig,
	} = useContext(AppContext);

	const changeLanguage = () => {
		setIsRTL((prevIsRTL) => {
			const newIsRTL = !prevIsRTL;
			localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
			setConfig("isRTL", newIsRTL);

			if (newIsRTL) {
				i18n.changeLanguage("ar");
				// keycloakService.changeLanguage("ar");
			} else {
				i18n.changeLanguage("en");
				// keycloakService.changeLanguage("en");
			}

			return newIsRTL;
		});

		window.location.reload();
	};
	return (
		<Nav navbar className="ms-auto navbar-nav-icons flex-row align-items-center" as="ul">
			<Nav.Item as={"li"}>
				<Nav.Link className="px-2 theme-control-toggle" onClick={() => setConfig("isDark", !isDark)}>
					<OverlayTrigger
						key="left"
						placement={isRTL ? "bottom" : "left"}
						overlay={
							<Tooltip style={{ position: "fixed" }} id="ThemeColor">
								{isDark ? "Switch to light theme" : "Switch to dark theme"}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label" style={{ height: "32px", width: "32px" }}>
							<FontAwesomeIcon icon={isDark ? "sun" : "moon"} className="fs-0" />
						</div>
					</OverlayTrigger>
				</Nav.Link>
			</Nav.Item>
			<Dropdown navbar={true} as="li">
				<Dropdown.Toggle
					as={"button"}
					bsPrefix="toggle"
					id="nav-link-toggle-button"
					// as={Link}
					style={{ backgroundColor: "transparent", border: "none" }}
					className="pe-0 ps-2 pt-1"
				>
					<Avatar src={team3} />
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
					<div className="bg-white rounded-2 py-2 dark__bg-1000">
						<Dropdown.Item onClick={changeLanguage}>{isRTL ? "English" : "عربي"}</Dropdown.Item>
						<Dropdown.Item onClick="">{t("Login")}</Dropdown.Item>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</Nav>
	);
};

export default LandingRightSideNavItem;
