import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { executePendingTasks } from "components/workflow/WorkflowHelper";
import { useTranslation } from "react-i18next";
import { EncryptStorage } from "encrypt-storage"; // Assuming you are using encrypt-storage library

function Redash(props) {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [content, setContent] = useState(null);
	const { isSettingsPressed, isDashboardsPressed, isQueriesPressed, isMain } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const RedashUrl = "https://visualization.mwan.gov.sa/";

	useEffect(() => {
		const accessToken = encryptStorage1.getItem("accessToken");

		if (navigator.onLine) {
			executePendingTasks(
				() => {},
				() => {},
				() => {},
				navigate,
				location,
				t
			);
		}

		let iframeSrc = "";

		if (isMain) {
			iframeSrc = `${RedashUrl}login?token=${accessToken}&next=/`;
		} else if (isSettingsPressed) {
			iframeSrc = `${RedashUrl}login?token=${accessToken}&next=/data_sources`;
		} else if (isDashboardsPressed) {
			iframeSrc = `${RedashUrl}login?token=${accessToken}&next=/dashboards`;
		} else if (isQueriesPressed) {
			iframeSrc = `${RedashUrl}login?token=${accessToken}&next=/queries`;
		} else {
			setContent(<p>No component/content selected.</p>);
			return;
		}

		// Create a new iframe element
		const iframe = (
			<iframe
				key={iframeSrc + Date.now()} // Use a unique key to force re-render
				src={iframeSrc}
				width="100%"
				height="1000"
				onLoad={(e) => {
					// Check if the iframe has redirected

					try {
						const iframeWindow = e?.target?.contentWindow;
						const currentUrl = iframeWindow?.location?.href;
						if (currentUrl !== iframeSrc) {
							e.target.src = currentUrl;
						}
					} catch (ex) {
						console.log(ex);
					}
				}}
			/>
		);

		setContent(iframe);
	}, [location.pathname, isSettingsPressed, isDashboardsPressed, isQueriesPressed, isMain]);

	return <div>{content}</div>;
}

export default Redash;
