import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment-timezone";
import UpliftCss from "../common/upliftcss.module.css";

const SearchPanel = ({ onSearch, searchValues, setSearchValues }) => {
  const [t] = useTranslation();


  const handleStartDateChange = (date) => {
    setSearchValues(prev => ({
      ...prev,
      startDate: date
    }));
  };

  const handleEndDateChange = (date) => {
    setSearchValues(prev => ({
      ...prev,
      endDate: date
    }));
  };

  const handleSearchClick = () => {
    const filters = {
      searchTerm: searchValues.searchTerm,
      startDate: searchValues.startDate ? moment(searchValues.startDate).format("DD-MM-YYYY") : "",
      endDate: searchValues.endDate ? moment(searchValues.endDate).format("DD-MM-YYYY") : ""
    };
    onSearch(filters);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchClick();
    }
  };

  return (
    <Row className="flex-end-center mb-3">
      <Col xs="auto" sm={6} lg={4}>
        <input
          type="text"
          className={`form-control ${UpliftCss.filterFieldBoxes}`}
          placeholder={t("Search")}
          value={searchValues.searchTerm}
          onChange={(e) => setSearchValues(prev => ({
            ...prev,
            searchTerm: e.target.value
          }))}
          onKeyDown={handleKeyPress}
        />
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          className={`form-control ${UpliftCss.filterFieldBoxes}`}
          placeholderText={t("Start Date")}
          selected={searchValues.startDate}
          maxDate={searchValues.endDate}
          onChange={handleStartDateChange}
          onKeyDown={handleKeyPress}
        />
      </Col>
      <Col xs="auto" sm={6} lg={4}>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          className={`form-control ${UpliftCss.filterFieldBoxes}`}
          placeholderText={t("End Date")}
          selected={searchValues.endDate}
          minDate={searchValues.startDate}
          onChange={handleEndDateChange}
          onKeyDown={handleKeyPress}
        />
      </Col>
      <Col xs="auto" sm={6} lg={2} className="mt-3">
        <Button 
          className={`w-100 ${UpliftCss.searchButton}`}
          onClick={handleSearchClick}
        >
          {t("Search")}
        </Button>
      </Col>
    </Row>
  );
};

export default SearchPanel;