import React, { useEffect, useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const DeleteContactConfirmation = ({ company, showComponent, hideDeleteConfirmation, successCallBackFunction, selectedContact }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [RelatedCompanytypeIcon, setRelatedCompanytypeIcon] = useState("");

	const [t, i18n] = useTranslation();

	const handleCancelDelete = () => {
		hideDeleteConfirmation();
	};

	const handleConfirmDelete = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);
		const DeleteselectedContactSuccess = (responseObject) => {
			handleCancelDelete();
			successCallBackFunction(true);
			showLoaderContainer(false);
			showHideButtons(true);
		};

		const DeleteselectedContactFailure = (error) => {
			showErrorContainer(true);
			setTimeout(function () {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later. " + error;
				}
				showLoaderContainer(false);
				showHideButtons(true);
			}, 1000);
		};

		var deleteEndpoint = Constants.DeleteTransporterContactData + selectedContact.id;
		try {
			await General.DeleteFromEndPoint(deleteEndpoint, null, null, DeleteselectedContactSuccess, DeleteselectedContactFailure);
		} catch (error) {
			DeleteselectedContactFailure(error);
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelDelete} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon={RelatedCompanytypeIcon} className="text-success" />
						</div>
						<div className="ms-2">{t(`Delete Delegate`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t(`Are you sure you want to remove this related Truck?`)}
							<br /> <br />
							{t(`First Name`)}: <strong>{selectedContact.firstName}</strong>
							<br />
							{t("Last Name")}: <strong>{selectedContact.lastName}</strong>
							<br />
							{t(`National Id`)}: <strong>{selectedContact.nationalId}</strong>
							<br />
							{t("Mobile Number")}: <strong>{selectedContact.mobileNumber}</strong>
							<br />
							{t(`Address`)}: <strong>{selectedContact.address}</strong>
							<br />
							{t("Email")}: <strong>{selectedContact.email}</strong>
							<br />
							{t(`Generator Company ID`)}: <strong>{selectedContact.generatorCompanyId}</strong>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={handleCancelDelete} size="sm" variant="secondary">
							{t("No")}
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="check" size="sm" className="ms-2" variant="danger">
							{t("Yes")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

DeleteContactConfirmation.propTypes = {
	company: PropTypes.object,
	showComponent: PropTypes.bool,
};

export default DeleteContactConfirmation;
