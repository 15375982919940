import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "helpers/initFA";
import { Form } from "@formio/react";
import components from "../src/components/forms/custom";
import { Components } from "@formio/react";
import i18next from "../src/i18n";
import { I18nextProvider } from "react-i18next";
import { register } from "./serviceWorkerRegistration"; // Adjust the path as needed
import sessionReducer from "reducers/sessionReducer";
import { Provider } from "react-redux";
import store from "reducers/store";

import("hide-cra-error-overlay").then(({ initHideOverlay }) =>
	initHideOverlay({
		//HTML id(s) of the 'overlay' element to hide when it appears
		//(assumed to be a direct child of document.body).
		//can be a single string id, or an array of multiple ids to check for.
		overlayId: ["webpack-dev-server-client-overlay", "webpack-dev-server-client-overlay-div"],

		//whether or not to add the "Show Errors" button
		withRestoreButton: false,

		//disable hiding, restore original CRA behavior
		disable: false,
	})
);

// register();
//These are the custom components created registered within formio components and thus can be used within formio builder
Components.setComponents(components);

const container = document.getElementById("main");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<Main>
			<I18nextProvider i18n={i18next}>
				<App />
			</I18nextProvider>
		</Main>
	</Provider>
);
