import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import * as Constants from "common/constants";
import IconButton from "components/common/IconButton";
import { EncryptStorage } from "encrypt-storage";
import { Dropdown, Col, Modal, Row, Button, Image, Tooltip, overlay, OverlayTrigger } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AddContact from "./AddContact";
import EditContact from "./EditContact";
import DeleteContactConfirmation from "./DeleteContactConfirmation";
import { isSet } from "lodash";
import ViewContact from "./ViewContact";
import { useAsyncDebounce } from "react-table";
import UpliftCss from "../../common/upliftcss.module.css";

const ManageContacts = ({ RelatedCompanytypeNamePlural, CompanyID }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [fetchFlag, setFetchFlag] = useState(false);
	const [t, i18n] = useTranslation();
	const location = useLocation();
	const [searchvar, setSearchvar] = useState(location.state?.company);
	const [selectedCompany, setSelectedCompany] = useState([]);
	const [selectedContacts, setContacts] = useState([]);
	const [addContactsPopupVisibility, showContactsPopup] = useState(false);
	const [editContactsPopupVisibility, showEditContacts] = useState(false);
	const [viewContactsPopupVisibility, showViewContacts] = useState(false);
	const [deleteContactsPopupVisibility, showDeleteContactsPopup] = useState(false);
	const columns = [
		{
			accessor: "id",
			Header: t("ID"),
		},
		{
			accessor: "firstName",
			Header: t("First Name"),
		},
		{
			accessor: "lastName",
			Header: t("Last Name"),
		},
		{
			accessor: "nationalId",
			Header: t("National Id"),
		},
		{
			accessor: "mobileNumber",
			Header: t("Mobile Number"),
		},
		{
			accessor: "address",
			Header: t("Address"),
		},
		{
			accessor: "email",
			Header: t("Email"),
		},
		{
			accessor: "generatorCompanyId",
			Header: t("Company"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const [data, setData] = useState([]);
	const [onRefresh, setOnRefresh] = useState(null);

	//first function that's called

	const handlerefresh = (value) => {
		setOnRefresh(value);
		setFetchFlag(value);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	useEffect(() => {
		setOnRefresh(null);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
		setSelectedCompany(selectedCompany);
	}, [window.location.href, onRefresh]);

	/*************** ADD contact START ************************/

	const handleClickAddContacts = () => {
		showContactsPopup(true);
	};

	const handleCancelAddContacts = () => {
		showContactsPopup(false);
	};
	/*************** ADD contact END ************************/
	/*************** ADD contact START ************************/

	const handleClickEditContacts = (gen) => {
		setContacts(gen);
		showEditContacts(true);
	};

	const handleCancelEditContacts = () => {
		showEditContacts(false);
	};
	/*************** ADD contact END ************************/
	/*************** ADD contact START ************************/

	const handleClickViewContacts = (gen) => {
		setContacts(gen);
		showViewContacts(true);
	};
	const handleCancelViewContacts = () => {
		showViewContacts(false);
	};
	/*************** ADD contact END ************************/

	/*************** delete contact START ************************/
	const handleClickDeleteContact = (gen) => {
		setContacts(gen);
		showDeleteContactsPopup(true);
	};
	const handleCancelDelete = () => {
		showDeleteContactsPopup(false);
	};
	/*************** delete contact END ************************/

	const resetSearch = () => {
		if (searchvar !== "") {
			setSearchvar("");
			setFetchFlag(true);
		}
	};
	useEffect(() => {
		async function fetchData() {
			setFetchFlag(null);
			try {
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				const response = await fetch(Constants.GetTransporterContactsData + selectedCompany, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + accessToken,
					},
				});
				const responseText = await response.text();

				try {
					const newData = JSON.parse(responseText);
					let arrayOfContactss = Object.values(newData);
					const contacts = arrayOfContactss.flatMap((contact) => {
						let parsedcontact;
						try {
							parsedcontact = typeof contact === "string" ? JSON.parse(contact) : contact;
						} catch (e) {
							console.error("Error parsing task:", task, e);
							return []; // Skip this task if it can't be parsed
						}
						parsedcontact.id = t(parsedcontact.id);
						parsedcontact.firstName = t(parsedcontact.firstName);
						parsedcontact.lastName = t(parsedcontact.lastName);
						parsedcontact.nationalId = t(parsedcontact.nationalId);
						parsedcontact.mobileNumber = t(parsedcontact.mobileNumber);
						parsedcontact.address = t(parsedcontact.address);
						parsedcontact.email = t(parsedcontact.email);
						parsedcontact.companyId = t(parsedcontact.companyId);
						parsedcontact.tranporterCompanyId = t(parsedcontact.tranporterCompanyId);
						parsedcontact.generatorCompanyId = t(parsedcontact.generatorCompanyId);
						parsedcontact.type = t(parsedcontact.type);
						parsedcontact.other = t(parsedcontact.other);
						parsedcontact.action = (
							<>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Edit")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-edit"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickEditContacts(parsedcontact)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("View")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-eye"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickViewContacts(parsedcontact)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Delete")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn-danger"
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-trash"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickDeleteContact(parsedcontact)}
										></IconButton>
									</div>
								</OverlayTrigger>
							</>
						);
						return parsedcontact;
					});
					if (searchvar != null && searchvar !== "") {
						const filteredcontacts = contacts.filter((contact) => contact.generatorCompanyId === searchvar);
						setData(filteredcontacts);
					} else {
						setData(contacts);
					}
				} catch (jsonError) {
					console.error("Response text:", jsonError);
				}
			} catch (networkError) {
				console.error("Fetch error:", networkError);
			}
			//}
		}

		fetchData();
	}, [fetchFlag]);
	return (
		<div>
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(`Manage Contacts`)}</h5>
					</div>
				</div>
			</div>
			<div className={`card ${UpliftCss.BodyBorder}`}>
				<div className="tool-header">
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: "fixed" }} id="newcontract">
								{t(`New Contact`)}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label icon-position end-text">
							<IconButton
								className="btn-primary botton-circle"
								iconClassName="fs--5"
								size="sm"
								icon="fa-solid fa-circle-plus"
								style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
								onClick={handleClickAddContacts}
							></IconButton>
						</div>
					</OverlayTrigger>
				</div>
				<div className="card-body">
					<div className={`mb-3 ${UpliftCss.tableHeader}`}>
						<AdvanceTableWrapper columns={columns} data={data} className={`${UpliftCss.tableHeader}`} sortable pagination perPage={10}>
							<Row className="flex-end-center mb-3">
								<Col xs="auto" sm={6} lg={4}>
									<AdvanceTableSearchBox
										globalFilter={searchvar}
										className={`${UpliftCss.filterFieldBoxes}`}
										searchvar={searchvar}
										resetSearch={resetSearch}
										setGlobalFilter={setFetchFlag}
										table
										placeholder={t("Search")}
									/>
								</Col>
							</Row>
							<AdvanceTable
								table
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: "fs--1 mb-0 overflow-hidden",
								}}
							/>
							<div className="mt-3">
								<AdvanceTableFooter rowCount={data.length} table rowInfo navButtons rowsPerPageSelection />
							</div>
						</AdvanceTableWrapper>
						{addContactsPopupVisibility && (
							<AddContact
								successCallBackFunction={handlerefresh}
								hideAddContact={handleCancelAddContacts}
								showComponent={addContactsPopupVisibility}
								RelatedCompany={selectedCompany}
							></AddContact>
						)}
						{deleteContactsPopupVisibility && (
							<DeleteContactConfirmation
								successCallBackFunction={handlerefresh}
								selectedContact={selectedContacts}
								hideDeleteConfirmation={handleCancelDelete}
								showComponent={deleteContactsPopupVisibility}
								company={selectedCompany}
							></DeleteContactConfirmation>
						)}
						{editContactsPopupVisibility && (
							<EditContact
								successCallBackFunction={handlerefresh}
								selectedContacts={selectedContacts}
								hideEditContact={handleCancelEditContacts}
								showComponent={editContactsPopupVisibility}
								RelatedCompany={selectedCompany}
							></EditContact>
						)}
						{viewContactsPopupVisibility && (
							<ViewContact
								successCallBackFunction={handlerefresh}
								selectedContacts={selectedContacts}
								hideViewContact={handleCancelViewContacts}
								showComponent={viewContactsPopupVisibility}
								RelatedCompany={selectedCompany}
							></ViewContact>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ManageContacts.propTypes = {
	RelatedCompanytypeID: PropTypes.string,
	CompanyRelationshipTypeID: PropTypes.string,
};

export default ManageContacts;
