import React, { Component, useEffect } from "react";
import { useState } from "react";
import ActionButton from "components/common/ActionButton";
import CardDropdown from "components/common/CardDropdown";
import { Dropdown, Col, Modal, Row, Button, Image, overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "common/constants";
import * as General from "common/general";
import DeleteConfirmation from "components/custom/forms/DeleteConfirmation";
import Loader from "components/custom/Loader";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Translation } from "react-i18next";
import { Utils } from "formiojs";
import db from "indexedDB";
import * as em from "common/ErrorMessages";
import UpliftCss from "../common/upliftcss.module.css";

const ManageForms = () => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		loggedInEmail: "",
		forms: [],
		selectedFormID: "",
		selectedFormName: "",
		selectedFormPath: "",
		showDeleteConfirmationPopup: false,
		errorOccuredVisibility: false,
		loaderContainerVisibility: false,
		selectedFormTitle: "",
		currentPageNumber: 1,
		recordsPerPage: 5,
		maxReached: false,
	});

	useEffect(() => {
		let tempState = { ...state };
		tempState["loggedInEmail"] = localStorage.getItem(Constants.LoggedInEmailOrUserNameKeyInLocalStorage);
		tempState["loaderContainerVisibility"] = true;
		tempState["errorOccuredVisibility"] = false;
		setState(tempState);
		bindForms(state.currentPageNumber);
	}, []);

	/*************** LOAD Forms START ************************/
	const bindForms = async (pageNumber) => {
		let tempState = { ...state };

		const GetAllFormsSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					var myformdata = JSON.parse(responseObject.data.formData);
					myformdata.sort((a, b) => new Date(b.modified) - new Date(a.modified));
					myformdata.sort((a, b) => new Date(b.modified) - new Date(a.modified));
					//maxReached used to indicate if the pageNumber returned is the same we request if not thus we reach max number of pageNumber
					tempState["maxReached"] = responseObject.data.currentPageNumber !== pageNumber ? true : false;
					//if we don't reach the max append the result else the response returns page 1 which we already have
					if (!tempState["maxReached"]) {
						tempState["forms"] = [...tempState["forms"], ...myformdata];
					}
					tempState["currentPageNumber"] = responseObject.data.currentPageNumber;
					isSuccess = true;
					// General.SaveToken(responseObject);
				}
			}

			if (isSuccess == false) {
				tempState["errorOccuredVisibility"] = true;

				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					tempState["loaderContainerVisibility"] = false;
				}, 1000);
			} else {
				tempState["errorOccuredVisibility"] = false;
				tempState["loaderContainerVisibility"] = false;
			}
			setState(tempState);
		};

		const GetAllFormsFailure = (error) => {
			tempState["errorOccuredVisibility"] = true;

			setTimeout(() => {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later.";
				}
				tempState["loaderContainerVisibility"] = false;
			}, 1000);
			setState(tempState);
		};

		General.GetFromEndPoint(
			Constants.GetAllFormsEndPoint + "?pageNumber=" + pageNumber + "&recordsPerPage=" + state.recordsPerPage,
			null,
			null,
			GetAllFormsSuccess,
			GetAllFormsFailure
		);
	};
	/*************** LOAD FORMS END ************************/

	/*************** DELETE START ************************/
	const showDeleteConfirmation = (selectedForm, index) => {
		let tempState = { ...state };
		tempState["showDeleteConfirmationPopup"] = true;
		tempState["selectedFormID"] = selectedForm._id;
		tempState["selectedFormTitle"] = selectedForm.title;
		tempState["selectedFormName"] = selectedForm.name;
		tempState["selectedFormPath"] = selectedForm.path;
		tempState["selectedFormIndex"] = index;

		setState(tempState);
	};

	const handleCancelDelete = (mode) => {
		let tempState = { ...state };
		tempState["showDeleteConfirmationPopup"] = false;
		tempState["selectedFormID"] = "";
		tempState["selectedFormTitle"] = "";
		tempState["selectedFormName"] = "";
		tempState["selectedFormPath"] = "";
		if (mode) {
			tempState["forms"].splice(tempState["selectedFormIndex"], 1);
		}
		tempState["selectedFormIndex"] = -1;
		setState(tempState);
	};
	/*************** DELETE END ************************/

	//Export form or its components api Keys as json file
	const exportForm = (form, isApiKeys = false) => {
		const blob = new Blob([JSON.stringify(form, null, 2)], {
			type: "application/json",
		});
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = form.name + (isApiKeys ? "_apiKeys" : "") + ".json";
		a.click();

		URL.revokeObjectURL(url);
	};
	//Export All form components Api Keys
	const exportApiKeys = (form) => {
		let apiKeysArray = [];
		//iterate over every component in the form using formio Utils
		Utils.eachComponent(
			form.components,
			function (component) {
				//if it is an input component and not layout component then append its key
				if (component.input === true) {
					apiKeysArray.push(component.key);
				}
			},
			true
		);
		exportForm(
			{
				name: form.name,
				apiKeys: apiKeysArray,
			},
			true
		);
	};

	//Create form copy
	const duplicateForm = (form) => {
		var formData = {
			title: moment().format("hhmmss").toString() + form.name,
			display: form.display,
			type: form.type,
			name: moment().format("hhmmss").toString() + form.name,
			path: (moment().format("hhmmss").toString() + form.name).toString().replaceAll(" ", ""),
			components: form.components,
			settings: {
				languages: form.settings?.languages,
				translationUrl: form.settings?.translationUrl,
			},
		};
		const AddFormSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							isSuccess = true;
							// General.SaveToken(responseObject);
							// handleCancelNew();
							// showFormCreated();
							//successCallBackFunction();
						}
					}
				}
			}

			if (isSuccess == false) {
			} else {
				window.location.reload();
			}
		};

		const AddFormFailure = (error) => {
			// this.showErrorContainer(true);
			// setTimeout(function () {
			//     var errorContainer = document.getElementById('errorContainer');
			//     if (errorContainer.innerHTML == "") {
			//         errorContainer.innerHTML = "An error occured, please try again later." + error;
			//     }
			//     this.showLoaderContainer(false);
			//     this.showHideButtons(true);
			// }, 1000)
		};

		var AddFormJson = JSON.stringify(formData);
		var AddFormEndPoint = Constants.CreateFormEndPoint;

		General.PostToEndPoint(AddFormEndPoint, null, AddFormJson, AddFormSuccess, AddFormFailure);
	};
	return (
		<Translation>
			{(t, { i18n }) => (
				<>
					<div className="mb-3 card">
						<div className="card-body">
							<div className="col-md">
								<h5 className="mb-2 mb-md-0">{t("Manage Forms")}</h5>
							</div>
						</div>
					</div>

					<div
						className="card"
						style={{
							marginBottom: "80px",
						}}
					>
						<div className="tool-header">
							<OverlayTrigger
								overlay={
									<Tooltip style={{ position: "fixed" }} id="newform">
										{t(em.new_form)}
									</Tooltip>
								}
							>
								<div className="theme-control-toggle-label icon-position end-text">
									<Link to="/addForm" className="addNewButton">
										{" "}
										<IconButton
											className={`btn-primary botton-circle  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--5"
											size="sm"
											icon="fa-solid fa-circle-plus"
											style={{
												margin: "auto 0.25rem",
												padding: "9px 10px 10px",
											}}
										></IconButton>
									</Link>
								</div>
							</OverlayTrigger>
						</div>

						{/*   <div className='d-flex w-100  justify-content-end'>
                        <Link to="/addForm" className="addNewButton"><IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="plus">
                            {t("Add new form")}
                        </IconButton></Link>
                    </div>*/}
						<div className="table-responsive">
							<table className="customTable table table-striped" striped="true" responsive="true">
								<thead>
									<tr>
										<th scope="col"> {t("Name")}</th>
										<th scope="col"> {t("ID")}</th>

										<th scope="col"> {t("Path")}</th>
										<th scope="col"> {t("Created Date")}</th>
										<th scope="col"> {t("Modified Date")}</th>
										<th className="text-end" scope="col">
											{" "}
											{t("Actions")}
										</th>
									</tr>
								</thead>
								<tbody>
									{state.forms.map((form, index) => (
										<tr key={form._id}>
											<td>{t(form.name)}</td>
											<td>{form._id}</td>

											<td>{form.path}</td>
											<td>{General.formatTimestamp(form.created)}</td>
											<td>{General.formatTimestamp(form.modified)}</td>

											<td className="text-end">
												<CardDropdown name={form._id + "_test"}>
													<div className="py-2">
														<Dropdown.Item>
															<a
																href="javascript:void(0);"
																onClick={async () => {
																	await db.table("temp_form_data").clear();
																	// localStorage.removeItem("TempFormData")
																	window.open("/formView/" + form._id);
																}}
																className="view-submission-button"
															>
																{t("View Form")}
															</a>
														</Dropdown.Item>

														<Dropdown.Item>
															<div
																onClick={async () => {
																	await db.table("temp_form_data").clear();

																	// localStorage.removeItem("TempFormData")
																	navigate("/formView/" + form._id, {
																		state: {
																			isTesting: true,
																			// toPDF: true,
																			// data: {
																			//     "textField1": "MSW",
																			//     "textField2": "01-Wastes resulting from exploration, mining, quarrying, physical and chemical treatment of minerals",
																			//     "textField3": "Ahmad Nasser",
																			//     "textField": "HEET Landfill",
																			//     "userInformation": {
																			//         "loggedInUserInfo.id": "8ed68c80-0151-417b-8cec-c5015e12d386"
																			//     },
																			//     "loc": {
																			//         "latitude": 33.4363835,
																			//         "longitude": 35.2819849
																			//     },
																			//     "wasteGeneratorEntityName": {
																			//         "entityName": "Waste Generator Entity",
																			//         "crNumber": 123321,
																			//         "organizationEmail": "info@wg.com",
																			//         "phoneNumber": "(123) 423-4334",
																			//         "geographicalLocation": "test"
																			//     },
																			//     "wasteInformation": {
																			//         "label": "احسح سضسض سضسض",
																			//         "value": "1"
																			//     },
																			//     "wasteCategoryDriverReadOnly": "MSW",
																			//     "wasteCategoryRecepientReadOnly": "MSW",
																			//     "wasteCode": {
																			//         "label": "01-Wastes resulting from exploration, mining, quarrying, physical and chemical treatment of minerals",
																			//         "value": "01"
																			//     },
																			//     "wasteCodeDriverReadOnly": "01-Wastes resulting from exploration, mining, quarrying, physical and chemical treatment of minerals",
																			//     "wasteCodeRecepientReadOnly": "01-Wastes resulting from exploration, mining, quarrying, physical and chemical treatment of minerals",
																			//     "facilityAddedId": {
																			//         "value": "xa",
																			//         "facilityAddedCrNumber": "xa",
																			//         "facilityAddedPhoneNumber": "xa",
																			//         "facilityAddedEmail": "xa",
																			//         "facilityAddedAddress": "xa"
																			//     },
																			//     "supervisors": {
																			//         "value": "ahmad.nasser@msiuk.com",
																			//         "firstName": "Ahmad",
																			//         "label": "Ahmad Nasser"
																			//     },
																			//     "supervisorFacility": {
																			//         "value": "HEET Landfill",
																			//         "phoneNumber": "(011) 445-4567",
																			//         "label": "HEET Landfill"
																			//     }
																			// }
																		},
																	});
																}}
															>
																{t("Test Flow")}
															</div>
														</Dropdown.Item>

														<Dropdown.Item>
															<a
																href="javascript:void(0);"
																onClick={async () => {
																	// localStorage.removeItem("TempFormData")
																	await db.table("temp_form_data").clear();
																	window.open("/formedit/" + form._id);
																}}
																className="edit-button-form"
															>
																{t("Edit")}
															</a>
														</Dropdown.Item>

														<Dropdown.Item onClick={() => showDeleteConfirmation(form, index)}>{t("Delete")}</Dropdown.Item>
														<Dropdown.Item onClick={() => exportForm(form)}>{t("Export Form")}</Dropdown.Item>
														<Dropdown.Item onClick={() => duplicateForm(form)}>{t("Duplicate")}</Dropdown.Item>
														<Dropdown.Item onClick={() => exportApiKeys(form)}>{t("Export Api Keys")}</Dropdown.Item>
													</div>
												</CardDropdown>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							{state.errorOccuredVisibility ? <div id="errorContainer" className="loginAlert"></div> : ""}
							{state.loaderContainerVisibility ? <Loader></Loader> : ""}

							{state.showDeleteConfirmationPopup && (
								<DeleteConfirmation
									hideDeleteConfirmation={(mode) => handleCancelDelete(mode)}
									showComponent={state.showDeleteConfirmationPopup}
									formID={state.selectedFormID}
									formTitle={state.selectedFormTitle}
									formName={state.selectedFormName}
									formPath={state.selectedFormPath}
								></DeleteConfirmation>
							)}
						</div>
						<div className="w-100 d-flex justify-content-center">
							{!state.maxReached && !state.loaderContainerVisibility && (
								<label
									onClick={() => {
										let tempState = { ...state };
										tempState["currentPageNumber"] = tempState["currentPageNumber"] + 1;
										tempState["loaderContainerVisibility"] = true;
										// tempState["forms"] = [];
										setState(tempState);
										bindForms(tempState["currentPageNumber"]);
									}}
									style={{ cursor: "pointer" }}
								>
									{t("Load More")}
								</label>
							)}
						</div>
					</div>

					{/* {
                    !state.loaderContainerVisibility && <div className='w-100 d-flex justify-content-between mt-3'>
                        {

                            <div>

                                {state["currentPageNumber"] !== 1 && <label style={{ cursor: "pointer" }} onClick={() => {
                                    let tempState = { ...state };
                                    tempState["currentPageNumber"] = 1;
                                    tempState["loaderContainerVisibility"] = true;
                                    tempState["forms"] = [];
                                    setState(tempState);
                                    bindForms(tempState["currentPageNumber"]);
                                }}>{t("Back to page 1")}</label>}
                            </div>
                        }

                        <div className='d-flex'>
                            {
                                state["currentPageNumber"] !== 1 && <IconButton
                                    onClick={() => {
                                        let tempState = { ...state };
                                        tempState["currentPageNumber"] = tempState["currentPageNumber"] - 1;
                                        tempState["loaderContainerVisibility"] = true;
                                        tempState["forms"] = [];
                                        setState(tempState);
                                        bindForms(tempState["currentPageNumber"]);
                                    }}
                                    iconClassName="fs--2 me-1"
                                    variant="falcon-default"
                                    size="sm"
                                >
                                    {t("Previous")}
                                </IconButton>
                            }

                            <IconButton
                                onClick={() => {
                                    let tempState = { ...state };
                                    tempState["currentPageNumber"] = tempState["currentPageNumber"] + 1;
                                    tempState["loaderContainerVisibility"] = true;
                                    tempState["forms"] = [];
                                    setState(tempState);
                                    bindForms(tempState["currentPageNumber"]);
                                }}
                                iconClassName="fs--2 me-1"
                                variant="falcon-default"
                                size="sm"
                            >
                                {t("Next")}
                            </IconButton>



                        </div>
                    </div>
                } */}
				</>
			)}
		</Translation>
	);
};

export default ManageForms;
