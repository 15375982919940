import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const AddGenerator = ({ successCallBackFunction, hideAddGenerator, RelatedCompany, showComponent, allData }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [unnErrorMessage, setUnnErrorMessage] = useState("");
	const [crErrorMessage, setCrErrorMessage] = useState("");
	const [addressErrorMessage, setAddressErrorMessage] = useState("");
	const [companyErrorMessage, setCompanyErrorMessage] = useState("");
	const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
	const [generatedWasteType, setGeneratedWasteType] = useState("Hazardous Waste");
	const [wasteType, setWasteType] = useState("Contractor");
	const [unn, setUNN] = useState("");
	const [crNumber, setCrNumber] = useState("");
	const [address, setAddress] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isVerified, setIsVerified] = useState("");
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const [isUnique, setIsUnique] = useState(true);
	const [isMandatory, setIsMandatory] = useState(true);
	const [isMandatoryCr, setIsMandatoryCr] = useState(true);
	const [isMandatoryUnn, setIsMandatoryUnn] = useState(true);
	const [isMandatoryName, setIsMandatoryName] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isDisabledCr, setIsDisabledCr] = useState(false);
	const [isDisabledAddress, setIsDisabledAddress] = useState(false);
	const [isDisabledPhone, setIsDisabledPhone] = useState(false);
	const [isUnnMandatory, setIsUnnMandatory] = useState(false);
	const [isButtonPressed, setIsButtonPressed] = useState(false);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	useEffect(() => {
		if (wasteType === "Government") {
			// setIsUnnMandatory(false);
			setIsMandatoryCr(false);
		} else {
			// setIsUnnMandatory(true);
			setIsMandatoryCr(true);
		}
	}, [wasteType]);

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (compTypeID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			console.log(responseObject);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		console.log(compTypeID);
		var endPoint = Constants.GetCompaniesByType + compTypeID;
		General.GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
	};

	/*************** LOAD COMPANIES END ************************/

	const handleDropDownChange = () => {};

	const handleCancelNew = () => {
		hideAddGenerator();
	};
	const isUNNUnique = (setedunn) => {
		// console.log(!allData.some((generator) => generator.unn === setedunn));

		return !allData.some((generator) => generator.unn === setedunn);
	};
	const handleVerify = async () => {
		const fieldunnNumber = document.getElementById("unn");
		fieldunnNumber.classList.remove("is-invalid");
		setIsButtonPressed(true);
		// Validation for UNN
		if (unn.length !== 10 || !/^\d+$/.test(unn)) {
			fieldunnNumber.classList.add("is-invalid");
			showErrorContainer(true);
			setUnnErrorMessage(t("UNN must be 10 digits long."));
			setIsFinish(false);
			return; // Exit early if validation fails
		}

		// Make API call to verify CR information
		const GetCRInformationByCRNumberEndPoint = Constants.GetCRInformationByCRNumberData + unn;

		const GetSuccessCallBack = (responseObject) => {
			// console.log({ responseObject });
			if (responseObject.httpResponse.status == 1) {
				setCompanyName(responseObject.crNameAR);
				setAddress(responseObject.crAddress);
				if (responseObject.crContactNo) {
					setPhoneNumber(responseObject.crContactNo);
				}
				// setPhoneNumber(responseObject.crPhoneNo);
				if (responseObject.crNumber) {
					setCrNumber(responseObject.crNumber);
				}
				setIsDisabled(true);
				if (responseObject.crNumber) {
					setIsDisabledCr(true);
				}
			} else {
				setCompanyName("");
				setAddress("");
				setPhoneNumber("");
				setCrNumber("");
				setIsDisabled(false);
				setIsDisabledCr(false);
			}
		};
		const GetFailureCallBack = (responseObject) => {};
		try {
			const dataFromCr = await General.GetFromEndPoint(GetCRInformationByCRNumberEndPoint, null, null, GetSuccessCallBack, GetFailureCallBack);

			// Handle successful response
			// console.log(dataFromCr);
			if (dataFromCr) {
				// console.log(dataFromCr);
				// Add your success handling logic here
				setIsFinish(true); // Or whatever logic you need to set
			}
		} catch (error) {
			// Handle API call failure
			fieldunnNumber.classList.add("is-invalid");
			showErrorContainer(true);
			setUnnErrorMessage(t("Failed to verify UNN. Please try again."));
			setIsFinish(false);
		}
	};
	const checkGeneratorType = (value) => {
		if (value === "Hazardous Waste") {
			setIsMandatory(true);
		} else {
			setIsMandatory(false);
		}
		return true;
	};

	const handleAddGenerator = async () => {
		setIsFinish(true);

		//get all elements
		var fieldgeneratedWasteTypeSelect = document.getElementById("generatedWasteTypeSelect");
		var fieldwasteTypeSelect = document.getElementById("wasteTypeSelect");
		var fieldunnNumber = document.getElementById("unn");
		var fieldcrNumber = document.getElementById("crNumber");
		var fieldaddress = document.getElementById("address");
		var fieldPhoneNumber = document.getElementById("phoneNumber");
		var fieldCompanyName = document.getElementById("companyName");
		var errorContainerDate = document.getElementById("errorContainerDate");
		var AddGeneratorJSON = {
			generatedWasteType: generatedWasteType,
			wasteType: wasteType,
			unn: unn,
			crNumber: crNumber,
			address: address,
			companyName: companyName,
			phoneNumber: phoneNumber,
			isVerified: true,
		};
		var isValidForm = true;
		//remove class for invalid
		fieldgeneratedWasteTypeSelect.classList.remove("is-invalid");
		fieldwasteTypeSelect.classList.remove("is-invalid");
		fieldunnNumber.classList.remove("is-invalid");
		fieldcrNumber.classList.remove("is-invalid");
		fieldPhoneNumber.classList.remove("is-invalid");
		fieldaddress.classList.remove("is-invalid");
		fieldCompanyName.classList.remove("is-invalid");
		//check if fields are filled and set the invalid class in case they are not
		if (generatedWasteType === "Hazardous Waste") {
			if (generatedWasteType === "") {
				setIsFinish(false);
				fieldgeneratedWasteTypeSelect.classList.add("is-invalid");
				isValidForm = false;
			}

			if (wasteType === "") {
				setIsFinish(false);
				fieldwasteTypeSelect.classList.add("is-invalid");
				isValidForm = false;
			}
			// UNN validation: must be 10 digits and unique
			if (unn.length !== 10 || !/^\d+$/.test(unn)) {
				fieldunnNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setUnnErrorMessage(t("UNN must be 10 digits long."));
				setIsFinish(false);
				isValidForm = false;
			} else if (!isUNNUnique(unn)) {
				fieldunnNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setUnnErrorMessage(t("UNN already exists. Please enter a unique UNN."));
				setIsFinish(false);
				isValidForm = false;
			} else {
				showErrorContainer(false);
			}

			if (wasteType !== "Government") {
				// CR Number validation: must be 10 digits
				if (crNumber.length !== 10 || !/^\d+$/.test(crNumber)) {
					fieldcrNumber.classList.add("is-invalid");
					showErrorContainer(true);
					setCrErrorMessage(t("CR Number must be 10 digits long."));
					setIsFinish(false);
					isValidForm = false;
				}
			}

			// Phone Number validation: must be 10 digits and start with '05'
			if (phoneNumber.length !== 10 || !phoneNumber.startsWith("05")) {
				fieldPhoneNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setPhoneErrorMessage(t("Phone Number must be 10 digits and start with '05'."));
				setIsFinish(false);
				isValidForm = false;
			}
			if (address.length == 0) {
				fieldaddress.classList.add("is-invalid");
				showErrorContainer(true);
				setAddressErrorMessage(t("Address is required"));
				setIsFinish(false);
				isValidForm = false;
			}
			if (companyName.length == 0) {
				fieldCompanyName.classList.add("is-invalid");
				showErrorContainer(true);
				setCompanyErrorMessage(t("Facility Name is required"));
				setIsFinish(false);
				isValidForm = false;
			}
		} else {
			if (companyName.length == 0) {
				fieldCompanyName.classList.add("is-invalid");
				showErrorContainer(true);
				setCompanyErrorMessage(t("Facility Name is required"));
				setIsFinish(false);
				isValidForm = false;
			}
			if (unn.length !== 10 || !/^\d+$/.test(unn)) {
				fieldunnNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setUnnErrorMessage(t("UNN must be 10 digits long."));
				setIsFinish(false);
				isValidForm = false;
			} else if (!isUNNUnique(unn)) {
				fieldunnNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setUnnErrorMessage(t("UNN already exists. Please enter a unique UNN."));
				setIsFinish(false);
				isValidForm = false;
			} else {
				showErrorContainer(false);
			}
			if (crNumber.length > 0) {
				if (wasteType !== "Government") {
					// CR Number validation: must be 10 digits
					if (crNumber.length !== 10 || !/^\d+$/.test(crNumber)) {
						fieldcrNumber.classList.add("is-invalid");
						showErrorContainer(true);
						setCrErrorMessage(t("CR Number must be 10 digits long."));
						setIsFinish(false);
						isValidForm = false;
					}
				}
			}
			if (phoneNumber.length > 0) {
				if (phoneNumber.length !== 10 || !phoneNumber.startsWith("05")) {
					fieldPhoneNumber.classList.add("is-invalid");
					showErrorContainer(true);
					setPhoneErrorMessage(t("Phone Number must be 10 digits and start with '05'."));
					setIsFinish(false);
					isValidForm = false;
				}
			}
		}
		if (!isButtonPressed) {
			showErrorContainer(true);
			setUnnErrorMessage(t("UNN was not verified"));
			setIsFinish(false);
			isValidForm == false;
		}
		if (isValidForm == false) {
			return;
		} else {
			const AddGeneratorSuccess = (responseObject) => {
				toast.success("Success");
				handleCancelNew();
				successCallBackFunction(true);
				setIsFinish(false);
			};

			const AddGeneratorFailure = (error) => {
				setTimeout(() => {
					setIsFinish(false);
				}, timeOut);

				toast.error("An error occured, please try again later.", {
					autoClose: timeOut,
				});
			};

			var AddGeneratorJSONString = JSON.stringify(AddGeneratorJSON);
			var AddCompanyEndPoint = Constants.AddTransporterGeneratorData + RelatedCompany;
			General.PostToEndPoint(AddCompanyEndPoint, null, AddGeneratorJSONString, AddGeneratorSuccess, AddGeneratorFailure);
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="industry" className="text-success" />
						</div>
						<div className="ms-2">{t(`New Generator`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">
								{t("Generated Waste Type")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<select
								id="generatedWasteTypeSelect"
								className={`form-select`}
								onChange={(e) => {
									setGeneratedWasteType(e.target.value);
									checkGeneratorType(e.target.value);
								}}
							>
								<option value="Hazardous Waste">{t("Hazardous Waste")}</option>
								<option value="Non-Hazardous Waste">{t("Non-Hazardous Waste")}</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("Company Type")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<select name="wasteTypeSelect" id="wasteTypeSelect" className="form-select" onChange={(e) => setWasteType(e.target.value)}>
								{/* <option value="Individual" data-name="">
									{t(`Individual`)}
								</option> */}
								<option value="Contractor" data-name="">
									{t(`Contractor`)}
								</option>
								<option value="Engineering company" data-name="">
									{t(`Engineering company`)}
								</option>
								<option value="Delegate" data-name="">
									{t(`Delegate`)}
								</option>
								<option value="Government" data-name="">
									{t(`Government`)}
								</option>
								<option value="Other" data-name="">
									{t(`other`)}
								</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Unified National Number`)}
								{isMandatoryUnn && <span style={{ color: "red" }}>*</span>}
							</label>
							<input
								type="text"
								id="unn"
								required={isMandatoryUnn}
								className={`form-control ${unn.length === 10 ? (isUnique ? "" : "is-invalid") : unn.length > 0 ? "is-invalid" : ""}`} // Apply invalid class based on length and uniqueness
								value={unn}
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict length to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setUNN((prevUNN) => {
											return input;
										});
										// Check uniqueness when the length reaches 10 digits
										if (input.length === 10) {
											setIsUnique(isUNNUnique(input)); // Pass input to isUNNUnique function
										} else {
											setIsUnique(true); // Reset uniqueness when less than 10 digits
										}
									}
									setCompanyName("");
									setAddress("");
									setPhoneNumber("");
									setCrNumber("");
									setIsDisabled(false);
									setIsDisabledCr(false);
									setIsButtonPressed(false);
								}}
							/>
							{unnErrorMessage && <div className="invalid-feedback">{unnErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<Button size="sm" onClick={handleVerify} disabled={isButtonPressed} variant="primary">
								{t("Verify")}
							</Button>
						</div>

						<div className="mb-3">
							<label className="form-label">{t(`CR Number`)}</label>
							{isMandatory && isMandatoryCr && <span style={{ color: "red" }}>*</span>}
							<input
								type="text"
								id="crNumber"
								disabled={isDisabledCr}
								className={`form-control ${crNumber.length === 10 ? "" : crNumber.length > 0 ? "is-invalid" : ""}`} // Add invalid class if not 10 digits
								value={crNumber}
								required={isMandatory}
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setCrNumber(input);
									}
								}}
							/>
							{crErrorMessage && crNumber.length !== 10 && <div className="invalid-feedback">{t("CR Number must be exactly 10 digits long.")}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Address`)}</label>
							{isMandatory && <span style={{ color: "red" }}>*</span>}
							<input
								type="text"
								required={isMandatory}
								id="address"
								className="form-control"
								disabled={isDisabledAddress}
								value={address}
								onChange={(event) => {
									setAddress(event.target.value);
									const input = event.target.value;
									if (input.length > 0) {
										setAddressErrorMessage("");
									}
								}}
							/>
							{addressErrorMessage && <div className="invalid-feedback">{addressErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Facility Name`)}</label>
							{isMandatoryName && <span style={{ color: "red" }}>*</span>}
							<input
								type="text"
								required={isMandatoryName}
								id="companyName"
								disabled={isDisabled}
								className="form-control"
								value={companyName}
								onChange={(event) => {
									setCompanyName(event.target.value);
									const input = event.target.value;
									if (input.length > 0) {
										setCompanyErrorMessage("");
									}
								}}
							/>
							{companyErrorMessage && <div className="invalid-feedback">{companyErrorMessage}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Phone Number`)}</label>
							{isMandatory && <span style={{ color: "red" }}>*</span>}
							<input
								type="text"
								id="phoneNumber"
								disabled={isDisabledPhone}
								className={`form-control ${phoneNumber.length === 10 && phoneNumber.startsWith("05") ? "" : phoneNumber.length > 0 ? "is-invalid" : ""}`} // Highlight if invalid
								value={phoneNumber}
								required={isMandatory}
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										setPhoneNumber(input);
									}
								}}
							/>
							{phoneNumber && (!phoneNumber.startsWith("05") || phoneNumber.length !== 10) && (
								<div className="invalid-feedback">{t("Phone number must start with '05' and be exactly 10 digits long.")}</div>
							)}
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
					<IconButton size="sm" icon="check" className="ms-2" variant="primary" onClick={handleAddGenerator}>
						{t("Confirm")}
					</IconButton>
				</div>

				{/* {errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)} */}
			</Modal.Footer>
		</Modal>
	);
};

AddGenerator.propTypes = {
	showComponent: PropTypes.bool,
};

export default AddGenerator;
