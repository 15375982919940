import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const EditTruck = ({ successCallBackFunction, allTrucks, hideEditTruck, selectedTrucks, RelatedCompany, showComponent }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [plateNumber, setPlateNumber] = useState(selectedTrucks.plateNumber || "");
	const [type, setType] = useState(selectedTrucks.type || "");
	const [vehicleSerialNumber, setVehicleSerialNumber] = useState(selectedTrucks.vehicleSerialNumber || "");
	const [serialNumberError, setSerialNumberError] = useState("");
	const [subType, setSubType] = useState(selectedTrucks.subType || "");
	const [size, setSize] = useState(selectedTrucks.size || "");
	const [tareWeight, setTareWeight] = useState(selectedTrucks.tareWeight || "");
	const [capacity, setCapacity] = useState(selectedTrucks.capacity || "");
	const [capacityUnit, setCapacityUnit] = useState(selectedTrucks.capacityUnit || "");
	const [transporterCompanyId, setTransporterCompanyId] = useState("truck");
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const timeOut = 5000;
	const [plateNumberError, setPlateNumberError] = useState("");
	const [vehicleTypeError, setVehicleTypeError] = useState("");
	const [tareWeightError, setTareWeightError] = useState("");
	const [capacityError, setCapacityError] = useState("");
	const plateNumberRegex = /^[A-Za-z]{3}[0-9]{4}$/;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const vehicleData = {
		"Compactor Truck": ["Rear Loader Compactor", "Side Loader Compactor", "Front Loader Compactor"],
		"Loader Truck": ["Hook Lift Loader", "Skip Loader"],
		Tankers: ["Liquid Waste Tanker", "Vacuum Tanker", "Trella Tanker"],
		"Tipper Truck": ["Tipper", "Mini Tipper", "Crane Tipper", "Tipper Trella"],
		"Waste Compactor": ["Static Compactor", "Trella Compactor"],
		"Enclosed Truck": ["Enclosed Refrigerated Truck", "Enclosed Medical Waste Truck", "Enclosed Trella", "Enclosed Van"],
		"Pickup Truck": ["Pickup", "Refrigerated Pickup", "Mini Pickup"],
		"Miscellaneous Truck": ["Curtain Truck", "Flatbed Truck", "Carbon Ash Truck"],
	};

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (compTypeID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			// console.log(responseObject);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		// console.log(compTypeID);
		var endPoint = Constants.GetCompaniesByType + compTypeID;
		General.GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
	};

	/*************** LOAD COMPANIES END ************************/

	const handleDropDownChange = () => {};

	const handleCancelNew = () => {
		hideEditTruck();
	};
	const checkCapacity = (value) => {
		var capacity = document.getElementById("capacity");
		if (value >= 0) {
			setCapacity(value);
			setCapacityError("");
		} else {
			setCapacityError(t("Capacity must be a positive number."));
		}
	};

	const handleEditTruck = async () => {
		setIsFinish(true);

		//get all elements
		var fieldplateNumber = document.getElementById("plateNumber");
		var fieldtype = document.getElementById("wastetypeTypeSelect");
		var fieldsubType = document.getElementById("subType");
		var fieldsize = document.getElementById("size");
		var errorContainerDate = document.getElementById("errorContainerDate");
		var EditTruckJSON = {
			plateNumber: plateNumber,
			type: type,
			subType: subType,
			size: size,
			tareWeight: tareWeight,
			capacity: capacity,
			capacityUnit: capacityUnit,
			vehicleSerialNumber: vehicleSerialNumber,
		};
		var isValidForm = true;
		//check if fields are filled and set the invalid class in case they are not

		const plateExists = allTrucks.some((truck) => truck.plateNumber === plateNumber && truck.id !== selectedTrucks.id);

		if (!plateNumberRegex.test(plateNumber)) {
			setErrorMessage(t("Plate Number must be in the format ABC1234 (3 letters followed by 4 numbers)."));
			setPlateNumberError(t("Plate Number must be in the format ABC1234 (3 letters followed by 4 numbers)."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		} else {
			setPlateNumberError(t(""));
		}
		if (plateExists) {
			setPlateNumberError(t("The plate number already exists."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		}
		if (!type) {
			setErrorMessage(t("Truck Type Required."));
			setVehicleTypeError(t("Truck Type Required."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		} else {
			setVehicleTypeError(t(""));
		}
		if (!vehicleSerialNumber) {
			setErrorMessage(t("Vehicle Serial Number Required."));
			setSerialNumberError(t("Vehicle Serial Number Required."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		} else {
			setSerialNumberError(t(""));
		}

		if (!tareWeight) {
			setErrorMessage(t("Tare Weight must be a valid number."));
			setTareWeightError(t("Tare Weight must be a valid number."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		} else {
			setTareWeightError(t(""));
		}
		if (isNaN(tareWeight) || tareWeight <= 0) {
			setErrorMessage(t("Tare Weight must be a positive number."));
			setTareWeightError(t("Tare Weight must be a valid number."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		}
		if (!plateNumber || !type || !tareWeight) {
			setErrorMessage(t("Plate Number, Truck Type, and Tare Weight are required."));
			showErrorContainer(true);
			setIsFinish(false);
			var isValidForm = false;
		}
		if (isValidForm == false) {
			return;
		} else {
			const EditTruckSuccess = (responseObject) => {
				toast.success("Success");
				handleCancelNew();
				successCallBackFunction(true);
				setIsFinish(false);
			};

			const EditTruckFailure = (error) => {
				setTimeout(() => {
					setIsFinish(false);
				}, timeOut);

				toast.error("An error occured, please try again later.", {
					autoClose: timeOut,
				});
			};

			var EditTruckJSONString = JSON.stringify(EditTruckJSON);
			var AddCompanyEndPoint = Constants.EditTransporterTruckData + selectedTrucks.id;
			General.PutToEndPoint(AddCompanyEndPoint, null, EditTruckJSONString, EditTruckSuccess, EditTruckFailure);
		}
	};
	const {
		config: { isRTL },
	} = useContext(AppContext);
	const handleTypeChange = (event) => {
		setType(event.target.value);
		setSubType(""); // Reset subType when type changes
	};
	const handlePlateNumberChange = (value) => {
		// Convert input to uppercase for consistency
		let formattedValue = value.toUpperCase();

		// Enforce letters for the first three characters
		let lettersPart = formattedValue.slice(0, 3).replace(/[^A-Z]/g, ""); // Only letters in first three positions

		// Enforce digits for the remaining four characters
		let numbersPart = formattedValue.slice(3).replace(/[^0-9]/g, ""); // Only numbers in last four positions

		// Combine the two parts and limit the length to 7
		formattedValue = (lettersPart + numbersPart).slice(0, 7);

		setPlateNumber(formattedValue);

		// Validate the final value
		const plateNumberRegex = /^[A-Z]{3}\d{4}$/; // Strict format: 3 uppercase letters followed by 4 digits
		if (!plateNumberRegex.test(formattedValue)) {
			setPlateNumberError(t("Plate Number must be in the format ABC1234 (3 letters followed by 4 numbers)."));
		} else {
			setPlateNumberError(""); // Clear error if valid
		}
	};
	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="fa-solid fa-truck" />
						</div>
						<div className="ms-2">{t(`Edit Vehicle`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">
								{t(`Plate Number`)}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="plateNumber"
								className={`form-control ${plateNumberError ? "is-invalid" : ""}`}
								value={plateNumber}
								onChange={(event) => handlePlateNumberChange(event.target.value)}
							/>
							{plateNumberError && <div className="invalid-feedback">{plateNumberError}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Vehicle Serial Number`)}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="plateNumber"
								className={`form-control ${serialNumberError ? "is-invalid" : ""}`}
								value={vehicleSerialNumber}
								onChange={(event) => setVehicleSerialNumber(event.target.value)}
							/>
							{serialNumberError && <div className="invalid-feedback">{serialNumberError}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Vehicle Type`)}
								<span style={{ color: "red" }}>*</span>
							</label>
							<select id="type" className={`form-control ${vehicleTypeError ? "is-invalid" : ""}`} value={type} onChange={handleTypeChange}>
								<option value="">{t("Select Vehicle Type")}</option>
								{Object.keys(vehicleData).map((vehicleType) => (
									<option key={vehicleType} value={vehicleType}>
										{t(vehicleType)}
									</option>
								))}
							</select>
							{vehicleTypeError && <div className="invalid-feedback">{vehicleTypeError}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Vehicle Subtype`)}</label>
							<select
								id="subType"
								className="form-control"
								value={subType}
								onChange={(event) => setSubType(event.target.value)}
								disabled={!type} // Disable if no type is selected
							>
								<option value="">{t("Select Vehicle Subtype")}</option>
								{/* Check if vehicleData[type] exists and is an array before mapping */}
								{type &&
									vehicleData[type] &&
									vehicleData[type].map((subTypeOption) => (
										<option key={subTypeOption} value={subTypeOption}>
											{t(subTypeOption)}
										</option>
									))}
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Vehicle Gross Weight`)}</label>
							<input type="number" id="size" className="form-control" value={size} onChange={(event) => setSize(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t(`Vehicle Tare Weight`)}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="number"
								id="tareWeight"
								className={`form-control ${tareWeightError ? "is-invalid" : ""}`}
								value={tareWeight}
								onChange={(event) => setTareWeight(event.target.value)}
							/>
							{tareWeightError && <div className="invalid-feedback">{tareWeightError}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Vehicle Load Capacity`)}</label>
							<input
								type="number"
								id="capacity"
								className={`form-control ${capacityError ? "is-invalid" : ""}`}
								value={capacity}
								min={0}
								onChange={(event) => checkCapacity(event.target.value)}
							/>
							{capacityError && <div className="invalid-feedback">{capacityError}</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Vehicle Load Capacity UOM`)}</label>
							<select id="capacityUnit" className={`form-select`} value={capacityUnit} onChange={(e) => setCapacityUnit(e.target.value)}>
								<option key={0} value="Kgs">
									{t("Kgs")}
								</option>
								<option key={1} value="Ltrs">
									{t("Ltrs")}
								</option>
								<option key={2} value="Tons">
									{t("Tons")}
								</option>
							</select>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
					<IconButton size="sm" icon="check" className="ms-2" variant="primary" onClick={handleEditTruck}>
						{t("Confirm")}
					</IconButton>
				</div>

				{/* {errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)} */}
			</Modal.Footer>
		</Modal>
	);
};

EditTruck.propTypes = {
	showComponent: PropTypes.bool,
};

export default EditTruck;
