import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import * as Constants from "common/constants";
import IconButton from "components/common/IconButton";
import { EncryptStorage } from "encrypt-storage";
import { Dropdown, Col, Modal, Row, Button, Image, Tooltip, overlay, OverlayTrigger } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import AdvanceTable from "components/common/advance-table/AdvanceTable";

import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AddGenerator from "./AddGenerator";
import DeleteGeneratorConfirmation from "./DeleteGeneratorConfirmation";
import EditGenerator from "./EditGenerator";
import AddContact from "./AddContact";
import ViewGenerator from "./ViewGenerator";
import { useNavigate } from "react-router-dom";
import UpliftCss from "../../common/upliftcss.module.css";

const ManageRelatedGenerators = ({ RelatedCompanytypeNamePlural, CompanyID }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [fetchFlag, setFetchFlag] = useState(false);
	const [t, i18n] = useTranslation();
	const [selectedCompany, setSelectedCompany] = useState([]);
	const [selectedGenerator, setGenerator] = useState([]);
	const [addContactsPopupVisibility, showContactsPopup] = useState(false);
	const [addGeneratorPopupVisibility, showGeneratorPopup] = useState(false);
	const [editGeneratorPopupVisibility, showEditGenerator] = useState(false);
	const [deleteGeneratorPopupVisibility, showDeleteGeneratorPopup] = useState(false);
	const [viewGeneratorPopupVisibility, showViewGeneratorPopup] = useState(false);
	const navigate = useNavigate();
	const columns = [
		{
			accessor: "id",
			Header: t("ID"),
		},
		{
			accessor: "companyName",
			Header: t("Facility Name"),
		},
		{
			accessor: "generatedWasteType",
			Header: t("Generated Waste Type"),
		},
		{
			accessor: "wasteType",
			Header: t("Company Type"),
		},
		{
			accessor: "unn",
			Header: t("UNN"),
		},
		{
			accessor: "crNumber",
			Header: t("CR Number"),
		},
		{
			accessor: "phoneNumber",
			Header: t("Phone Number"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const [data, setData] = useState([]);
	const [onRefresh, setOnRefresh] = useState(null);

	//first function that's called
	useEffect(() => {
		setOnRefresh(null);
	}, [window.location.href, onRefresh]);

	const handlerefresh = (value) => {
		setOnRefresh(value);
		setFetchFlag(value);
	};
	const handleAddGenerator = () => {};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	useEffect(() => {
		setOnRefresh(null);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
		setSelectedCompany(selectedCompany);
	}, [window.location.href, onRefresh]);
	/*************** ADD Contact START ************************/
	const handleClickAddContacts = () => {
		showContactsPopup(true);
	};
	const handleCancelAddContacts = () => {
		showContactsPopup(false);
	};
	/*************** ADD Contact END ************************/
	/*************** ADD generator START ************************/

	const handleClickAddGenerator = () => {
		showGeneratorPopup(true);
	};

	const handleCancelAddGenerator = () => {
		showGeneratorPopup(false);
	};
	/*************** ADD generator END ************************/
	/*************** ADD generator START ************************/

	const handleClickEditGenerator = (gen) => {
		setGenerator(gen);
		showEditGenerator(true);
	};

	const handleCancelEditGenerator = () => {
		showEditGenerator(false);
	};
	/*************** ADD generator END ************************/

	/*************** delete generator START ************************/
	const handleClickDeleteGenetrator = (gen) => {
		setGenerator(gen);
		showDeleteGeneratorPopup(true);
	};
	const handleCancelDelete = () => {
		showDeleteGeneratorPopup(false);
	};
	/*************** delete generator END ************************/
	/*************** View generator START ************************/
	const handleClickViewGenetrator = (gen) => {
		setGenerator(gen);
		showViewGeneratorPopup(true);
	};
	const handleCancelView = () => {
		showViewGeneratorPopup(false);
	};
	/*************** View generator END ************************/
	const handleClickViewContacts = (company) => {
		navigate("/manageContacts", { state: { company } });
	};
	useEffect(() => {
		async function fetchData() {
			setFetchFlag(null);
			try {
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				const response = await fetch(Constants.GetTransporterGeneratorsData + selectedCompany, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + accessToken,
					},
				});
				const responseText = await response.text();

				try {
					const newData = JSON.parse(responseText);
					let arrayOfGenerators = Object.values(newData);
					const generators = arrayOfGenerators.flatMap((generator) => {
						let parsedgenerator;
						try {
							parsedgenerator = typeof generator === "string" ? JSON.parse(generator) : generator;
						} catch (e) {
							console.error("Error parsing task:", task, e);
							return []; // Skip this task if it can't be parsed
						}
						const originalValues = {
							id: parsedgenerator.id,
							generetorCompanyId: parsedgenerator.generetorCompanyId,
							generatedWasteType: parsedgenerator.generatedWasteType,
							wasteType: parsedgenerator.wasteType,
							unn: parsedgenerator.unn,
							crNumber: parsedgenerator.crNumber,
							address: parsedgenerator.address,
							companyName: parsedgenerator.companyName,
							phoneNumber: parsedgenerator.phoneNumber,
						};
						parsedgenerator.id = t(parsedgenerator.id);
						parsedgenerator.generetorCompanyId = t(parsedgenerator.generetorCompanyId);
						parsedgenerator.generatedWasteType = t(parsedgenerator.generatedWasteType);
						parsedgenerator.wasteType = t(parsedgenerator.wasteType);
						parsedgenerator.unn = t(parsedgenerator.unn);
						parsedgenerator.crNumber = t(parsedgenerator.crNumber);
						parsedgenerator.address = t(parsedgenerator.address);
						parsedgenerator.companyName = t(parsedgenerator.companyName);
						parsedgenerator.phoneNumber = t(parsedgenerator.phoneNumber);
						parsedgenerator.action = (
							<>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Edit")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-edit"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickEditGenerator(originalValues)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("View")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-eye"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickViewGenetrator(parsedgenerator)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("View Contacts")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-users"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickViewContacts(parsedgenerator.generetorCompanyId)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Delete")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn-danger"
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-trash"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickDeleteGenetrator(parsedgenerator)}
										></IconButton>
									</div>
								</OverlayTrigger>
							</>
						);
						return parsedgenerator;
					});
					setData(generators);
				} catch (jsonError) {
					console.error("Response text:", jsonError);
				}
			} catch (networkError) {
				console.error("Fetch error:", networkError);
			}
			//}
		}

		fetchData();
	}, [fetchFlag]);
	return (
		<div>
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(`Manage ${RelatedCompanytypeNamePlural}`)}</h5>
					</div>
				</div>
			</div>
			<div className={`card ${UpliftCss.BodyBorder}`}>
				<div className="tool-header">
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: "fixed" }} id="newcontract">
								{t(`New Generator`)}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label icon-position end-text">
							<IconButton
								className="btn-primary botton-circle"
								iconClassName="fs--5"
								size="sm"
								icon="fa-solid fa-circle-plus"
								style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
								onClick={handleClickAddGenerator}
							></IconButton>
						</div>
					</OverlayTrigger>
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: "fixed" }} id="newcontract">
								{t(`New Contact`)}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label icon-position end-text">
							<IconButton
								className="btn-primary botton-circle"
								iconClassName="fs--5"
								size="sm"
								icon="fa-solid fa-user-plus"
								style={{ margin: "auto 0.25rem", padding: "9px 8px 9px" }}
								onClick={handleClickAddContacts}
							></IconButton>
						</div>
					</OverlayTrigger>
				</div>
				<div className="card-body">
					<div className={`mb-3 ${UpliftCss.tableHeader}`}>
						<AdvanceTableWrapper columns={columns} data={data} className={`${UpliftCss.tableHeader}`} sortable pagination perPage={10}>
							<Row className="flex-end-center mb-3">
								<Col xs="auto" sm={6} lg={4}>
									<AdvanceTableSearchBox className={`${UpliftCss.filterFieldBoxes}`} table placeholder={t("Search")} />
								</Col>
							</Row>
							<AdvanceTable
								table
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: "fs--1 mb-0 overflow-hidden",
								}}
							/>
							<div className="mt-3">
								<AdvanceTableFooter rowCount={data.length} table rowInfo navButtons rowsPerPageSelection />
							</div>
						</AdvanceTableWrapper>

						{addGeneratorPopupVisibility && (
							<AddGenerator
								successCallBackFunction={handlerefresh}
								hideAddGenerator={handleCancelAddGenerator}
								showComponent={addGeneratorPopupVisibility}
								RelatedCompany={selectedCompany}
								allData={data}
							></AddGenerator>
						)}
						{deleteGeneratorPopupVisibility && (
							<DeleteGeneratorConfirmation
								successCallBackFunction={handlerefresh}
								selectedGenerator={selectedGenerator}
								hideDeleteConfirmation={handleCancelDelete}
								showComponent={deleteGeneratorPopupVisibility}
								company={selectedCompany}
							></DeleteGeneratorConfirmation>
						)}
						{viewGeneratorPopupVisibility && (
							<ViewGenerator
								successCallBackFunction={handlerefresh}
								selectedGenerator={selectedGenerator}
								hideViewGenerator={handleCancelView}
								showComponent={viewGeneratorPopupVisibility}
								RelatedCompany={selectedCompany}
								allData={data}
							></ViewGenerator>
						)}

						{editGeneratorPopupVisibility && (
							<EditGenerator
								successCallBackFunction={handlerefresh}
								selectedGenerator={selectedGenerator}
								hideEditGenerator={handleCancelEditGenerator}
								showComponent={editGeneratorPopupVisibility}
								RelatedCompany={selectedCompany}
								allData={data}
							></EditGenerator>
						)}
						{addContactsPopupVisibility && (
							<AddContact
								successCallBackFunction={handlerefresh}
								hideAddContact={handleCancelAddContacts}
								showComponent={addContactsPopupVisibility}
								RelatedCompany={selectedCompany}
							></AddContact>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ManageRelatedGenerators.propTypes = {
	RelatedCompanytypeID: PropTypes.string,
	CompanyRelationshipTypeID: PropTypes.string,
};

export default ManageRelatedGenerators;
