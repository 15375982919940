import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Col, Row } from "react-bootstrap";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";
import LoaderIcon from "../Preloader";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import store from "reducers/store";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "reducers/actions";
import { useForm } from "react-hook-form";
const encryptStorage1 = new EncryptStorage("secret-key-value", {
	prefix: "@mwan",
});
const EditUser = ({
	userID,
	firstName,
	lastName,
	email,
	enabled,
	mobileNumber,
	IDNumber,
	IDType,
	getCompanyUsers,
	showComponent,
	hideEditUser,
	successCallBackFunction,
	dropdownVisible,
	showModal,
	setShowModal,
}) => {
	const capitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	const {
		register,
		handleSubmit,
		formState: { errors, dirtyFields },
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			user_id: userID,
			firstName,
			lastName,
			email,
			isEnabled: enabled,
			mobile_number: mobileNumber,
			id_number: IDNumber,
			id_type: capitalizeFirstLetter(IDType),
		},
	});

	const [t, i18n] = useTranslation();

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const handleCancelEdit = () => {
		setShowModal(false);
		hideEditUser();
		reset();
	};

	const handleEditUser = async (formData) => {
		setLoading(true);
		var EditUserEndPoint = Constants.EditUserEndPoint.concat(formData.user_id);
		// Get the dirty fields only
		const dirtyData = Object.keys(dirtyFields).reduce((acc, field) => {
			acc[field] = formData[field];
			return acc;
		}, {});
		try {
			const response = await General.PutToEndPoint(EditUserEndPoint, null, dirtyData, EditUserSuccess, EditUserFailure);
		} catch (error) {
			console.error("Edit user failed:", error);
			setLoading(false);
		}
	};

	const EditUserSuccess = (response) => {
		if (response?.httpResponse.status != 1) {
			toast.error(response.httpResponse.message, { autoClose: 4000 });
			setLoading(false);
			// You can perform any other logic here if needed
		} else {
			// Perform the async logic here
			const decodedAccessToken = jwtDecode(encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage));
			console.log("Decoded Access Token:", decodedAccessToken);
			// Perform async operation after condition check
			const loggedInUserData = async () => {
				const res = await General.GetLoggedInUserData(decodedAccessToken.sub);
				console.log("User data received:", res);
				return res;
			};
			// Log user data after await
			loggedInUserData()
				.then((data) => {
					// Do something with data if needed
					encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, data);
					dispatch(updateUserInfo(data));
					toast.success(response.httpResponse.message, { autoClose: 4000 });
					setShowModal(false);
					setLoading(false);
					if (successCallBackFunction) successCallBackFunction();
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
				});
		}
	};

	const EditUserFailure = (error) => {
		console.error("Edit user failed:", error);
		setLoading(false);
	};

	return (
		<Modal show={showModal} onHide={handleCancelEdit} centered>
			<form onSubmit={handleSubmit(handleEditUser)}>
				{loading && <LoaderIcon />}
				<Modal.Header closeButton>
					<Modal.Title>{t("Edit User")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="g-0 align-items-center">
						<Col>
							<div className="mb-3">
								<label className="form-label">{t("User ID")}</label>
								<input type="text" className="form-control" value={userID} disabled />
							</div>
							<div className="mb-3">
								<label className="form-label">{t("First Name")}</label>
								<input type="text" className="form-control" placeholder={t("First Name")} {...register("firstName", { required: t(em.fistName_Empty) })} />
								{errors.firstName && <div className="text-danger">{errors.firstName.message}</div>}
							</div>
							<div className="mb-3">
								<label className="form-label">{t("Last Name")}</label>
								<input type="text" className="form-control" placeholder={t("Last Name")} {...register("lastName", { required: t(em.lastname_empty) })} />
								{errors.lastName && <div className="text-danger">{errors.lastName.message}</div>}
							</div>
							<div className="mb-3">
								<label className="form-label">{t("Email")}</label>
								<input type="text" className="form-control" disabled {...register("email", { pattern: { value: /\S+@\S+\.\S+/, message: t(em.invalid_email) } })} />
								{errors.email && <div className="text-danger">{errors.email.message}</div>}
							</div>
							<div className="mb-3">
								<label className="form-label">{t("Mobile Number")}</label>
								<input
									type="text"
									className="form-control"
									placeholder={t("KSA Mobile Number")}
									{...register("mobile_number", {
										required: t(em.format_phone_number),
										pattern: { value: /^05\d{8}$/, message: t(em.format_phone_number) },
									})}
								/>
								{errors.mobile_number && <div className="text-danger">{errors.mobile_number.message}</div>}
							</div>
							<div className="mb-3">
								<label className="form-label">{t("ID Type")}</label>
								<select className="form-select" {...register("id_type", { required: t(em.id_type_unselected) })}>
									<option value="">{t("Select ID Type")}</option>
									<option value="National ID">{t("National ID")}</option>
									<option value="Iqama">{t("Iqama")}</option>
								</select>
								{errors.id_type && <div className="text-danger">{errors.id_type.message}</div>}
							</div>
							<div className="mb-3">
								<label className="form-label">{t("ID Number")}</label>
								<input
									type="text"
									className="form-control"
									placeholder={t("ID Number")}
									{...register("id_number", {
										required: t(em.id_number),
										pattern: { value: /^\d{10}$/, message: t(em.id_number) },
									})}
								/>
								{errors.id_number && <div className="text-danger">{errors.id_number.message}</div>}
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button size="sm" type="submit" variant="primary">
						{t("Confirm")}
					</Button>
					<Button variant="secondary" onClick={handleCancelEdit}>
						{t("Cancel")}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};

EditUser.propTypes = {
	userID: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	enabled: PropTypes.bool,
	mobileNumber: PropTypes.string,
	IDNumber: PropTypes.string,
	IDType: PropTypes.string,
	getCompanyUsers: PropTypes.func,
	showComponent: PropTypes.bool,
	hideEditUser: PropTypes.func,
	successCallBackFunction: PropTypes.func,
	dropdownVisible: PropTypes.bool,
	showModal: PropTypes.bool.isRequired,
	setShowModal: PropTypes.func.isRequired,
};

export default EditUser;
