import React, { useState, useContext } from "react";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import FaqAccordionItem from "../faq/faq-accordion/FaqAccordionItem";
import { faqAccordions as faqsData } from "data/faqs";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Faq = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const [faqs] = useState(faqsData);
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100}`}>
			<NavbarStandard />
			<Container className={`${styles.padding35em} ${styles.addPageHeight} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle} mb-4`}>{t("FAQ")}</h2>
				<Accordion id="accordionFaq" className="overflow-hidden">
					<FaqAccordionItem
						key={0}
						id={0}
						title={t("1. What is an electronic manifest?")}
						description={`${t("What_is_an_electronic_manifest_FAQ_Answer")}`}
						isFirst={true}
						isLast={false}
					/>
					<FaqAccordionItem
						key={1}
						id={1}
						title={t("2. Who are the target groups for activating the e-Manifest system?")}
						description={t("target_groups_system_answer")}
						isFirst={false}
						isLast={false}
					/>
					<FaqAccordionItem
						key={2}
						id={2}
						title={t("3. How can I access the e-Manifest system?")}
						description={t(
							"The e-Manifest system is a module component of the MWAN Waste Management Platform, an IT solution used by MWAN, and the waste management sector to track MWAN regulated waste activity. To access the eManifest system, register for a free account at https://emanifest.mwan.gov.sa/."
						)}
						isFirst={false}
						isLast={false}
					/>
					<FaqAccordionItem
						key={3}
						id={3}
						title={t("4. What are the waste streams covered by the e-Manifest Platform?")}
						description={t("What_are_waste_streams_covered_answer")}
						isFirst={false}
						isLast={true}
					/>
				</Accordion>
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Faq;
