import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import CompaniesDropDown from "./CompaniesDropDown";
import { useTranslation } from "react-i18next";
import CustomInput from "components/common/CustomInput";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import keycloakService from "keycloakService";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
import LoaderIcon from "components/custom/Preloader";
import { useDispatch } from 'react-redux';
import { updateUserInfo } from "reducers/actions";

const EditProfile = ({
	userID,
	firstName,
	lastName,
	email,
	enabled,
	mobileNumber,
	IDNumber,
	IDType,
	SelectedGroups,
	showComponent,
	hideEditUser,
	showCompaniesDropDown,
	dropdownVisible, //used to know if the admin changes companyid and companytypeid for user being editted
	successCallBackFunction,
}) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [isFinish, setIsFinish] = useState(false);
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [errorDropDownClassCompany, seterrorDropDownClassCompany] = useState("");
	const timeOut = 5000;
	const [errorDropDownClassCompanyType, seterrorDropDownClassCompanyType] = useState("");
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch(); 
	const Schema = Yup.object().shape({
		userID: Yup.string().required("Required"),
		firstNameEdit: Yup.string().required("Required"),
		lastNameEdit: Yup.string().required("Required"),
		emailEdit: Yup.string().email("Invalid email address").required("Required"),
		mobileNumber: Yup.string()
			.test("starts-with-05", 'Phone number must start with "05"', (value) => value && value.startsWith("05"))
			.test("is-numeric", "Phone number must contain only numeric digits", (value) => /^\d+$/.test(value))
			.test("is-valid-length", "Phone number must have 10 digits in total", (value) => value && value.length === 10)
			.required("Required"),
		IDNumber: Yup.string().min(10, "ID number should be exactly 10 digits").max(10, "ID number should be exactly 10 digits").required("Required"),
		IDType: Yup.string().required("Required"),
	});

	const handleCancelEdit = () => {
		hideEditUser();
	};

	const GetSelectedCompanyIDFromTheProject = (compID) => {
		if (dropdownVisible) {
			updateCompanyID(compID);
		}
	};
	const GetSelectedCompanyTypeIDFromTheProject = (comtypeid) => {
		if (dropdownVisible) {
			updateCompanyTypeID(comtypeid);
		}
	};

	const saveDataLocalStorage = ({ values }) => {
		let LocalDataJson = localStorage.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		let LocalData = JSON.parse(LocalDataJson);
		let USERINFO = {
			companyID: LocalData.companyID,
			companyLicenseNumber: LocalData.companyLicenseNumber,
			createdDateTime: LocalData.createdDateTime,
			createdTimestamp: LocalData.createdTimestamp,
			email: values.emailEdit.toLowerCase(),
			emailVerified: LocalData.emailVerified, // ask about
			enabled: enabled,
			firstName: values.firstNameEdit,
			fullName: values.firstNameEdit + " " + values.lastNameEdit,
			groups: [values.TypeIDEdit, values.IDEdit],
			id: LocalData.id,
			idNumber: values.IDNumber,
			idType: values.IDType,
			lastName: values.lastNameEdit,
			mobileNumber: values.mobileNumber,
		};
		localStorage.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, JSON.stringify(USERINFO));
	};

	const EditUserSuccess = async(responseObject, values) => {
		if (responseObject?.httpResponse.status!=1) {
			toast.error(responseObject.httpResponse.message, { autoClose: 4000 });
			setIsFinish(false);
			// You can perform any other logic here if needed
		}else{
			const decodedAccessToken = jwtDecode(encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage));
			const loggedInUserData = async () => {
				const res = await General.GetLoggedInUserData(decodedAccessToken.sub);
				console.log("User data received:", res);
				return res
			  }
			  loggedInUserData().then(data => {
				// Do something with data if needed
				encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, data);
				dispatch(updateUserInfo(data));
				toast.success("Profile has been updated", { autoClose: 4000 });
				setIsFinish(false);
				hideEditUser();
			  }).catch(error => {
				console.error("Error fetching user data:", error);
			  });
		}
/* 		const decodedAccessToken = jwtDecode(encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage));
		var loggedInUserData = await General.GetLoggedInUserData(decodedAccessToken.sub);
		await encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, loggedInUserData);
		//toast.success(response.httpResponse.message, { autoClose: 4000 });
		setIsFinish(false);
		hideEditUser();
		toast.success("Profile has been updated", { autoClose: 4000 });
		dispatch(updateUserInfo(loggedInUserData)); */
	};

	const EditUserFailure = (error) => {
		if (error) {
			toast.error(t("Profile did not update. Please try again later or check your internet"), {
				autoClose: timeOut,
			});

			//  showErrorContainer(true);
		}
	};

	return (
		<Modal show={showComponent} onHide={handleCancelEdit} size="lg">
			<Formik
				validationSchema={Schema}
				initialValues={{
					userID: userID,
					firstNameEdit: firstName,
					lastNameEdit: lastName,
					emailEdit: email,
					mobileNumber: mobileNumber,
					IDNumber: IDNumber,
					IDType: IDType,
					TypeIDEdit: SelectedGroups[0] ?? "",
					IDEdit: SelectedGroups[1] ?? "",
				}}
				onSubmit={(values) => {
					setIsFinish(true);
					showLoaderContainer(true);
					showHideButtons(false);
					var EditUserEndPoint = Constants.EditUserEndPoint + userID;

					var EditProfileJSON = {
						isEnabled: true,
						firstName: values.firstNameEdit,
						lastName: values.lastNameEdit,
						email: values.emailEdit,
						id_Type: values.IDType,
						id_number: values.IDNumber,
						mobile_number: values.mobileNumber,
						/* language: Constants.Language,
						userData: {
							isEnabled: true,
							firstName: values.firstNameEdit,
							lastName: values.lastNameEdit,
							email: values.emailEdit,
							id_Type: values.IDType,
							id_number: values.IDNumber,
							mobile_number: values.mobileNumber,
							//  GroupIDs: [values.TypeIDEdit.id, values.IDEdit.id],
							//  GroupNames: [values.TypeIDEdit.name, values.IDEdit.name],
						}, */
					};

					var EditUserJSONString = JSON.stringify(EditProfileJSON);

					General.PutToEndPoint(EditUserEndPoint, null, EditUserJSONString, (response) => EditUserSuccess(response, values), EditUserFailure);
				}}
			>
				{({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
					<>
						{isFinish && <LoaderIcon />}
						<Modal.Header closeButton className="border-200">
							<Modal.Title as="h5">
								<Flex alignItems="center">
									<div className="icon-item bg-soft-success shadow-none">
										<FontAwesomeIcon icon="user" className="text-success" />
									</div>
									<div className="ms-2">{t("Edit Profile")}</div>
								</Flex>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="g-0 align-items-center">
								<Col>
									<div className="mb-3">
										<Field component={CustomInput} value={values.userID} disabled={true} title={t("User ID")} onChange={handleChange("userID")} errors={errors.userID} />
									</div>
									{/* <div className="mb-3">
                    <div className="gx-2 gy-3 row">
                      <div class="col-md-6">
                        <div>
                          <label className="form-label">
                            {t("Company type")}:
                          </label>
                          <select
                            disabled={true}
                            name="productCategory"
                            id="dropdownCompanyType"
                            className="form-select "
                          >
                            <option value="0">{t("Company type")}</option>
                            <option
                              selected={true}
                              value={values.IDEdit}
                              key={values.IDEdit}
                            >
                              {values.IDEdit.name}
                            </option>
                          </select>
                          <div className="invalid-feedback"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label className="form-label">{t("Company")}:</label>
                          <select
                            disabled={true}
                            name="productSubCategory"
                            id="dropdownCompanies"
                            className="form-select "
                          >
                            <option value="0">{t("Company type")}</option>
                            <option
                              selected={true}
                              key={values.TypeIDEdit}
                              value={values.TypeIDEdit}
                            >
                              {values.TypeIDEdit.name}
                            </option>
                          </select>
                          <div className="invalid-feedback"></div>
                        </div>
                      </div>

                      <div
                        className="loginAlert"
                        id="errorContainerAllCompanies"
                      ></div>
                    </div>
                  </div> */}
									<div className="mb-3">
										<Field
											component={CustomInput}
											value={values.firstNameEdit}
											disabled={false}
											title={t("First Name")}
											placeholder={t("First Name")}
											onChange={handleChange("firstNameEdit")}
											errors={errors.firstNameEdit}
										/>
									</div>
									<div className="mb-3">
										<Field
											component={CustomInput}
											value={values.lastNameEdit}
											disabled={false}
											title={t("Last Name")}
											placeholder={t("Last Name")}
											onChange={handleChange("lastNameEdit")}
											errors={errors.lastNameEdit}
										/>
									</div>
									<div className="mb-3">
										<Field
											component={CustomInput}
											value={values.emailEdit}
											disabled={true}
											title={t("Email")}
											placeholder={t("Email")}
											onChange={handleChange("emailEdit")}
											errors={errors.emailEdit}
										/>
									</div>

									<div className="mb-3">
										<Field
											component={CustomInput}
											value={values.mobileNumber}
											disabled={false}
											title={t("Mobile Number")}
											placeholder={t("KSA Mobile Number")}
											onChange={handleChange("mobileNumber")}
											errors={errors.mobileNumber}
										/>
									</div>

									<div className="mb-3">
										<label className="form-label">{t("ID Type")}</label>
										<select name="productCategory" id="ddIDTypeEdit" className="form-select" value={values.IDType} onChange={(event) => setFieldValue("IDType", event.target.value)}>
											<option value="">{t("Select ID Type")}</option>
											<option selected={values.IDType == "1"} value="National ID">
												{t("National ID")}
											</option>
											<option selected={values.IDType == "2"} value="Iqama">
												{t("Iqama")}
											</option>
										</select>
									</div>
									<div className="mb-3">
										<Field
											component={CustomInput}
											value={values.IDNumber}
											disabled={false}
											title={t("ID Number")}
											placeholder={t("ID Number")}
											onChange={handleChange("IDNumber")}
											errors={errors.IDNumber}
										/>
									</div>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer className="border-200">
							<div>
								<Button size="sm" onClick={handleCancelEdit} variant="secondary">
									{t("Cancel")}
								</Button>
								<IconButton size="sm" className="ms-2" variant="primary" onClick={handleSubmit} icon={<FontAwesomeIcon icon="check" />}>
									{t("Confirm")}
								</IconButton>
							</div>
							{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
						</Modal.Footer>
					</>
				)}
			</Formik>
		</Modal>
	);
};

EditProfile.propTypes = {
	userID: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default EditProfile;
