import * as Constants from "common/constants";
import * as actions from "./actions.js"
const encryptStorage1 = new EncryptStorage("secret-key-value", {
    prefix: "@mwan",
});
const initialState = {
    userInfo:encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage) || {},
};
  const storedLoggedInUserInfoReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case actions.UPDATE_USER_INFO:
        return {
          ...state,
          userInfo: action.payload,
        };
  
      default:
        return state;
  
    }
  }
  
  export default storedLoggedInUserInfoReducer;