import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./CompanyContracts.settingsForm";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import moment from "moment";
import { labels } from "data/kanban";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";


/*
This component get all facilities or transporters with additional text fields to fill their info in as per user request
*/

const CompanyContractsCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });
	const [t, i18n] = useTranslation();
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event
			let state = { ...myState }

			//get the associated record from options based on the value selected and set it in form data
			let selectedValues = state["options"].filter((v) => v.value === newValue)[0];

			if (selectedValues) {
				let json = { value: newValue };
				//fill value with the fields requested
				if (props.component.companyGrid) {
					for (let i = 0; i < props.component.companyGrid.length; i++) {
						json[props.component.companyGrid[i].companyFieldPath] = selectedValues[props.component.companyGrid[i].companyFieldPath];
					}
				}
				json["label"] = selectedValues.label;
				state["selectedValues"] = json;
				setMyState(state);
				props.setComponentState(state);
				props.onChange(json, null);
			} else {
				state["selectedValues"] = {};
				setMyState(state);
				props.setComponentState(state);
				props.onChange("", null);
			}
		}
	};

	useEffect(() => {
		let state = { ...myState }
		let initialValue = props.component.defaultValue ? props.component.defaultValue.value ? props.component.defaultValue : props.form._data[props.component.key] : props.form._data[props.component.key];
		//if component has default value and so its additional fields, get their values and set them in state
		if (initialValue) {
			state["selectedValues"] = initialValue;

			let label = state["selectedValues"]?.label;
			let value = state["selectedValues"]?.value;
			i18next.language = i18next.language.includes("-") ? i18next.language.split("-")[0] : i18next.language;
			//label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
			label = props.form.i18next.store.data[i18next.language][label] || [label];
			Object.keys(props.form.i18next.store.data[i18next.language].translation).map((key) => {
				let allLabels = props.form.i18next.store.data[i18next.language].translation;
				Object.keys(allLabels).map((key2) => {
					if (allLabels[key2] === label) {
						label = props.form.i18next.store.data[i18next.language].translation[key2];
					}
				});

			});

			state["selectedValues"].value = props.component.disabled === true ? state["selectedValues"]?.label : value;
		}
		else {
			state["selectedValues"] = {};
		}
		//if component has no default value and it is required get options
		if (props.component.disabled !== true) {
			if (!state.loaded) {
				loadData(state);
			}
			else {
				setMyState(state);
				props.setComponentState(state);
			}
		} else {
			if (!state.loaded) {
				let options = [
					{
						label: state["selectedValues"]?.label,
						value: props.component.disabled === true ? state["selectedValues"]?.label : state["selectedValues"]?.value,
					},
				];
				state["options"] = options;
				state.loaded = true;
				setMyState(state);
				props.setComponentState(state);
			}

		}
	}, [props.form]);

	const loadData = async (state) => {

		let companyID = encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		}

		let fetchParams = { method: "GET", headers: headers };

		fetch(Constants.base_url + props.component.requestUrl + "/" + companyID, { ...fetchParams })
			.then((response) => response.json())
			.then((res) => {
				if (JSON.stringify(res) !== "{}") {
					let path = props.component.dataPath || "";
					let result = path ? _.get(res, path, "") : res;

					// Dynamically split and concatenate paths
					let options = result?.map((e) => {
						let labelPaths = (props.component.labelPath || "").split("+").map(path => _.get(e, path.trim(), ""));
						let valuePaths = (props.component.valuePath || "").split("+").map(path => _.get(e, path.trim(), ""));

						return {
							label: labelPaths.join(" "),  // Concatenating fields for label
							value: valuePaths.join(" "),  // Concatenating fields for value
							...e,
						};
					});
			
					if (props.component.othersData !== "") {
						// Parse the othersData JSON
						const othersData = JSON.parse(props.component.othersData);
					
						// Add each key-value pair as an option
						for (const [label, value] of Object.entries(othersData)) {
							options.push({
								label: t(label), // Key from the JSON
								value: value, // Value from the JSON
							});
						}
					}


					state["options"] = options;
					state.loaded = true;
					setMyState(state);
					props.setComponentState(state);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div>
			<div className="row mx-0 ms-0 companyRelationCustomComp">
				<label style={{ display: props.component.dropdownLabel ? "block" : "none" }}>
					{props.component.translateData && props.form._form.settings ? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] : props.component.dropdownLabel}
				</label>
				<CustomSelect
					component={props.component}
					disabled={props.component.disabled && props.component.disabled === true}
					value={myState["selectedValues"].value}
					options={myState.options}
					updateValue={updateValue}
				/>

				{props.component.companyGrid?.map((grid, index) => {
					return (
						!grid.isHidden && (
							<div key={props.component.key + "_" + index} className="p-0 col-sm-6 mt-1">
								<label>
									{props.component.translateData && props.form._form.settings ? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] : grid.companyFieldName}

								</label>
								<input type="text" className="form-control" value={myState["selectedValues"][grid.companyFieldPath] || ""} disabled={true} />
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export default class CompanyContracts extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			requestUrl: "",
			selectedValues: {},
			loaded: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "companyContractsCustomComp",
			label: "Company Contracts",
		});
	}
	static get builderInfo() {
		return {
			title: "Company Contracts",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: CompanyContracts.schema(),
		};
	}
	static editForm = settingsForm;



	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		const root = createRoot(element);

		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);
			root.render(
				<CompanyContractsCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue || ""}
					form={rootForm}
					data={this.data}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		}

		root.render(
			<CompanyContractsCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue || ""}
				form={rootForm}
				data={this.data}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		const newValue = value === undefined || value === null ? this.getValue() : value;
		const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
		this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

		this.updateOnChange(flags, changed);
		return changed;
	};
}
