import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import CompaniesDropDown from "./CompaniesDropDown";
import { enable } from "workbox-navigation-preload";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";
import Background from "components/common/Background";
import { List } from "reactstrap";
import { replacePlaceholders, sendNotification, sendSms } from "components/workflow/WorkflowHelper";
import { emailTemplates } from "common/SmsTemplates";
import { EncryptStorage } from "encrypt-storage";
import { ToastContainer, toast } from "react-toastify";

const AddUser = ({ showComponent, hideAddUser, successCallBackFunction, companyId, keycloakCompanyId, getCompanyUsers }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [roles, setRoles] = useState([]);
	const [firstName, updateFirstName] = useState("");
	const [errorFirstName, setErrorFirstName] = useState("");
	const [lastName, updateLastName] = useState("");
	const [errorLastName, setErrorLastName] = useState("");
	const [email, updateEmail] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [password, updatePassword] = useState("");
	const [errorPassword, setErrorPassword] = useState("");
	const [passwordConfirm, updatePasswordConirm] = useState("");
	const [errorPasswordConfirm, setErrorPasswordConfirm] = useState("");
	const [enabled, updateEnabled] = useState(true);

	const [mobileNumber, updateMobileNumber] = useState("");
	const [errorMobileNumber, setErrorMobileNumber] = useState("");
	const [iDType, updateIDType] = useState("");
	const [erroriDType, setErroriDType] = useState("");
	const [iDNumber, updateIDNumber] = useState("");
	const [errorIDNumber, setErrorIDNumber] = useState("");
	const [checkedRoles, setCheckedRoles] = useState([]);
	const [errorDropDownClassCompany, seterrorDropDownClassCompany] = useState("");
	const [errorDropDownClassCompanyType, seterrorDropDownClassCompanyType] = useState("");
	const [t, i18n] = useTranslation();

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const handleCancelNew = () => {
		hideAddUser();
	};

	const handleEnabledChange = () => {
		updateEnabled(!enabled);
	};

	const handleCheckboxChange = (event) => {
		const roleName = event.target.value;
		const isChecked = event.target.checked;
		if (isChecked) {
			setCheckedRoles([...checkedRoles, roleName]);
		} else {
			setCheckedRoles(checkedRoles.filter((role) => role !== roleName));
		}
	};

	useEffect(() => {
		fetchRoles();
	}, []);

	const fetchRoles = async () => {
		showLoaderContainer(true);
		var compnayRoles = await General.GetCompanyRoles(companyId);
		compnayRoles = compnayRoles.filter((record) => record.hidden === false);
		setRoles(compnayRoles);

		showLoaderContainer(false);
	};
	function validatePassword(password) {
		const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?[\]\\;',./`~])[A-Za-z\d!@#$%^&*()_+{}|:"<>?[\]\\;',./`~]{8,}$/;

		return regex.test(password);
	}

	const handleAddUser = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var firstNameInput = document.getElementById("txtFirstName");
		var lastNameInput = document.getElementById("txtLastName");
		var emailInput = document.getElementById("txtEmail");

		// var passwordInput = document.getElementById("txtPassword");
		// var passwordConfirmInput = document.getElementById("txtPasswordConfirm");
		var enabledCheckbox = document.getElementById("chkEnabled");

		var mobileNumberInput = document.getElementById("txtMobileNumber");
		var iDTypeDropDown = document.getElementById("ddIDType");
		var iDNumberInput = document.getElementById("txtIDNumber");

		var errorContainerCampany = document.getElementById("errorContainerCampany");

		var isValidForm = true;
		firstNameInput.classList.remove("is-invalid");
		emailInput.classList.remove("is-invalid");

		lastNameInput.classList.remove("is-invalid");
		// passwordInput.classList.remove("is-invalid");
		// passwordConfirmInput.classList.remove("is-invalid");

		mobileNumberInput.classList.remove("is-invalid");
		iDTypeDropDown.classList.remove("is-invalid");
		iDNumberInput.classList.remove("is-invalid");

		setErrorEmail("");
		setErrorMobileNumber("");
		setErrorIDNumber("");
		setErrorFirstName("");
		setErrorLastName("");
		// setErrorPassword("");
		// setErrorPasswordConfirm("");
		setErroriDType("");
		errorContainerCampany.innerHTML = "";

		seterrorDropDownClassCompany("");
		seterrorDropDownClassCompanyType("");

		if (firstName === "") {
			setErrorFirstName(t(em.fistName_Empty));
			firstNameInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (lastName === "") {
			lastNameInput.classList.add("is-invalid");
			setErrorLastName(t(em.lastname_empty));
			isValidForm = false;
		}

		// if (password === "") {
		// 	setErrorPassword(t(em.password_required));
		// 	passwordInput.classList.add("is-invalid");
		// 	isValidForm = false;
		// }

		// if (passwordConfirm === "") {
		// 	setErrorPasswordConfirm(t(em.confirm_password_required));
		// 	passwordConfirmInput.classList.add("is-invalid");
		// 	isValidForm = false;
		// }

		if (email != "" && !General.isValidEmail(email)) {
			setErrorEmail(t(em.invalid_email));
			emailInput.classList.add("is-invalid");
			isValidForm = false;
		}

		// if (password != "" || passwordConfirm != "") {
		// 	if (password != passwordConfirm) {
		// 		passwordInput.classList.add("is-invalid");
		// 		passwordConfirmInput.classList.add("is-invalid");
		// 		setErrorPasswordConfirm(t(em.password_no_match));
		// 		isValidForm = false;
		// 	}
		// }
		// if (!validatePassword(password)) {
		// 	setErrorPassword(t(em.password_format));
		// 	passwordInput.classList.add("is-invalid");
		// 	isValidForm = false;
		// }

		if (mobileNumber === "" || !General.isKSAPhoneNumber(mobileNumber)) {
			setErrorMobileNumber(t(em.format_phone_number));
			mobileNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (iDType === "") {
			setErroriDType(t(em.id_type_unselected));
			iDTypeDropDown.classList.add("is-invalid");
			isValidForm = false;
		}

		if (iDNumber === "" || iDNumber.length != 10 || iDNumber.startsWith(0)) {
			setErrorIDNumber(t(em.id_number));
			iDNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			showLoaderContainer(false);
			showHideButtons(true);
			showErrorContainer(false);
		} else {
			var addUserJSON = {
				enabled: enabled,
				emailVerified: true,
				//password: password,
				temporary: false,
				firstName: firstName,
				lastName: lastName,
				email: email,
				idType: iDType,
				idNumber: iDNumber,
				mobileNumber: mobileNumber,
				roles: checkedRoles,
				userName: iDNumber,
			};

			var addUserJSONString = JSON.stringify(addUserJSON);

			let response = await General.AddUser(companyId, keycloakCompanyId, false, addUserJSONString);
			if (response.status === 200) {
				var loggedInUserInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);

				var userBodyVariable = { USERNAME: firstName + " " + lastName, PASSWORD: response.code };
				var adminBodyVariable = { ADMINNAME: loggedInUserInfo.fullName, NEWUSERNAME: firstName + " " + lastName };
				var adminSmsBody = replacePlaceholders(emailTemplates["adduseradmin"], adminBodyVariable);
				var userSmsBody = replacePlaceholders(emailTemplates["adduser"], userBodyVariable);

				if (loggedInUserInfo.mobileNumber !== null && loggedInUserInfo.mobileNumber !== "") {
					await sendSms(loggedInUserInfo.mobileNumber, adminSmsBody);
				}

				if (loggedInUserInfo.email !== null && loggedInUserInfo.email !== "") {
					await sendNotification("en", loggedInUserInfo.email, "adduseradmin", adminBodyVariable);
				}
				if (mobileNumber !== null && mobileNumber !== "") {
					await sendSms(mobileNumber, userSmsBody);
				}
				if (email !== null && email !== "") {
					await sendNotification("en", email, "adduser", userBodyVariable);
				}
				toast.success(t("Successfully Added"), {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				});
			} else {
				toast.error(t(response.message), {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				});
			}

			showErrorContainer(false);
			showLoaderContainer(false);
			showHideButtons(true);
			hideAddUser();
			getCompanyUsers();
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} autoComplete="off" onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon={faUser} className="text-success" />
						</div>
						<div className="ms-2">{t("New User")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						{/* style={LoggedInUserCompanyID != "" && LoggedInUserCompanyID != "0" && LoggedInUserCompanyID != null ? { display: 'none' } : { display: 'block' }}*/}
						<div>
							<span id="errorContainerCampany" style={{ color: "red" }}></span>
						</div>
						<div className="mb-3">
							{/* <CompaniesDropDown
                                CompanyDropDownCSSClassExtra={errorDropDownClassCompany}
                                CompanyTypeDropDownCSSClassExtra={errorDropDownClassCompanyType}

                                PreselectedGroups={[]}

                                CompanyDropDownChangeCallBackFunction={GetSelectedCompanyIDFromTheProject}
                                CompanyTypeDropDownChangeCallBackFunction={GetSelectedCompanyTypeIDFromTheProject} ></CompaniesDropDown> */}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("First Name")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errorFirstName ? "is-invalid" : ""}`}
								id="txtFirstName"
								value={firstName}
								placeholder={t("First Name")}
								onChange={(event) => {
									updateFirstName(event.target.value);
									setErrorFirstName("");
								}}
							/>
							{errorFirstName && (
								<div id="errorContainerFirstName" className="invalid-feedback">
									{errorFirstName}
								</div>
							)}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("Last Name")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errorLastName ? "is-invalid" : ""}`}
								id="txtLastName"
								value={lastName}
								placeholder={t("Last Name")}
								onChange={(event) => {
									updateLastName(event.target.value);
									setErrorLastName("");
								}}
							/>
							{errorLastName && (
								<div id="errorContainerLastName" className="invalid-feedback">
									{errorLastName}
								</div>
							)}
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Email")}</label>
							<input
								type="text"
								className={`form-control ${errorEmail ? "is-invalid" : ""}`}
								id="txtEmail"
								value={email}
								placeholder={t("Email")}
								autoComplete="off"
								onChange={(event) => {
									updateEmail(event.target.value);
									if (General.isValidEmail(event.target.value)) {
										setErrorEmail("");
									}
								}}
							/>
							{errorEmail && (
								<div id="errorContainerEmail" className="invalid-feedback">
									{errorEmail}
								</div>
							)}
						</div>

						{/* <div className="mb-3">
							<label className="form-label">
								{t("Password")}
								<span style={{ color: "red" }}>*</span>{" "}
							</label>
							<input
								type="password"
								className={`form-control ${errorPassword ? "is-invalid" : ""}`}
								id="txtPassword"
								value={password}
								autoComplete="new-password"
								placeholder={t("Password")}
								onChange={(event) => {
									updatePassword(event.target.value);
									setErrorPassword("");
								}}
							/>
							{errorPassword && (
								<div id="errorContainerPassword" className="invalid-feedback">
									{errorPassword}
								</div>
							)}
						</div>

						<div className="mb-3">
							<label className="form-label">
								{t("Confirm Password")}
								<span style={{ color: "red" }}>*</span>{" "}
							</label>
							<input
								type="password"
								className={`form-control ${errorPasswordConfirm ? "is-invalid" : ""}`}
								id="txtPasswordConfirm"
								value={passwordConfirm}
								placeholder={t("Confirm Password")}
								onChange={(event) => {
									updatePasswordConirm(event.target.value);
									setErrorPasswordConfirm("");
								}}
							/>
							{errorPasswordConfirm && (
								<div id="errorContainerConfirmPassword" className="invalid-feedback">
									{errorPasswordConfirm}
								</div>
							)}
						</div> */}

						<div className="mb-3">
							<label className="form-label">
								{t("Mobile Number")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errorMobileNumber ? "is-invalid" : ""}`}
								id="txtMobileNumber"
								value={mobileNumber}
								placeholder={t("KSA Mobile Number")}
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										updateMobileNumber(input);
										if (General.isKSAPhoneNumber(event.target.value)) {
											setErrorMobileNumber("");
										}
									}
								}}
							/>
							{errorMobileNumber && (
								<div id="errorContainerMobileNumber" className="invalid-feedback">
									{errorMobileNumber}
								</div>
							)}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("ID Type")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<select
								name="productCategory"
								id="ddIDType"
								className={`form-control ${erroriDType ? "is-invalid" : ""}`}
								onChange={(event) => {
									updateIDType(event.target.value);
									setErroriDType("");
								}}
							>
								<option value="">{t("Select ID Type")}</option>
								<option value="National ID">{t("National ID")}</option>
								<option value="Iqama">{t("Iqama")}</option>
							</select>
							{erroriDType && (
								<div id="errorContainerIdType" className="invalid-feedback">
									{erroriDType}
								</div>
							)}
						</div>
						<div className="mb-3">
							<label className="form-label">
								{t("ID Number")}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								className={`form-control ${errorIDNumber ? "is-invalid" : ""}`}
								id="txtIDNumber"
								value={iDNumber}
								placeholder={t("ID Number")}
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										updateIDNumber(input);
										setErrorIDNumber("");
									}
								}}
							/>
							{errorIDNumber && (
								<div id="errorContainerIDNumber" className="invalid-feedback">
									{errorIDNumber}
								</div>
							)}
						</div>
						<div className="mb-3" hidden>
							<div className="form-check">
								<div className="checkBoxItem form-check">
									<input type="checkbox" className="form-check-input" id="chkEnabled" checked={enabled} onChange={handleEnabledChange} />
									<label className="form-check-label" htmlFor="chkEnabled">
										{t("Enable")}
									</label>
								</div>
							</div>
						</div>

						<div className="mb-3">
							<label>{t("Assign Roles")}</label>
							<div className="form-check">
								<div className="row">
									{roles != undefined &&
										roles.length > 0 &&
										roles.map((role) => (
											<div key={role.roleId} className="checkBoxItem form-check col-4">
												<input type="checkbox" className="form-check-input" id={`checkbox-${role.roleName}`} value={role.roleName} checked={role.isChecked} onChange={handleCheckboxChange} />
												<label className="form-check-label" htmlFor={`checkbox-${role.roleName}`}>
													{t(role.roleName)}
												</label>
											</div>
										))}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelNew} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" icon="check" className="ms-2" variant="primary" onClick={handleAddUser}>
							{t("Confirm")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

AddUser.propTypes = {
	showComponent: PropTypes.bool,
};

export default AddUser;
