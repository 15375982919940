import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Row, Col, Button } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import { toast } from "react-toastify";
import LoaderIcon from "components/custom/Preloader";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";

const UpdatePassword = ({ userID, email, showComponent, hideUpdatePassword, successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [t] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const [newPassword, updateNewPassword] = useState("");
	const [newPasswordConfirm, updateNewPasswordConfirm] = useState("");

	// Using refs instead of document.getElementById
	const newPasswordInputRef = useRef(null);
	const newPasswordConfirmInputRef = useRef(null);

	const handleCancelEdit = () => {
		hideUpdatePassword();
		setIsFinish(false);
	};

	// Password validation function
	const validatePassword = (password) => {
		if (password.length < 8) {
			return t("Password must be at least 8 characters long.");
		}
		if (!/[A-Z]/.test(password)) {
			return t("Password must contain at least one uppercase letter.");
		}
		if (!/[a-z]/.test(password)) {
			return t("Password must contain at least one lowercase letter.");
		}
		if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
			return t("Password must contain at least one special character.");
		}
		return null; // No validation errors
	};

	const handleUpdatePassword = async () => {
		const errorContainerPassword = document.getElementById("errortxtNewPassword");
		const errorContainerConfirmPassword = document.getElementById("errortxtNewPasswordConfirm");

		errorContainerConfirmPassword.innerHTML = "&nbsp;";
		errorContainerPassword.innerHTML = "&nbsp;";

		// Clear previous error styles
		newPasswordInputRef.current.classList.remove("is-invalid");
		newPasswordConfirmInputRef.current.classList.remove("is-invalid");

		// Validate new password
		const passwordError = validatePassword(newPassword);
		if (passwordError) {
			errorContainerPassword.innerText = passwordError;
			newPasswordInputRef.current.classList.add("is-invalid");
			return;
		}

		// Check if passwords match
		if (newPassword !== newPasswordConfirm) {
			errorContainerConfirmPassword.innerText = t(em.password_no_match);
			newPasswordConfirmInputRef.current.classList.add("is-invalid");
			newPasswordInputRef.current.classList.add("is-invalid");
			return;
		}

		// Proceed with the update if validation passes
		setIsFinish(true);
		const UpdatePasswordSuccess = (responseObject) => {
			toast.success(t(em.password_Changed_Successfully_with_Logout), {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			});
			setTimeout(() => {
				setIsFinish(false);
				hideUpdatePassword();
				successCallBackFunction();
			}, 3000);
		};

		const UpdatePasswordFailure = (error) => {};

		debugger;
		const UpdatePasswordEndPoint = Constants.UpdatePasswordEndPoint + userID;
		const UpdatePasswordJSON = `{"userCredentials":{"type": "password","value": "${newPassword}","temporary": false}}`;
		General.PostToEndPoint(UpdatePasswordEndPoint, null, UpdatePasswordJSON, UpdatePasswordSuccess, UpdatePasswordFailure);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<>
			{isFinish && <LoaderIcon />}
			<Modal show={showComponent} onHide={handleCancelEdit} size="lg">
				<Modal.Header closeButton className="border-200">
					<Modal.Title as="h5">
						<Flex alignItems="center">
							<div className="icon-item bg-soft-success shadow-none">
								<FontAwesomeIcon icon="user" className="text-success" />
							</div>
							<div className="ms-2">{t(em.Change_Password)}</div>
						</Flex>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="g-0 align-items-center">
						<Col>
							<div className="mb-3">
								<label className="form-label">{t("User ID")}</label>
								<input type="text" className="form-control" value={userID} disabled />
							</div>
							<div className="mb-3">
								<label className="form-label">{t("Email")}</label>
								<input type="text" className="form-control" value={email} disabled />
							</div>
							<div className="mb-3">
								<label className="form-label">{t("New Password")}</label>
								<span id="errortxtNewPassword" className="loginAlert popupAlert inlineError">
									&nbsp;
								</span>
								<input
									type="password"
									className="form-control"
									id="txtNewPassword"
									ref={newPasswordInputRef}
									value={newPassword}
									placeholder={t("New Password")}
									onChange={(event) => updateNewPassword(event.target.value)}
								/>
							</div>
							<div className="mb-3">
								<label className="form-label">{t("Confirm New Password")}</label>
								<span id="errortxtNewPasswordConfirm" className="loginAlert popupAlert inlineError">
									&nbsp;
								</span>
								<input
									type="password"
									className="form-control"
									id="txtNewPasswordConfirm"
									ref={newPasswordConfirmInputRef}
									value={newPasswordConfirm}
									placeholder={t("Confirm New Password")}
									onChange={(event) => updateNewPasswordConfirm(event.target.value)}
								/>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="border-200">
					<div>
						<Button size="sm" onClick={handleCancelEdit} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" icon="check" onClick={handleUpdatePassword}>
							{t("Confirm")}
						</IconButton>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

UpdatePassword.propTypes = {
	userID: PropTypes.string,
	email: PropTypes.string,
	showComponent: PropTypes.bool,
	hideUpdatePassword: PropTypes.func,
	successCallBackFunction: PropTypes.func,
};

export default UpdatePassword;
