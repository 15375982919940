import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const EditGenerator = ({ successCallBackFunction, selectedGenerator, hideEditGenerator, RelatedCompany, showComponent, allData }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	// console.log({ selectedGenerator });

	const [errorMessage, setErrorMessage] = useState("");
	const [generatedWasteType, setGeneratedWasteType] = useState(selectedGenerator.generatedWasteType || "");
	const [wasteType, setWasteType] = useState(selectedGenerator.wasteType || "");
	const [unn, setUNN] = useState(selectedGenerator.unn || "");
	const [crNumber, setCrNumber] = useState(selectedGenerator.crNumber || "");
	const [address, setAddress] = useState(selectedGenerator.address || "");
	const [companyName, setCompanyName] = useState(selectedGenerator.companyName || "");
	const [phoneNumber, setPhoneNumber] = useState(selectedGenerator.phoneNumber || "");
	const [isVerified, setIsVerified] = useState(selectedGenerator.isVerified || false);
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const [isUnique, setIsUnique] = useState(true);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const handleCancelNew = () => {
		hideEditGenerator();
	};
	const isUNNUnique = () => {
		return !allData.some((generator) => generator.unn === unn && generator.id !== selectedGenerator.id);
	};

	const handleEditGenerator = async () => {
		setIsFinish(true);

		//get all elements
		var fieldgeneratedWasteTypeSelect = document.getElementById("generatedWasteTypeSelect");
		var fieldwasteTypeSelect = document.getElementById("wasteTypeSelect");
		var fieldunnNumber = document.getElementById("unn");
		var fieldcrNumber = document.getElementById("crNumber");
		var fieldPhoneNumber = document.getElementById("phoneNumber");
		var errorContainerDate = document.getElementById("errorContainerDate");
		var EditGeneratorJSON = {
			generatedWasteType: generatedWasteType,
			wasteType: wasteType,
			unn: unn,
			crNumber: crNumber,
			address: address,
			companyName: companyName,
			phoneNumber: phoneNumber,
			isVerified: true,
		};
		var isValidForm = true;
		//remove class for invalid
		fieldgeneratedWasteTypeSelect.classList.remove("is-invalid");
		fieldwasteTypeSelect.classList.remove("is-invalid");
		fieldunnNumber.classList.remove("is-invalid");
		fieldcrNumber.classList.remove("is-invalid");
		fieldPhoneNumber.classList.remove("is-invalid");
		//check if fields are filled and set the invalid class in case they are not
		if (generatedWasteType === "") {
			setIsFinish(false);
			fieldgeneratedWasteTypeSelect.classList.add("is-invalid");
			isValidForm = false;
		}
		if (wasteType === "") {
			setIsFinish(false);
			fieldwasteTypeSelect.classList.add("is-invalid");
			isValidForm = false;
		}
		if (crNumber.length > 0) {
			// CR Number validation: must be 10 digits
			if (crNumber.length !== 10 || !/^\d+$/.test(crNumber)) {
				fieldcrNumber.classList.add("is-invalid");
				showErrorContainer(true);
				setErrorMessage(t("CR Number must be 10 digits long."));
				setIsFinish(false);
				isValidForm = false;
			}
		}
		// Phone Number validation: must be 10 digits and start with '05'
		if (phoneNumber.length !== 10 || !phoneNumber.startsWith("05")) {
			fieldPhoneNumber.classList.add("is-invalid");
			showErrorContainer(true);
			setErrorMessage(t("Phone Number must be 10 digits and start with '05'."));
			setIsFinish(false);
			isValidForm = false;
		}
		if (generatedWasteType === "Hazardous Waste") {
			// CR Number validation: must be 10 digits
			if (wasteType !== "Government") {
				if (crNumber.length !== 10 || !/^\d+$/.test(crNumber)) {
					fieldcrNumber.classList.add("is-invalid");
					showErrorContainer(true);
					setErrorMessage(t("CR Number must be 10 digits long."));
					setIsFinish(false);
					isValidForm = false;
				}
			}
		}

		if (isValidForm == false) {
			return;
		} else {
			const EditGeneratorSuccess = (responseObject) => {
				toast.success("Success");
				handleCancelNew();
				successCallBackFunction(true);
				setIsFinish(false);
			};

			const EditGeneratorFailure = (error) => {
				setTimeout(() => {
					setIsFinish(false);
				}, timeOut);

				toast.error("An error occured, please try again later.", {
					autoClose: timeOut,
				});
			};
			var EditGeneratorJSONString = JSON.stringify(EditGeneratorJSON);
			var EditCompanyEndPoint = Constants.EditTransporterGeneratorsData + selectedGenerator.id;
			General.PutToEndPoint(EditCompanyEndPoint, null, EditGeneratorJSONString, EditGeneratorSuccess, EditGeneratorFailure);
		}
	};
	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="industry" className="text-success" />
						</div>
						<div className="ms-2">{t(`Edit Generator`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("Generated Waste Type")}</label>
							<select
								id="generatedWasteTypeSelect"
								value={generatedWasteType}
								className={`form-select ${generatedWasteType === "" ? "is-invalid" : ""}`}
								onChange={(e) => setGeneratedWasteType(e.target.value)}
							>
								<option value="Hazardous Waste">{t("Hazardous Waste")}</option>
								<option value="Non-Hazardous Waste">{t("Non-Hazardous Waste")}</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Company Type")}</label>
							<select value={wasteType} name="wasteTypeSelect" id="wasteTypeSelect" className="form-select" onChange={(e) => setWasteType(e.target.value)} disabled>
								<option value="Individual" data-name="">
									{t(`Individual`)}
								</option>
								<option value="Contractor" data-name="">
									{t(`Contractor`)}
								</option>
								<option value="Engineering company" data-name="">
									{t(`Engineering company`)}
								</option>
								<option value="Delegate" data-name="">
									{t(`Delegate`)}
								</option>
								<option value="Government" data-name="">
									{t(`Government`)}
								</option>
								<option value="Other" data-name="">
									{t(`other`)}
								</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Unified National Number`)}</label>
							<input
								type="text"
								id="unn"
								className={`form-control ${unn.length === 10 ? (isUnique ? "" : "is-invalid") : unn.length > 0 ? "is-invalid" : ""}`} // Apply invalid class based on length and uniqueness
								value={unn}
								disabled
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict length to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setUNN((prevUNN) => {
											return input;
										});

										// Check uniqueness when the length reaches 10 digits
										if (input.length === 10) {
											setIsUnique(!isUNNUnique()); // Pass input to isUNNUnique function
										} else {
											setIsUnique(true); // Reset uniqueness when less than 10 digits
										}
									}
								}}
							/>
							{unn && unn.length !== 10 && <div className="invalid-feedback">Unified National Number must be exactly 10 digits long.</div>}
							{/* {unn.length === 10 && !isUnique && <div className="invalid-feedback">UNN already exists. Please enter a unique UNN.</div>} */}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`CR Number`)}</label>
							<input
								type="text"
								id="crNumber"
								disabled
								className={`form-control ${crNumber.length === 10 ? "" : crNumber.length > 0 ? "is-invalid" : ""}`} // Add invalid class if not 10 digits
								value={crNumber}
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setCrNumber(input);
									}
								}}
							/>
							{crNumber && crNumber.length !== 10 && <div className="invalid-feedback">CR Number must be exactly 10 digits long.</div>}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Address`)}</label>
							<input type="text" id="address" className="form-control" value={address} onChange={(event) => setAddress(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Facility Name`)}</label>
							<input type="text" id="companyName" className="form-control" value={companyName} onChange={(event) => setCompanyName(event.target.value)} disabled />
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Phone Number`)}</label>
							<input
								type="text"
								id="phoneNumber"
								className={`form-control ${phoneNumber.length === 10 && phoneNumber.startsWith("05") ? "" : phoneNumber.length > 0 ? "is-invalid" : ""}`} // Highlight if invalid
								value={phoneNumber}
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										setPhoneNumber(input);
									}
								}}
							/>
							{phoneNumber && (!phoneNumber.startsWith("05") || phoneNumber.length !== 10) && (
								<div className="invalid-feedback">{t("Phone number must start with '05' and be exactly 10 digits long.")}</div>
							)}
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
					<IconButton size="sm" icon="check" className="ms-2" variant="primary" onClick={handleEditGenerator}>
						{t("Confirm")}
					</IconButton>
				</div>

				{/* {errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)} */}
			</Modal.Footer>
		</Modal>
	);
};

EditGenerator.propTypes = {
	showComponent: PropTypes.bool,
};

export default EditGenerator;
