import React from "react";
import PropTypes from "prop-types";
import createMarkup from "helpers/createMarkup";
import Section from "components/common/Section";
import IconGroup from "components/common/icon/IconGroup";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { blogPostList, menuList1, menuList2 } from "data/footer";
import { bgWhiteIcons } from "data/socialIcons";
import { version } from "config";
import logo from "assets/img/logo/mwanLogo-white.png";
import styles from "./landing.module.css";
import { useTranslation } from "react-i18next";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const FooterTitle = ({ children }) => <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>;

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

let urlLink = "";
if (location.host.includes("emanifest")) {
	urlLink = "https://mds.mwan.gov.sa/";
} else if (location.host.includes("dev")) {
	urlLink = "https://databasesystem-api-dev.msisolutions.co.uk/";
} else {
	urlLink = "https://zoibojppqodaknewyqge.supabase.co/";
}
const FooterList = ({ list }) => (
	<ul className="list-unstyled">
		{list.map(({ title, to, isDownload = false }, index) => (
			<li className="mb-1" key={index}>
				{isDownload ? (
					<a className={`text-600 ${styles.footerLink}`} target="_blank" href={to} download>
						{title}
					</a>
				) : (
					<Link className={`text-600 ${styles.footerLink}`} to={to}>
						{title}
					</Link>
				)}
			</li>
		))}
	</ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
	<ul className="list-unstyled">
		{list.map((blog, index) => (
			<li key={index}>
				<h5 className="fs-0 mb-0">
					<Link className="text-600" to="#!">
						{blog.title}
					</Link>
				</h5>
				<p className="text-600 opacity-50">
					{blog.date} &bull; {blog.read} read {blog.star && <span dangerouslySetInnerHTML={createMarkup("&starf;")} />}
				</p>
			</li>
		))}
	</ul>
);
FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const Footer = () => {
	const [t, i18n] = useTranslation();
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};
	const aboutPlatform = [
		{ title: t("Overview"), to: "#!" },
		{ title: t("Our partners"), to: "#!" },
	];
	const guidelines = [
		{
			title: t("Transporter Admin"),
			to: urlLink + "storage/v1/object/public/Manuals/transporter_admin_manual_v1.pdf",
			isDownload: true,
		},
		{
			title: t("Receiving Facility Staff"),
			to: urlLink + "storage/v1/object/public/Manuals/receiving_facility_staff_manual_v1.pdf",
			isDownload: true,
		},
		{ title: t("Driver"), to: urlLink + "storage/v1/object/public/Manuals/driver_manual_v1.pdf", isDownload: true },
	];
	return (
		<>
			<Section className={`pt-6 pb-4 light ${styles.backgroundNavy}`}>
				<Row className="mb-3">
					<Col lg={3} className="mb-3">
						<img src={logo} className={`${styles.logosizeFOOTER}`} />
					</Col>
					<Col lg={3} xs={12} md={3} className="mb-3">
						<FooterTitle>{t("About the platform")}</FooterTitle>
						<FooterList list={aboutPlatform} />
					</Col>
					<Col lg={3} xs={12} md={3} className="mb-3">
						<FooterTitle>{t("User Manual")}</FooterTitle>
						<FooterList list={guidelines} />
					</Col>
					<Col lg={3} xs={12} md={3}>
						<FooterTitle>{t("Follow us")}</FooterTitle>
						<div className="icon-group">
							<a className={``}>
								<FontAwesomeIcon icon={faXTwitter} className={styles.footerIcon} />
							</a>
							<a className={``}>
								<FontAwesomeIcon icon="fa-brands fa-linkedin" className={styles.footerIcon} />
							</a>
							<a className={``}>
								<FontAwesomeIcon icon="fa-brands fa-youtube" className={styles.footerIcon} />
							</a>
						</div>
					</Col>
				</Row>
				<Row className="mt-5 mt-lg-0">
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/privacypolicy"}>
							{t("Privacy policy")}
						</Link>
					</Col>
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/faq"}>
							{t("FAQ")}
						</Link>
					</Col>
					<Col xs={12} ms={12} md={2}>
						<Link className={`text-600 ${styles.footerLink}`} to={"/contact"}>
							{t("Contact us")}
						</Link>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default Footer;
