import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { version } from "config";
import UpliftCss from "../common/upliftcss.module.css";
import FooterBackground from "../../../src/assets/img/footer.png";

const Footer = () => {
	const backgroundStyle = {
		backgroundImage: `url(${FooterBackground})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		color: "#d7d7d7",
		textAlign: "center",
		padding: "3.5em",
		fontSize: "14px",
	};
	return (
		<footer className={`footer ${UpliftCss.footerplacement}`}>
			{/* <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
			<Col sm="auto">
				<p className="mb-0 text-600">
					INSIGHT a platform by Mobile Systems International
					<br className="d-sm-none" /> &copy; {new Date().getFullYear()}
				</p>
			</Col>
		</Row> */}
			<Row style={backgroundStyle} className={`row ${UpliftCss.forcefooterrow}`}>
				<Container>
					<p className="mb-0 text-600">
						INSIGHT a platform by Mobile Systems International
						{/*<span className="d-none d-sm-inline-block">| </span>*/}
						<br className="d-sm-none" /> &copy; {new Date().getFullYear()}
					</p>
				</Container>
			</Row>
		</footer>
	);
};

export default Footer;
