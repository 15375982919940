import React, { Component } from "react";
import { useState } from "react";
import LoginPage from "../LoginPage";
import { validToken } from "../commonFunctions";
import IconButton from "components/common/IconButton";
import CardDropdown from "components/common/CardDropdown";
import { Dropdown, Col, Modal, Row, Button, Image, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import DeleteConfirmation from "components/custom/roles/DeleteConfirmation";
import EditRole from "components/custom/roles/EditRole";
import CreateRole from "components/custom/roles/CreateRole";
//import { useTranslation } from "react-i18next";
import { Translation } from "react-i18next";
import * as em from "common/ErrorMessages";
import { useLocation } from "react-router-dom";

class ManageRoles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			roles: [],
			errorOccuredVisibility: false,
			loaderContainerVisibility: false,
			showDeleteConfirmationPopup: false,
			selectedRoleID: "",
			selectedRoleName: "",
			selectedRoleAttributes: null,
			selectedRoleCompanyAttributes: null,
			showEditPopup: false,
			showCreatePopup: false,
		};
	}

	//ON LOAD
	async componentDidMount() {
		if (General.validateRoleAttribute("Roles")) {
			this.bindRoles();
		} else {
			window.location.href = "/";
		}
	}

	/*************** LOAD Roles START ************************/
	bindRoles = () => {
		this.showErrorOccured(false);
		this.showLoaderContainer(true);

		const GetAllRolesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							this.setState({ roles: responseObject.data.roles });
							isSuccess = true;
							General.SaveToken(responseObject);
						}
					}
				}
			}

			if (isSuccess == false) {
				this.showErrorOccured(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					this.showLoaderContainer(false);
				}, 1000);
			} else {
				this.showErrorOccured(false);
				this.showLoaderContainer(false);
			}
		};

		const GetAllRolesFailure = (GetAllRolesFailure) => {
			this.showErrorOccured(true);
			setTimeout(function () {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later.";
				}
				this.showLoaderContainer(false);
			}, 1000);
		};

		General.GetFromEndPoint(Constants.GetAllRolesEndPoint, { Platform: localStorage.getItem(Constants.platform) }, null, GetAllRolesSuccess, GetAllRolesFailure);
	};

	/*************** LOAD Roles END ************************/

	/*************** COMMON FUNCTIONS START ************************/
	showErrorOccured = (shown) => {
		this.setState({ errorOccuredVisibility: shown });
	};

	showLoaderContainer = (shown) => {
		this.setState({ loaderContainerVisibility: shown });
	};
	/*************** COMMON FUNCTIONS END ************************/

	/*************** DELETE START ************************/
	showDeleteConfirmation = (selectedRole) => {
		this.setState({
			showDeleteConfirmationPopup: true,
			selectedRoleID: selectedRole.id,
			selectedRoleName: selectedRole.name,
		});
	};

	handleCancelDelete = () => {
		this.setState({
			showDeleteConfirmationPopup: false,
			selectedRoleID: "",
			selectedRoleName: "",
		});
	};
	/*************** DELETE END ************************/

	/*************** EDIT START ************************/

	showEditRole = (selectedRole) => {
		this.setState({
			showEditPopup: true,
			selectedRoleID: selectedRole.id,
			selectedRoleName: selectedRole.name,
			selectedRoleAttributes: selectedRole.attributes,
			selectedRoleCompanyAttributes: selectedRole.companyAttributes,
		});
	};

	handleCancelEditRole = () => {
		this.setState({
			showEditPopup: false,
			selectedRoleID: "",
			selectedRoleName: "",
			selectedRoleAttributes: null,
			selectedRoleCompanyAttributes: null,
		});
	};

	/*************** EDIT END ************************/

	/*************** CREATE START ************************/

	showCreateRole = () => {
		this.setState({
			showCreatePopup: true,
		});
	};

	handleCancelCreateRole = () => {
		this.setState({
			showCreatePopup: false,
		});
	};

	/*************** CREATE END ************************/

	render() {
		const {
			roles,
			showCreatePopup,
			errorOccuredVisibility,
			loaderContainerVisibility,
			showDeleteConfirmationPopup,
			selectedRoleID,
			selectedRoleName,
			selectedRoleAttributes,
			selectedRoleCompanyAttributes,
			showEditPopup,
		} = this.state;
		const location = useLocation();
		const menuName = location?.state?.pagename || "Manage Roles";

		return (
			<Translation>
				{(t, { i18n }) => (
					<div>
						<div className="mb-3 card">
							<div className="card-body">
								<div className="col-md">
									<h5 className="mb-2 mb-md-0">{t(menuName)}</h5>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="tool-header">
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="newroles">
											{t(em.new_roles)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position end-text">
										<IconButton
											className="btn-primary botton-circle"
											iconClassName="fs--5"
											size="sm"
											icon="fa-solid fa-circle-plus"
											style={{
												margin: "auto 0.25rem",
												padding: "9px 10px 10px",
											}}
											onClick={this.showCreateRole}
										></IconButton>
									</div>
								</OverlayTrigger>
							</div>
							{/*  
                            <div className="addNewButton">
                                <IconButton
                                    iconClassName="fs--2 me-1"
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    onClick={this.showCreateRole}
                                >
                                    {t(em.new_roles)}
                                    
                                </IconButton>
                            </div>*/}
							<div className="table-responsive">
								<table className="customTable table table-striped" striped responsive>
									<thead>
										<tr>
											<th scope="col">{t("Role Name")}</th>
											<th className="text-end" scope="col">
												{t("Actions")}
											</th>
										</tr>
									</thead>
									<tbody>
										{roles.map((role) => (
											<tr key={role.id}>
												<td>{role.name}</td>
												<td className="text-end">
													<CardDropdown>
														<div className="py-2">
															<Dropdown.Item onClick={() => this.showEditRole(role)}>{t("Edit")}</Dropdown.Item>
															<Dropdown.Item onClick={() => this.showDeleteConfirmation(role)} className="text-danger">
																{t("Delete")}
															</Dropdown.Item>
														</div>
													</CardDropdown>
												</td>
											</tr>
										))}
									</tbody>
								</table>

								{errorOccuredVisibility ? <div id="errorContainer" className="loginAlert"></div> : ""}
								{loaderContainerVisibility ? <Loader></Loader> : ""}
								{showDeleteConfirmationPopup && (
									<DeleteConfirmation
										successCallBackFunction={this.bindRoles}
										hideDeleteConfirmation={this.handleCancelDelete}
										showComponent={showDeleteConfirmationPopup}
										roleID={selectedRoleID}
										roleName={selectedRoleName}
									></DeleteConfirmation>
								)}

								{showEditPopup && (
									<EditRole
										successCallBackFunction={this.bindRoles}
										hideEditRole={this.handleCancelEditRole}
										showComponent={showEditPopup}
										roleID={selectedRoleID}
										roleName={selectedRoleName}
										checkedRoleAttributes={selectedRoleAttributes}
										checkedRoleCompanyAttributes={selectedRoleCompanyAttributes}
									></EditRole>
								)}
								{showCreatePopup && (
									<CreateRole successCallBackFunction={this.bindRoles} hideCreateRole={this.handleCancelCreateRole} showComponent={showCreatePopup} roleName=""></CreateRole>
								)}
							</div>
						</div>
					</div>
				)}
			</Translation>
		);
	}
}

export default ManageRoles;
