import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext from "context/Context";
import React, { useContext } from "react";
import { Button, Col, Row, Carousel, Container } from "react-bootstrap";
import styles from "./landing.module.css";
import { useTranslation } from "react-i18next";
import audit from "assets/img/audit.png";
import crisis from "assets/img/crisis.png";
import time from "assets/img/time.png";
import reports from "assets/img/reports.png";

const LandingButtons = ({ img, text, rowSize }) => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const features = [
		{
			icon: audit,
			title: t("Enhance Control and Compliance"),
			// description: t(
			// 	"Enhancing waste tracking throughout the value chain and increasing the effectiveness of compliance and oversight of waste shipments by the National Center for Waste Management, monitoring and preventing irregular practices such as random dumping."
			// ),
		},
		{
			icon: time,
			title: t("Operational Efficiency"),
			// description: t("Increasing the efficiency of operations by obtaining accurate and live data on waste shipments and eliminating the paper system of the manifest document"),
		},
		{
			icon: reports,
			title: t("Data Sharing and Reporting"),
			// description: t("Establishing a system of reports and performance dashboards and integration with other systems and stakeholders"),
		},
		{
			icon: crisis,
			title: t("Emergency plans and crisis management"),
			// description: t("Quick and immediate notification of any errors related to shipment data and rapid response to any acts that violate the legal requirements"),
		},
	];

	return (
		<Container className={`platform-info ${styles.padding35em}`}>
			<Row>
				{features.map((feature, index) => (
					<Col key={index} md={3} className="text-center">
						<div className={`icon ${styles.iconImages}`}>
							<img src={feature.icon} className={`icon ${styles.iconImagesMax}`} />
						</div>
						<h4 className={`icon ${styles.buttonsTitle} mb-3`}>{feature.title}</h4>
						{/* <p className={`icon ${styles.buttonsSubTitle}`}>{feature.description}</p> */}
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default LandingButtons;
