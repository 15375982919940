import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import AuthCornerImage from "assets/img/illustrations/authentication-corner.png";
import { breakpoints, capitalize } from "helpers/utils";
import { topNavbarBreakpoint } from "config";
import styles from "../../pages/landing/landing.module.css";

const NavbarDropdown = ({ title, classes, children }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);

	return (
		<Dropdown
			show={dropdownOpen}
			onToggle={() => setDropdownOpen(!dropdownOpen)}
			onMouseOver={() => {
				let windowWidth = window.innerWidth;
				if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
					setDropdownOpen(true);
				}
			}}
			onMouseLeave={() => {
				let windowWidth = window.innerWidth;
				if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
					setDropdownOpen(false);
				}
			}}
		>
			<Dropdown.Toggle as={Link} to="#!" className={`nav-link fw-semi-bold ${classes}`}>
				{capitalize(title)}
			</Dropdown.Toggle>
			<Dropdown.Menu className={`dropdown-menu-card mt-0 dropdown-caret ${isRTL ? styles.dropodownStylesAr : styles.dropodownStyles}`}>{children}</Dropdown.Menu>
		</Dropdown>
	);
};

NavbarDropdown.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default NavbarDropdown;
