import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const AssignRoles = ({
	userID,
	userRoles,
	firstName,
	lastName,
	email,
	getCompanyUsers,
	companyId,
	checkedRoles: initialCheckedRoles = [],
	showComponent,
	hideAssignRoles,
	successCallBackFunction,
}) => {
	const [roles, setRoles] = useState();
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [t, i18n] = useTranslation();
	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		setRoles((prevRoles) => {
			return prevRoles.map((role) => (role.roleName === value ? { ...role, isChecked: checked } : role));
		});
	};

	const handleCancelAssignRoles = () => {
		hideAssignRoles();
	};

	const handleAssignRoles = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var deleteBody = [];
		var insertBody = [];

		for (let i = 0; i < roles.length; i++) {
			deleteBody.push({
				userId: userID,
				companyId: companyId,
				roleId: roles[i].roleName,
			});
			if (roles[i].isChecked) {
				insertBody.push({
					userId: userID,
					companyId: companyId,
					roleId: roles[i].roleName,
				});
			}
		}
		await General.DeleteRoles(deleteBody);

		var jsonToPost = {
			Language: "en",
			DatabasePostData: {
				fields: insertBody,
				isCreate: false,
				tableName: "tbl_roles_users",
				files: null,
			},
		};

		await General.PostToDb(jsonToPost);

		showLoaderContainer(false);
		showHideButtons(true);
		getCompanyUsers();
		hideAssignRoles();
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	useEffect(() => {
		showLoaderContainer(true);
		const fetchRoles = async () => {
			var compnayRoles = await General.GetCompanyRoles(companyId);
			compnayRoles = compnayRoles.filter((record) => record.hidden === false);
			for (var i = 0; i < compnayRoles.length; i++) {
				// Find the corresponding userRole with the same roleName
				var matchingUserRole = userRoles.find(function (userRole) {
					return userRole.roleName === compnayRoles[i].roleName;
				});

				// If a matching userRole is found, set isChecked to true
				if (matchingUserRole) {
					compnayRoles[i].isChecked = true;
				} else {
					compnayRoles[i].isChecked = false;
				}
			}

			setRoles(compnayRoles);

			showLoaderContainer(false);
		};

		fetchRoles();
	}, []);

	return (
		<Modal show={showComponent} onHide={handleCancelAssignRoles} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Assign Roles")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t("Full Name")}:{" "}
							<strong>
								{firstName} {lastName}
							</strong>
							<br />
							{t("Email")}: <strong>{email}</strong>
						</div>
					</Col>
				</Row>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<div className="form-check">
								{roles != undefined &&
									roles.length > 0 &&
									roles.map((role) => (
										<div key={role.roleId} className="checkBoxItem form-check">
											<input type="checkbox" className="form-check-input" id={`checkbox-${role.roleName}`} value={role.roleName} checked={role.isChecked} onChange={handleCheckboxChange} />
											<label className="form-check-label" htmlFor={`checkbox-${role.roleName}`}>
												{t(role.roleName)}
											</label>
										</div>
									))}
							</div>
						</div>
					</Col>
					{loaderContainerVisibility && <Loader></Loader>}
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelAssignRoles} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" onClick={handleAssignRoles}>
							{t("Confirm")}
						</IconButton>
					</div>
				)}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

AssignRoles.propTypes = {
	userID: PropTypes.string,
	name: PropTypes.string,
	email: PropTypes.string,
	companyID: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default AssignRoles;
