
import { createStore, combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import routeReducer from './routeReducer';
import flagReducer from './flagReducer';
import flagUnassignedReducer from './flagUnassignedReducer'
import storedLoggedInUserInfoReducer from './storedLoggedInUserInfoReducer'


const rootReducer = combineReducers({
  session: sessionReducer,
  route:routeReducer,
  counter:flagReducer,
  counterUnassigned:flagUnassignedReducer,
  storedLoggedInUserInfo:storedLoggedInUserInfoReducer
  // Add other reducers if needed
});

const store = createStore(rootReducer);

export default store;
