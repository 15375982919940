import React from "react";
import PropTypes from "prop-types";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import createMarkup from "helpers/createMarkup";
import styles from "../../landing/landing.module.css";

const FaqAccordionItem = ({ id, title, description, isFirst, isLast }) => {
	return (
		<div className={`${styles.accordionborder} ${styles.accordionStyle} ${isLast ? styles.accordionborderlast : ""}`}>
			<Accordion.Item eventKey={id} className={`border-0 ${styles.accordionborder}`}>
				<div className="p-0" id={`faqAccordionHeading${id}`}>
					<Accordion.Button className={`btn d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none ${styles.accordiontitle}`}>
						<div className="d-flex">
							<FontAwesomeIcon icon="fa-plus" className={`accordion-icon me-3 mt-1 ${styles.accordionIcon} ${styles.accordionIcon}`} transform="shrink-2" />
							<span className={`font-sans-serif white-force ${styles.accordionStyle}  ${styles.textBold}`}>{title}</span>
						</div>
					</Accordion.Button>
				</div>
				<Accordion.Body className="p-0">
					<div className="pt-2">
						<div className={`${styles.paddingem3}  ${styles.accordiontext}`} dangerouslySetInnerHTML={createMarkup(description)} />
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</div>
	);
};

FaqAccordionItem.propTypes = {
	isFirst: PropTypes.bool,
	isLast: PropTypes.bool,
	faq: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	}),
};

export default FaqAccordionItem;
