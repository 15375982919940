import React, { useState, useContext } from "react";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";
import createMarkup from "helpers/createMarkup";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Privacypolicy = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100} `}>
			<NavbarStandard />
			<Container className={`${styles.padding35em} ${styles.addPageHeight} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle} mb-4`}>{t("Privacy policy")}</h2>

				<div className={`${styles.colorBlack} `} dangerouslySetInnerHTML={createMarkup(t("Privacy_policy_text"))} />
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Privacypolicy;
