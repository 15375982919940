/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";

const AdvanceTableSearchBox = ({ 
  globalFilter, 
  setGlobalFilter, 
  placeholder = "Search...", 
  className,
  onSearch,
  useServerSearch = false // Flag to determine search behavior
}) => {
  const [value, setValue] = useState("");

  // Original search behavior for client-side filtering
  const onChange = useAsyncDebounce((value) => {
    if (!useServerSearch) {
      setGlobalFilter(value || undefined);
    }
  }, 200);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (useServerSearch && onSearch) {
      onSearch(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && useServerSearch && onSearch) {
      e.preventDefault();
      onSearch(value);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (!useServerSearch) {
      onChange(newValue);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup className={classNames(className, "position-relative")}>
        <FormControl
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          size="md"
          id="search"
          placeholder={placeholder}
          type="search"
          className="shadow-none"
        />
        <Button 
          size="sm" 
          variant="outline" 
          className="border-300 hover-border"
          onClick={handleSubmit}
          type="submit"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </InputGroup>
    </form>
  );
};

export default AdvanceTableSearchBox;