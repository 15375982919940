import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
	return baseEditForm(
		[
			{
				label: "Display",
				key: "display",
				weight: 0,
				components: [],
			},
			{
				label: "Data",
				key: "data",
				weight: 20,
				components: [
					{
						type: "number",
						label: "Number of Images",
						key: "fieldNumberOfImages",
						input: true,
						inline: true,
						defaultValue:"1",
						validate: {
							custom: 'valid = (input > 0) ? true : "Value must be greater than zero";',
							required:true
						},
					},
					{
						type: "select",
						label: "Storage",
						key: "typestorage",
						input: true,
						inline: true,
						data: {
							values: [
								{
									label: "Url",
									value: "url"
								},
							]
						},
						 defaultValue: "url"
					},
					{
						type: "textfield",
						label: "Folder",
						key: "folderField",
						input: true,
						inline: true,
						conditional: {
							json: {
								"===": [
									{ var: "data.typestorage" },
									"url"
								]
							}
						},
						validate: {
							required: {
							  if: [
								{ var: "data.typestorage" },
								"url"
							  ]
							}
						  }
					},			
					{
						type: "checkbox",
						label: "Add the Thumbnail option",
						key: "isSetThumbnail",
						input: true,
						inline: true,
					//	defaultValue: true
					},
					{
						type: "number",
						label: "Resolution Value an (%)",
						key: "fieldResolution",
						input: true,
						inline: true,
						defaultValue: 10,
						validate: {
							custom: 'valid = (input > 0) ? true : "Value must be greater than zero";',
							required:{if:[{ var: "data.isSetThumbnail" }, true]}
						},
						conditional: {
							json: {
								"===": [
									{ var: "data.isSetThumbnail" }, true
								]
							}
						}
					},
					{
						type: "checkbox",
						label: "Hide Original Photo",
						key: "isHideOriginal",
						input: true,
						inline: true,
					//	defaultValue: true,
						conditional: {
							json: {
								"===": [
									{ var: "data.isSetThumbnail" }, true
								]
							}
						}
					},
					
				],
			},
			{
				label: "Validation",
				key: "validation",
				weight: 20,
				components: [],
			},
			{
				label: "API",
				key: "api",
				weight: 30,
				components: [],
			},
			{
				label: "Logic",
				key: "logic",
				weight: 50,
				components: [],
			},
			{
				label: "Layout",
				key: "layout",
				weight: 60,
				components: [],
			},
		],
		...extend
	);
};
