import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import * as General from "common/general";
import { Dropdown } from "react-bootstrap";
import team3 from "assets/img/team/avatar.png";
import Avatar from "components/common/Avatar";
import * as Constants from "common/constants";
import AppContext from "context/Context";
import { useTranslation } from "react-i18next";
import EditProfile from "components/custom/users/EditProfile";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
import keycloakService from "keycloakService";
import UpdatePassword from "components/custom/users/UpdatePassword";
import * as em from "common/ErrorMessages";
import UpliftCss from "../../common/upliftcss.module.css";
import store from "reducers/store";
import { useSelector } from 'react-redux';
function ProfileDropdown() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [showModal, setShowModal] = useState(false);
	const [showModalChangePassword, setShowModalChangePassword] = useState(false);
	//const { i18n } = useTranslation();
	const [t, i18n] = useTranslation();
	const [fullName, setFullName] = useState("");
	const [userID, setUserID] = useState("");
	const [fName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [enabled, setEnabled] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [idNumber, setIDNumber] = useState("");
	const [idType, setIDType] = useState("");
	const [selectedGroups, setSelectedGroups] = useState("");
	const [onRefresh, setOnRefresh] = useState(null);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const { setConfig } = useContext(AppContext);

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	const storedLoggedInUserInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
	const decodedAccessToken = jwtDecode(accessToken);
	const userFullName = storedLoggedInUserInfo.fullName;
	const userInfo = useSelector((state) => state.storedLoggedInUserInfo.userInfo);

	const logout = () => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.active.postMessage({ action: "deleteAllCache" });
			});
		}
		keycloakService.logout();

		// keycloak.logout();

		// window.location.href = "/";
	};

	const changeLanguage = () => {
		setIsRTL((prevIsRTL) => {
			const newIsRTL = !prevIsRTL;
			localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
			setConfig("isRTL", newIsRTL);

			if (newIsRTL) {
				i18n.changeLanguage("ar");
				// keycloakService.changeLanguage("ar");
			} else {
				i18n.changeLanguage("en");
				// keycloakService.changeLanguage("en");
			}

			return newIsRTL;
		});

		window.location.reload();
	};
	const handlerefresh = (value) => {
		setOnRefresh(value);
	};

	const fetchData = () => {
		//const userInfo = useSelector((state) => state.user.userInfo);
		try {
			setUserID(userInfo.userId);
			setFirstName(userInfo.firstName || "");
			setLastName(userInfo.lastName || "");
			setEmail(userInfo.email?.toLowerCase() || "");
			setEnabled(userInfo.isEnabled || "");
			setMobileNumber(userInfo.mobileNumber || "");
			setIDNumber(userInfo.idNumber || "");
			setIDType(userInfo.idType || "");
			// setSelectedGroups(responseObject.data.user.groups || "");
			setFullName(userInfo.fullName);
			//   setOnRefresh(null)
		} catch (error) {
			// Handle error
			console.error("Error fetching user data:", error);
		}
	};

	useEffect(() => {
		let isMounted = true;
		setFullName(userFullName);

		// if (storedLoggedInUserInfo.fullName) {
		//   setFullName("storedLoggedInUserInfo.fullName");
		// }
		fetchData();
	}, [userInfo, showModal, showModalChangePassword]);

	return (
		<>
			<Dropdown navbar={true} as="li">
				<Dropdown.Toggle
					as={"button"}
					bsPrefix="toggle"
					id="nav-link-toggle-button"
					// as={Link}
					style={{ backgroundColor: "transparent", border: "none" }}
					className="pe-0 ps-2 pt-1"
				>
					<Avatar icon="user" className={UpliftCss.menuuser} />
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
					<div className="bg-white rounded-2 py-2 dark__bg-1000">
						<label className="userLabel">{userFullName}</label>
						<Dropdown.Divider />
						<Dropdown.Item onClick={changeLanguage}>{isRTL ? "English" : "عربي"}</Dropdown.Item>
						<Dropdown.Item onClick={() => setShowModal(true)}>{t("Edit Profile")}</Dropdown.Item>
						<Dropdown.Item onClick={() => setShowModalChangePassword(true)}>{t(em.Change_Password)}</Dropdown.Item>
						<Dropdown.Item onClick={logout}>{t("Logout")}</Dropdown.Item>
					</div>
				</Dropdown.Menu>
			</Dropdown>

			<EditProfile
				userID={userID}
				firstName={fName}
				lastName={lastName}
				email={email}
				enabled={enabled}
				mobileNumber={mobileNumber}
				IDNumber={idNumber}
				IDType={idType}
				SelectedGroups={selectedGroups}
				showComponent={showModal}
				hideEditUser={() => setShowModal(false)}
				successCallBackFunction={handlerefresh}
				dropdownVisible
			/>
			<UpdatePassword
				userID={userID}
				//	firstName={fName}
				//	lastName={lastName}
				showComponent={showModalChangePassword}
				email={email}
				hideUpdatePassword={() => setShowModalChangePassword(false)}
				successCallBackFunction={logout}
			/>
		</>
	);
}

export default ProfileDropdown;
