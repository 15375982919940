import React, { useContext } from "react";
import Slider from "react-slick";
import AppContext from "context/Context";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./landing.module.css";
import styles from "./landing.module.css";

const Carousel = ({ children, dots = false, infinite = true, slidesToShowLarge = 3, slidesToShowMid = 2, slidesToShowS = 2 }) => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const settings = {
		dots: dots,
		infinite: infinite,
		speed: 500,
		slidesToShow: slidesToShowLarge, // Show 3 items at a time
		slidesToScroll: slidesToShowLarge,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024, // If screen width is less than 1024px, show 2 items
				settings: {
					slidesToShow: slidesToShowMid,
					slidesToScroll: slidesToShowMid,
					infinite: infinite,
					dots: dots,
				},
			},
			{
				breakpoint: 600, // If screen width is less than 600px, show 1 item
				settings: {
					slidesToShow: slidesToShowS,
					infinite: infinite,
					slidesToScroll: slidesToShowS,
					arrows: false,
				},
			},
		],
	};
	return (
		<Slider {...settings} dotsClass={`slick-dots custom-dots ${styles.dotsLanding}  `} className={`${styles.sliderLanding}`}>
			{children}
		</Slider>
	);
};

export default Carousel;
