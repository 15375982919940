import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React from "react";
import { Link } from "react-router-dom";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Event = ({ title, isLast }) => {
	const [t, i18n] = useTranslation();
	return (
		<Flex>
			<FontAwesomeIcon
				icon={faFilePdf}
				style={{
					border: "0.125rem solid rgb(230 55 87 / 30%)",
					padding: "0.75rem 0",
					fontSize: "1.5rem",
					color: "#d9534f",
					marginRight: "8px",
					width: "3.125rem",
					borderRadius: "0.375rem",
					marginBottom: "0.175rem",
				}}
			/>
			<div className="flex-1 position-relative ps-3">
				<h6 className="fs-0 mb-0">
					<Link to="/events/event-detail">
						<span className="me-1">{title}</span>
					</Link>
				</h6>
				{!isLast && <div className="border-dashed border-bottom my-3"></div>}
			</div>
		</Flex>
	);
};

export default Event;
