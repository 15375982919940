import React, { useEffect, useState } from "react";
//import { startProcess } from "components/workflow/VerifyEmailWorkflow";
import { useNavigate } from "react-router-dom";
import Table from "components/common/Table";
import { Row, Col } from "react-bootstrap";
import { parse } from "date-fns";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import {
	continueProcess,
	formatDate,
	getAllDatabaseRecords,
	getDatabaseRecord,
	getFormById,
	getTaskVariables,
	getWorkflowListByAssignee,
	getWorkflowListHistoryByAssignee,
	getWorkflowsHistoryByProcessInstance,
	getWorkflowsSubprocess,
	parseDateAndTime,
	printPDF,
	getFormByUserTask,
} from "./WorkflowHelper";
import {
	AccessTokenKeyInLocalStorage,
	GetFormByIdEndPoint,
	LoggedInEmailOrUserNameKeyInLocalStorage,
	RefreshTokenKeyInLocalStorage,
	LoggedInRolesAttributesKeyInLocalStorage,
	DeleteProcessInstance,
} from "common/constants";
import { GetFromEndPoint, GetServices, GetRolebyUserTask, GetUserIdByEmail, SetAssigneeForTask } from "common/general";
import { get } from "lodash";
import jQuery from "jquery";
import moment, { duration } from "moment-timezone";
import FormioExport from "formio-export";
import { useTranslation } from "react-i18next";
import { Formio, Utils } from "formiojs";
import Formioform from "components/forms/FormioForm";
import LoaderIcon from "components/custom/Preloader";
import Loader from "components/custom/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as General from "common/general";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
import * as Constants from "common/constants";
import UpliftCss from "../common/upliftcss.module.css";

function GetWorkflowListHistoryByAssignee() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const navigate = useNavigate();

	const [workflows, setWorkflows] = useState([]);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const [tableData, setTableData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [form, setForm] = useState({});
	const [workflowsBody, setWorkflowsBody] = useState([]);
	const [formReady, setFormReady] = useState(false);
	const [printingPDF, setPrintingPDF] = useState(false);
	const [t, i18n] = useTranslation();
	const [isCurrentUserEmail, setCurrentUserEmail] = useState("");
	const [task_id, settask_id] = useState("");
	const [processInstance_Id, setProcessInstance_Id] = useState("");
	const [table_name, settable_name] = useState("");
	const [modal, setModal] = useState(false);
	const [modalDriver, setModalDriver] = useState(false);
	const [task_name, settask_name] = useState("");
	const [fetchFlag, setFetchFlag] = useState(false);
	const [roles, setRoles] = useState([]);
	const toggle = () => setModal(!modalDriver);
	const toggleDriver = () => setModalDriver(!modalDriver);
	const [driverCompany, setDriverCompany] = useState([]);
	const [driverEmail, setDriverEmail] = useState("");
	const [oldDriverEmail, setOldDriverEmail] = useState("");
	const [isFinish, setIsFinish] = useState(false);
	const [errorNoChange, setErrorNoChange] = useState(false);
	//const [enableModifyStatus,setEnableModifyStatus]=useState("false");
	const parseDate = (dateString) => parse(dateString, "dd-MM-yyyy HH:mm:ss", new Date());

	const columns = [
		{
			Header: t("Process ID"),
			accessor: "generatedEmanifestID",
		},
		{
			Header: t("Workflow Name"),
			accessor: "workflowName",
		},
		{
			Header: t("Task Name"),
			accessor: "taskName",
		},
		{
			Header: t("Task Assignee"),
			accessor: "userTaskAssignee",
		},
		{
			Header: t("Creation Date"),
			type: "date",
			accessor: "creationDate",
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.creationDate);
				const dateB = parseDate(rowB.values.creationDate);
				return dateA - dateB;
			},
		},
		{ Header: t("Creation By"), accessor: "startUserId" },
		{
			Header: t("Modification Date"),
			accessor: "modificationDate",
			type: "date",
			sortable: true,
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.modificationDate);
				const dateB = parseDate(rowB.values.modificationDate);
				return dateA - dateB;
			},
		},
		{ Header: t("Status"), accessor: "status" },
		{ Header: t("Action"), accessor: "action" },
	];

	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();

	const handleStartDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setStartDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setStartDateFilter("");
		}
	};
	const handleEndDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setEndDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setEndDateFilter("");
		}
	};
	const filterdata = (data) => {
		let filter = [];
		data.map((workflow) => {
			const searchgeneratedEmanifestID = t(workflow.generatedEmanifestID);
			const searchworkflowName = t(workflow.workflowName);
			const searchuserTaskAssignee = t(workflow.userTaskAssignee);
			const searchtaskName = t(workflow.taskName);
			if (startDateFilter && endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isBetween(moment(startDateFilter, "DD-MM-YYYY"), moment(endDateFilter, "DD-MM-YYYY"), null, "[]")) {
					filter.push(workflow);
				}
			} else if (startDateFilter && !endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isSameOrAfter(moment(startDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else if (!startDateFilter && endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isSameOrBefore(moment(endDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else {
				filter.push(workflow);
			}
		});

		return filter;
	};
	useEffect(() => {
		let NewData = filterdata(originalData);
		setTableData(NewData);
		setWorkflows(NewData);
	}, [startDateFilter, endDateFilter]);

	useEffect(() => {
		// setStartDateFilter(
		//   moment().subtract(30, "days").format("DD-MM-YYYY HH:mm:ss")
		// );
		// setEndDateFilter(moment().format("DD-MM-YYYY HH:mm:ss"));
	}, []);

	useEffect(() => {
		var actionEditDriver = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);

		var actionListUser = [];
		var enableModifyStatus = false;

		Object.keys(actionEditDriver.userCompanyDictionary).forEach((key) => {
			if (selectedCompany === key) {
				actionEditDriver.userCompanyDictionary[key].forEach((item) => {
					for (let j = 0; j < item.permissionResponseList.length; j++) {
						for (let k = 0; k < item.permissionResponseList[j].actionsResponseDTO.length; k++) {
							actionListUser.push(item.permissionResponseList[j].actionsResponseDTO[k].actionId);
						}
					}
				});
			}
		});
		if (actionListUser.includes("editDriver")) {
			enableModifyStatus = true;
		}

		const fetchData = async () => {
			setIsFinish(true);
			try {
				// Retrieve user information and selected company from encrypted storage
				const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
				const loggedInUser = userInfo?.userId || "";
				setCurrentUserEmail(loggedInUser);

				const selectedCompany = encodeURIComponent(encryptStorage1.getItem(Constants.SelectedCompany));
				const platform = localStorage.getItem(Constants.platform);

				// Fetch workflow list history by assignee
				const workflowList = await getWorkflowListHistoryByAssignee(loggedInUser, platform, selectedCompany, enableModifyStatus);
				const sortedWorkflows = workflowList?.finalGetWorkflowsHistoryByAssigneeDTO || [];
				console.log("ss", sortedWorkflows);
				// Transform the workflow data
				const pendingTasks = sortedWorkflows.flatMap((task) => {
					try {
						// Parse task if it's a string
						const parsedTask = typeof task === "string" ? JSON.parse(task) : task;

						// Populate parsedTask with additional properties
						return {
							...parsedTask,
							generatedEmanifestID: parsedTask.generatedEmanifestID ? t(parsedTask.generatedEmanifestID) : "",
							workflowName: parsedTask.workflowName ? t(parsedTask.workflowName) : "",
							taskName: parsedTask.taskName ? t(parsedTask.taskName) : "",
							userTaskAssignee: parsedTask.userTaskAssignee ? t(parsedTask.userTaskAssignee) : "",
							startUserId: parsedTask.startUserId ? t(parsedTask.startUserId) : "",
							status: parsedTask.status ? t(parsedTask.status) : "",
							index: task.id,
							action: (
								<>
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: "fixed" }} id="ViewTooltip">
												{t("View")}
											</Tooltip>
										}
									>
										<div className="theme-control-toggle-label icon-position end-text">
											<IconButton
												className={`btn btn-primary  ${UpliftCss.forceButtonColorwhite}`}
												iconClassName="fs--2"
												variant=""
												size="sm"
												icon="fa-solid fa-eye"
												style={{ margin: "auto 0.25rem" }}
												onClick={() => handleView(parsedTask.rootProcessInstanceId, parsedTask.applicationID, parsedTask.tableId, parsedTask.formKey)}
											/>
										</div>
									</OverlayTrigger>

									{parsedTask.status === "COMPLETED" && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
													{t("Download PDF")}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position end-text">
												<IconButton
													className="btn btn-primary"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-download"
													style={{ margin: "auto 0.25rem" }}
													onClick={() => downloadPdf(parsedTask.rootProcessInstanceId, parsedTask.applicationID, parsedTask.workflowName, parsedTask.tableId)}
												/>
											</div>
										</OverlayTrigger>
									)}

									{parsedTask.ableToDelete !== "false" && parsedTask.startUserId === loggedInUser && parsedTask.status !== "COMPLETED" && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="abletodelete">
													{t(em.delete_icon)}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position">
												<IconButton
													className="btn btn-danger"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-trash"
													style={{ margin: "auto 0.25rem" }}
													onClick={() => openModalToDelete(parsedTask.rootProcessInstanceId, parsedTask.taskName, parsedTask.applicationID)}
												/>
											</div>
										</OverlayTrigger>
									)}

									{parsedTask.status !== "COMPLETED" && parsedTask.enableModify && parsedTask.acceptEdit && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="editdriver">
													{t("edit")}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position">
												<IconButton
													className="btn btn-danger"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-user-pen"
													style={{ margin: "auto 0.25rem" }}
													onClick={() => openModalToChangeDriver(parsedTask.rootProcessInstanceId, parsedTask.taskName, parsedTask.applicationID, parsedTask.userTaskAssignee)}
												/>
											</div>
										</OverlayTrigger>
									)}
								</>
							),
						};
					} catch (e) {
						console.error("Error parsing task:", task, e);
						return []; // Skip this task if it can't be parsed
					}
				});

				// Update state with the transformed tasks
				if (!originalData || originalData.length === 0) {
					setOriginalData(pendingTasks);
				}
				setTableData(pendingTasks);
				setWorkflows(pendingTasks);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsFinish(false);
			}
		};

		fetchData();
	}, [fetchFlag]);

	const openModalToDelete = (processInstanceId, name, applicationID) => {
		//id,tablename

		setModal(!modal);
		settask_name(name);
		setProcessInstance_Id(processInstanceId);
		settable_name(applicationID);
	};
	const handleDelete = async () => {
		setModal(!modal);
		const response = await fetch(DeleteProcessInstance + processInstance_Id, {
			method: "DELETE",
		})
			//  if (response.ok) {
			//   const data =await response.json();
			.then((response) => response.json()) //response.json())
			.then((data) => {
				if (data.status === 1) {
					const DeleteRecordSuccess = (responseObject) => {
						if (responseObject.status == Constants.SuccessStatus) {
							toast.success(t(em.deleted_successfully));
							setFetchFlag(true);
						}
					};
					const DeleteRecordFailure = (error) => {};

					var deletereord = Constants.DeleteDBRecordEndPoint + table_name + "/" + processInstance_Id;
					General.DeleteFromEndPoint(deletereord, null, null, DeleteRecordSuccess, DeleteRecordFailure);
				} else {
					toast.error(t(em.failed_delete_workflow));
				}
			})
			.catch((err) => {
				console.error(err.message);
			});
	};

	const openModalToChangeDriver = (processInstanceId, name, applicationID, userTaskAssignee) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			const driverList = [];

			for (let i = 0; i < responseObject.length; i++) {
				if (responseObject[i].rolesResponseList != "") {
					driverList.push(responseObject[i]);
				}
			}

			setDriverCompany(driverList);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		var selectComapany = encryptStorage1.getItem(Constants.SelectedCompany);
		var endPoint = Constants.GetCompanyUsersData + "&&tbl_users.tbl_roles_users.roleId=eq.TransporterDriver&&companyId=eq." + selectComapany;
		GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);

		setModalDriver(!modalDriver);
		settask_name(name);
		setProcessInstance_Id(processInstanceId);
		settable_name(applicationID);
		setOldDriverEmail(userTaskAssignee);
	};

	const handleDropDownChange = async () => {
		const resTaskId = await getFormByUserTask(processInstance_Id);

		settask_id(resTaskId.getFormByTaskResponse.taskId);
		setDriverEmail(document.getElementById("id_driverCompany").value);
	};

	const handletochangedriver = async () => {
		setIsFinish(true);

		if (task_id != "" && driverEmail != "") {
			if (oldDriverEmail === driverEmail) {
				setErrorNoChange(true);
				setIsFinish(false);
			} else {
				setErrorNoChange(false);

				var res = await SetAssigneeForTask(task_id, driverEmail);

				if (res) {
					setIsFinish(false);
					setModalDriver(!modalDriver);
					setFetchFlag(true);
				}
			}
		} else {
			setIsFinish(false);
		}
	};

	const handleView = async (processInstanceId, applicationID, tableId, formKey) => {
		// let record = await getDatabaseRecord(applicationID, processInstanceId);

		// if (record === null || record === undefined || record === "") {
		//   return;
		// }
		setIsFinish(true);
		let variables = await getTaskVariables(processInstanceId);
		var result = {};
		if (variables.find((variable) => variable.name === "recordInTableId") !== undefined) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		} else {
			result = variables.reduce((result, item) => {
				result[item.name] = item.value;
				return result;
			}, {});
		}
		if (jQuery.isEmptyObject(result)) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		}
		console.log("resullt", result);
		//changed this
		//let stepPlusOne = parseInt(result.step, 10) - 1;

		// let stepPlusOne = parseInt(result.step, 10) - 1 === 0 ? 1 : parseInt(result.step, 10) - 1;
		// if (stepPlusOne.toString() === "NaN") {
		// 	stepPlusOne = null;
		// }
		var form = null;
		console.log("fom1:", formKey);
		if (result.formKey) {
			form = await getFormById(result.formKey, null, result, true, 1);
		} else if (formKey !== null) {
			form = await getFormById(formKey, null, result, true, 1);
		} else {
			form = { status: 0 };
			setIsFinish(false);
		}
		if (form.status === 0) {
			return;
		}
		const formData = {
			...JSON.parse(form.data.formData),
		};

		setIsFinish(false);
		navigate("/workflow-history-view", { state: { data: formData } });
	};

	function isValidJSON(str) {
		try {
			JSON.parse(str);
			return true;
		} catch (error) {
			return false;
		}
	}
	function parseValidJSONValues(obj) {
		const parsedObj = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (isValidJSON(value)) {
					parsedObj[key] = JSON.parse(value);
				} else {
					parsedObj[key] = value;
				}
			}
		}
		return parsedObj;
	}

	const downloadPdf = async (processInstanceId, applicationID, workflowName, tableId) => {
		let variables = await getTaskVariables(processInstanceId);
		var result = variables.reduce((result, item) => {
			result[item.name] = item.value;
			return result;
		}, {});
		if (jQuery.isEmptyObject(result)) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		}

		if (result.renderId) {
			GetRender(result.renderId);
		}
	};

	const GetRender = (renderId) => {
		var downloadWindow = window.open(renderId);

		// Close the tab after 3 seconds (adjust the timeout as needed)
		setTimeout(function () {
			downloadWindow.close();
		}, 3000);
	};

	useEffect(() => {
		if (formReady) {
			setPrintingPDF(true);
			document.getElementById("workflowHistory").innerHTML = "";
			window.scrollTo(0, 0);

			setTimeout(() => {
				if (document.getElementsByClassName("loaderContainer")[0]) {
					document.getElementsByClassName("loaderContainer")[0].style.display = "none";
				}

				const containerElement = document.querySelector(".container");
				const children = Array.from(containerElement.children);

				children.forEach((child) => {
					if (!child.classList.contains("content")) {
						containerElement.removeChild(child);
					}
				});

				const contentElement = document.querySelector(".content");
				const contentChildren = Array.from(contentElement.children);

				contentChildren.forEach((child) => {
					if (!child.classList.contains("container")) {
						contentElement.removeChild(child);
					}
				});

				setPrintingPDF(false);

				window.print();
				window.location.reload();
			}, 3000);
		}
	}, [formReady]);

	return (
		<div className="container" style={{ display: "flex", flexDirection: "column" }}>
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t("Process History")}</h5>
					</div>
				</div>
			</div>
			{/* {printingPDF && <Loader />} */}
			<div id="workflowHistory" className="row">
				<div className="col-12">
					{/* <h1>Workflow List</h1>*/}
					<div className={`card ${UpliftCss.BodyBorder}`}>
						<div className="card-body">
							<div className={`mb-3 ${UpliftCss.tableHeader}`}>
								<AdvanceTableWrapper columns={columns} data={tableData} sortable pagination perPage={10}>
									<Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox className={`${UpliftCss.filterFieldBoxes}`} table placeholder={t("Search")} />
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("Start Date")}
												selected={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : ""}
												maxDate={startDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleStartDateChange(date)}
											/>
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("End Date")}
												selected={endDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : ""}
												minDate={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleEndDateChange(date)}
											/>
										</Col>
									</Row>
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: "fs--1 mb-0 overflow-hidden",
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter rowCount={tableData.length} table rowInfo navButtons rowsPerPageSelection />
									</div>
								</AdvanceTableWrapper>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div id="toPDF">
				<Formioform toPDF={true} setFormReady={(e) => setFormReady(e)} withWizard={false} form={form} />
			</div> */}
			{isFinish && <LoaderIcon />}
			<div>
				<Modal isOpen={modal} keyboard={true}>
					<ModalHeader toggle={toggle}>Delete!!</ModalHeader>
					<ModalBody>
						Are you sure you want to delete? <br />
						<br />
						<span style={{ color: "red", fontWeight: "bold" }}>{task_name}</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => handleDelete()}>
							Delete
						</Button>
						<Button color="secondary" onClick={toggle}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal isOpen={modalDriver} keyboard={true}>
					<ModalHeader toggle={toggleDriver}>Edit Driver</ModalHeader>
					<ModalBody>
						<span style={{ color: "green", fontWeight: "bold" }}>{task_name}</span>
						<br />
						<br />
						Select driver to change <br />
						<br />
						<div className="mb-3">
							<select
								name="drivercompany"
								id="id_driverCompany"
								class="form-select"
								onChange={(e) => {
									//  setDriverCompany("");
									handleDropDownChange();
								}}
							>
								<option>{t(`Select Driver Company`)}</option>
								{driverCompany.map((company) => (
									<option
										//  data-driverCompany={company.transporterCompanyId}
										//   data-name={company.transporterCompanyId}
										value={company.userId}
									>
										{company.fullName}
									</option>
								))}
							</select>
						</div>
						{errorNoChange && (
							<span style={{ color: "red", fontWeight: "bold" }}>
								The chosen driver is the same as the current driver, please pick a different driver if you want to change the current driver
							</span>
						)}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => handletochangedriver()}>
							Change
						</Button>
						<Button color="secondary" onClick={toggleDriver}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
}

export default GetWorkflowListHistoryByAssignee;
