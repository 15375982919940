import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const ViewGenerator = ({ successCallBackFunction, selectedGenerator, hideViewGenerator, RelatedCompany, showComponent, allData }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [generatedWasteType, setGeneratedWasteType] = useState("");
	const [wasteType, setWasteType] = useState([]);
	const [unn, setUNN] = useState("");
	const [crNumber, setCrNumber] = useState("");
	const [address, setAddress] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isVerified, setIsVerified] = useState("");
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const [isUnique, setIsUnique] = useState(true);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	useEffect(() => {
		if (selectedGenerator) {
			setGeneratedWasteType(selectedGenerator.generatedWasteType || "");
			setWasteType(selectedGenerator.wasteType || "");
			setUNN(selectedGenerator.unn || "");
			setCrNumber(selectedGenerator.crNumber || "");
			setAddress(selectedGenerator.address || "");
			setCompanyName(selectedGenerator.companyName || "");
			setPhoneNumber(selectedGenerator.phoneNumber || "");
			setIsVerified(selectedGenerator.isVerified || false);
		}
	}, [selectedGenerator]);

	const handleCancelNew = () => {
		hideViewGenerator();
	};
	const isUNNUnique = () => {
		return !allData.some((generator) => generator.unn === unn && generator.id !== selectedGenerator.id);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none"></div>
						<div className="ms-2">{t(`View Generator`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("Generated Waste Type")}</label>
							<select
								disabled
								id="generatedWasteTypeSelect"
								value={generatedWasteType}
								className={`form-select ${generatedWasteType === "" ? "is-invalid" : ""}`}
								onChange={(e) => setGeneratedWasteType(e.target.value)}
							>
								<option value="">{t("Select Generated Waste Type")}</option>
								<option value="Hazardous Generators">{t("Hazardous Generators")}</option>
								<option value="Non-Hazardous Generators">{t("Non-Hazardous Generators")}</option>
							</select>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Company Type")}</label>
							<select value={wasteType} name="wasteTypeSelect" id="wasteTypeSelect" class="form-select" onChange={(e) => setWasteType(e.target.value)} disabled>
								<option value="" data-name="" disabled>
									{t(`Select Waste Type`)}
								</option>
								<option value="Individual" data-name="">
									{t(`Individual`)}
								</option>
								<option value="Contractor" data-name="">
									{t(`Contractor`)}
								</option>
								<option value="Engineering company" data-name="">
									{t(`Engineering company`)}
								</option>
								<option value="Delegate" data-name="">
									{t(`Delegate`)}
								</option>
								<option value="Government" data-name="">
									{t(`Government`)}
								</option>
								<option value="Other" data-name="">
									{t(`other`)}
								</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Unified National Number`)}</label>
							<input
								type="text"
								id="unn"
								className={`form-control ${unn.length === 10 ? (isUnique ? "" : "is-invalid") : unn.length > 0 ? "is-invalid" : ""}`} // Apply invalid class based on length and uniqueness
								value={unn}
								disabled
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict length to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setUNN((prevUNN) => {
											return input;
										});

										// Check uniqueness when the length reaches 10 digits
										if (input.length === 10) {
											setIsUnique(!isUNNUnique()); // Pass input to isUNNUnique function
										} else {
											setIsUnique(true); // Reset uniqueness when less than 10 digits
										}
									}
								}}
							/>
							{unn && unn.length !== 10 && <div className="invalid-feedback">Unified National Number must be exactly 10 digits long.</div>}
							{/* {unn.length === 10 && !isUnique && <div className="invalid-feedback">UNN already exists. Please enter a unique UNN.</div>} */}
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`CR Number`)}</label>
							<input
								type="text"
								id="crNumber"
								disabled
								className={`form-control ${crNumber.length === 10 ? "" : crNumber.length > 0 ? "is-invalid" : ""}`} // Add invalid class if not 10 digits
								value={crNumber}
								onChange={(event) => {
									const input = event.target.value;

									// Allow only numbers and restrict to 10 digits
									if (/^\d*$/.test(input) && input.length <= 10) {
										setCrNumber(input);
									}
								}}
							/>
							{crNumber && crNumber.length !== 10 && <div className="invalid-feedback">CR Number must be exactly 10 digits long.</div>}
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Address`)}</label>
							<input type="text" id="address" className="form-control" disabled value={address} onChange={(event) => setAddress(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(`Company Name`)}</label>
							<input type="text" id="companyName" className="form-control" disabled value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t(`Phone Number`)}</label>
							<input
								type="text"
								id="phoneNumber"
								className={`form-control ${phoneNumber.length === 10 && phoneNumber.startsWith("05") ? "" : phoneNumber.length > 0 ? "is-invalid" : ""}`} // Highlight if invalid
								value={phoneNumber}
								disabled
								onChange={(event) => {
									const input = event.target.value;

									// Allow typing any digit but only set if it's 10 digits max
									if (/^\d*$/.test(input) && input.length <= 10) {
										setPhoneNumber(input);
									}
								}}
							/>
							{phoneNumber && (!phoneNumber.startsWith("05") || phoneNumber.length !== 10) && (
								<div className="invalid-feedback">{t("Phone number must start with '05' and be exactly 10 digits long.")}</div>
							)}
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
				</div>

				{errorContainerVisiblity && (
					<div id="errorContainer" className="loginAlert popupAlert">
						{errorMessage}
					</div>
				)}
			</Modal.Footer>
		</Modal>
	);
};

ViewGenerator.propTypes = {
	showComponent: PropTypes.bool,
};

export default ViewGenerator;
