import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Translation } from "react-i18next";
import IconButton from "./common/IconButton";
import CardDropdown from "./common/CardDropdown";
import Loader from "./custom/Loader";
import * as em from "common/ErrorMessages";
import * as Constants from "common/constants";
import * as General from "common/general";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
import EditUser from "./custom/users/EditUser";
import UpdatePassword from "./custom/users/UpdatePassword";
import DeleteConfirmation from "./custom/users/DeleteConfirmation";
import AddUser from "./custom/users/AddUser";
import AssignRoles from "./custom/users/AssignRoles";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import UpliftCss from "./common/upliftcss.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ManageUsers = () => {
	const [companies, setCompanies] = useState([]);
	const [companyID, setCompanyID] = useState();
	const [onRefresh, setOnRefresh] = useState(null);
	const [t, i18n] = useTranslation();
	const location = useLocation();
	const menuName = t(location?.state?.pagename) || t("Manage User Company Users");

	const [companyUsers, setCompanyUsers] = useState([]);

	const [showEditUserPopup, setShowEditUserPopup] = useState(false);
	const [showUpdatePasswordPopUp, setShowUpdatePasswordPopUp] = useState(false);
	const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);
	const [showAssignRolesPopup, setShowAssignRolesPopup] = useState(false);

	const [showAddUserPopup, setShowAddUserPopup] = useState(false);

	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const [userId, setUserId] = useState("");
	const [enabled, setEnabled] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [idNumber, setidNumber] = useState("");
	const [idType, setIdType] = useState("");

	const [selectedCompany, setSelectedCompany] = useState("");

	const [selectedUserRoles, setSelectedUserRoles] = useState(null);

	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const userInfo = useSelector((state) => state.storedLoggedInUserInfo.userInfo);
	const handlerefresh = (value) => {
		setOnRefresh(value);
	};

	const handleShowEditUser = () => setShowEditUserPopup(true);
	const handleHideEditUser = () => setShowEditUserPopup(false);

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	useEffect(() => {
		showLoaderContainer(true);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
		// var selectedCompany = "GeneratorCompany";

		const getCompanyUsersData = async () => {
			var company = [];

			company.push({
				companyId: selectedCompany,
				companyName: selectedCompany,
			});

			setCompanies(company);
		};
		setOnRefresh(null);
		getCompanyUsersData();
		getCompanyUsers(selectedCompany);
		setCompanyID(selectedCompany);
	}, [userInfo, onRefresh]);

	const columns = [
		{
			accessor: "fullName",
			Header: t("Full Name"),
		},
		{
			accessor: "email",
			Header: t("Email"),
		},
		{
			accessor: "idType",
			Header: t("ID Type"),
		},
		{
			accessor: "idNumber",
			Header: t("ID Number"),
		},
		{
			accessor: "createdDate",
			Header: t("Created Date"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const getCompanyUsers = async (companyId) => {
		setSelectedCompany(companyId);
		let companyUsersData = await General.GetCompanyUsersData("&&companyId=eq." + companyId + "&&tbl_users.tbl_roles_users.companyId=eq." + companyId);
		var users = [];

		for (let i = 0; i < companyUsersData.length; i++) {
			users.push({
				firstName: companyUsersData[i].firstName,
				lastName: companyUsersData[i].lastName,
				idNumber: companyUsersData[i].idNumber,
				mobileNumber: companyUsersData[i].mobileNumber,
				idType: t(companyUsersData[i].idType),
				idType2: companyUsersData[i].idType,
				isEnabled: companyUsersData[i].isEnabled,
				userId: companyUsersData[i].userId,
				fullName: companyUsersData[i].fullName,
				email: companyUsersData[i].email,
				createdDate: companyUsersData[i].createdDate,
				userRoles: companyUsersData[i].rolesResponseList,
				action: (
					<>
						<div className="d-flex justify-content-end mb-2">
							<OverlayTrigger
								overlay={
									<Tooltip style={{ position: "fixed" }} id="assignRoles">
										{t(em.assign_roles)}
									</Tooltip>
								}
							>
								<div className="theme-control-toggle-label icon-position">
									<IconButton
										className={`play-hover ${UpliftCss.forceButtonColorwhite}`}
										iconClassName="fs--3"
										variant="falcon-default"
										size="sm"
										icon="fa fa-user-plus"
										style={{
											margin: "auto 0.25rem",
											backgroundColor: "#00a862",
											borderColor: "#00a862",
										}}
										onClick={() => showAssignRoles(users[i])}
									></IconButton>
								</div>
							</OverlayTrigger>

							<CardDropdown>
								<div className="py-2">
									<Dropdown.Item onClick={() => showEditUser(users[i])}>{t("Edit")}</Dropdown.Item>
									{/*
<Dropdown.Item hidden: true
onClick={() => showUpdatePassword(user)}
>
{t("Reset Password")}
</Dropdown.Item>

<Dropdown.Item
onClick={() => handleEnableDisable(user)}
>
{user.enabled ? t("Disable") : t("Enable")}
</Dropdown.Item>
*/}
									<Dropdown.Item onClick={() => showDeleteConfirmation(users[i])} className="text-danger">
										{t("Delete")}
									</Dropdown.Item>
								</div>
							</CardDropdown>
						</div>
					</>
				),
			});
		}
		setCompanyUsers(users);
		showLoaderContainer(false);
	};

	const showEditUser = (selectedUser) => {
		setFirstName(selectedUser.firstName);
		setUserId(selectedUser.userId);
		setEnabled(selectedUser.isEnabled);
		setLastName(selectedUser.lastName);
		setEmail(selectedUser.email);
		setMobileNumber(selectedUser.mobileNumber);
		setidNumber(selectedUser.idNumber);
		setIdType(selectedUser.idType2);
		setShowEditUserPopup(true);
	};

	const showUpdatePassword = (selectedUser) => {
		setFirstName(selectedUser.firstName);
		setUserId(selectedUser.userId);
		setEnabled(selectedUser.isEnabled);
		setLastName(selectedUser.lastName);
		setEmail(selectedUser.email);
		setMobileNumber(selectedUser.mobileNumber);
		setidNumber(selectedUser.idNumber);
		setIdType(selectedUser.idType);
		setShowUpdatePasswordPopUp(true);
	};

	const handleCancelEditUser = () => {
		setShowEditUserPopup(false);
	};
	const setShowModal = () => {
		setShowEditUserPopup(true);
	};

	const handleCancelUpdatePassword = () => {
		setShowUpdatePasswordPopUp(false);
	};

	const handleEnableDisable = async (user) => {
		showLoaderContainer(true);

		const EnableDisableUserSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							isSuccess = true;
							General.SaveToken(responseObject);
							this.bindUsers();
						}
					}
				}
			}
			if (isSuccess == false) {
				this.showErrorContainer(true);
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					this.showLoaderContainer(false);
				}, 1000);
			} else {
				this.showErrorContainer(false);
				this.showLoaderContainer(false);
			}
		};

		var fname = user.firstName;
		var lname = user.lastName;
		var email = user.email;
		var enabled = !user.enabled;
		var userID = user.id;
		var idType = user.idType;
		var idNumber = user.idNumber;
		var mobileNumber = user.mobileNumber;
		var EnableDisableUserEndPoint = Constants.EditUserEndPoint.concat(userID);
		var EnableDisableUserJSON =
			'{"language":"' +
			Constants.Language +
			'","userData":{ "enabled":' +
			enabled +
			' , "password":"-", "username":"", "emailVerified":true,"firstName":"' +
			fname +
			'","lastName":"' +
			lname +
			'","email":"' +
			email +
			'","additionaldata":{"MobileNumber":"' +
			mobileNumber +
			'","IDNumber":"' +
			idNumber +
			'","IDType":"' +
			idType +
			'"}' +
			"}}";
		General.PutToEndPoint(EnableDisableUserEndPoint, null, EnableDisableUserJSON, EnableDisableUserSuccess, EnableDisableUserFailure);
	};

	const showDeleteConfirmation = (selectedUser) => {
		setFirstName(selectedUser.firstName);
		setUserId(selectedUser.userId);
		setEnabled(selectedUser.isEnabled);
		setLastName(selectedUser.lastName);
		setEmail(selectedUser.email);
		setMobileNumber(selectedUser.mobileNumber);
		setidNumber(selectedUser.idNumber);
		setIdType(selectedUser.idType);
		setShowDeleteConfirmationPopup(true);
	};

	const handleCancelDelete = () => {
		setShowDeleteConfirmationPopup(false);
	};

	const showAddUser = () => {
		setShowAddUserPopup(true);
	};

	const handleCancelAddUser = () => {
		setShowAddUserPopup(false);
	};

	const showAssignRoles = (selectedUser) => {
		setFirstName(selectedUser.firstName);
		setUserId(selectedUser.userId);
		setEnabled(selectedUser.isEnabled);
		setLastName(selectedUser.lastName);
		setEmail(selectedUser.email);
		setMobileNumber(selectedUser.mobileNumber);
		setidNumber(selectedUser.idNumber);
		setIdType(selectedUser.idType);
		setSelectedUserRoles(selectedUser.userRoles);
		setShowAssignRolesPopup(true);
	};

	const handleCancelAssignRoles = () => {
		setShowAssignRolesPopup(false);
	};

	return (
		<Translation>
			{(t, { i18n }) => (
				<div>
					<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
						<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
							<div className="col-md">
								<h5 className="mb-2 mb-md-0">{t(menuName)}</h5>
							</div>
						</div>
					</div>
					<div className={`card ${UpliftCss.BodyBorder}`}>
						{
							<div className="card-body">
								<div className="filterSection">
									<div>
										<label
											className="form-label"
											style={{
												display: "flex",
												justifyContent: "center",
												margin: "auto",
												fontWeight: "bold",
												fontSize: "30px",
												borderRadius: "5px",
												padding: "0 8px",
											}}
										>
											{t(companyID)}
										</label>
										{/*<label className="form-label">{t("Select company")}:</label>     
                    <select
                      name="productCategory"
                      id="dropdownCompanyType"
                      className={"form-select"}
                      value={companyID}
                      onChange={(e) => {
                        getCompanyUsers(e.target.value);
                        setCompanyID(e.target.value);
                      }}
                      readonly
                      disabled
                    >
                      {companies.map((company) => (
                        <option
                          value={company.companyId}
                          key={company.companyId}
                        >
                          {company.companyName}
                        </option>
                      ))}
                    </select>*/}
										<div className="invalid-feedback"></div>
									</div>
								</div>
							</div>
						}

						<div className="tool-header">
							<OverlayTrigger
								overlay={
									<Tooltip style={{ position: "fixed" }} id="newuser">
										{t(em.new_user)}
									</Tooltip>
								}
							>
								<div className="theme-control-toggle-label icon-position end-text">
									<IconButton
										className="btn-primary botton-circle"
										iconClassName="fs--5"
										size="sm"
										icon="fa-solid fa-circle-plus"
										style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
										onClick={showAddUser}
									></IconButton>
								</div>
							</OverlayTrigger>
						</div>
						<div className="card">
							<div className="card-body">
								<div className={`mb-3 ${UpliftCss.tableHeader}`}>
									<AdvanceTableWrapper columns={columns} data={companyUsers} sortable pagination perPage={10}>
										<Row className="flex-end-center mb-3">
											<Col xs="auto" sm={6} lg={4}>
												<AdvanceTableSearchBox className={`${UpliftCss.filterFieldBoxes}`} table placeholder={t("Search")} />
											</Col>
										</Row>
										<AdvanceTable
											table
											headerClassName="bg-200 text-900 text-nowrap align-middle"
											rowClassName="align-middle white-space-nowrap"
											tableProps={{
												bordered: true,
												striped: true,
												className: "fs--1 mb-0 overflow-hidden",
											}}
										/>
										<div className="mt-3">
											<AdvanceTableFooter rowCount={companyUsers.length} table rowInfo navButtons rowsPerPageSelection />
										</div>
										{/* <div className="mt-3">
									<AdvanceTablePagination table />
								</div> */}
									</AdvanceTableWrapper>
								</div>
							</div>
						</div>

						<div className="table-responsive">
							{loaderContainerVisibility ? <Loader></Loader> : ""}

							{showEditUserPopup && (
								<EditUser
									dropdownVisible={false}
									getCompanyUsers={() => getCompanyUsers(companyID)}
									successCallBackFunction={handlerefresh}
									hideEditUser={handleCancelEditUser}
									showComponent={showEditUserPopup}
									userID={userId}
									enabled={enabled}
									firstName={firstName}
									lastName={lastName}
									email={email}
									mobileNumber={mobileNumber}
									IDNumber={idNumber}
									IDType={idType}
									showModal={showEditUserPopup}
									setShowModal={setShowEditUserPopup}
								></EditUser>
							)}
							{showUpdatePasswordPopUp && (
								<UpdatePassword
									successCallBackFunction=""
									hideUpdatePassword={handleCancelUpdatePassword}
									showComponent="{showUpdatePasswordPopUp}"
									userID={userId}
									email={email}
								></UpdatePassword>
							)}

							{showDeleteConfirmationPopup && (
								<DeleteConfirmation
									companyId={selectedCompany}
									getCompanyUsers={() => getCompanyUsers(companyID)}
									successCallBackFunction="{this.bindUsers}"
									hideDeleteConfirmation={handleCancelDelete}
									showComponent={showDeleteConfirmationPopup}
									userID={userId}
									IDNumber={idNumber}
									name={firstName + " " + lastName}
									email={email}
									keycloackCompanyId={encryptStorage1.getItem(Constants.SelectedKeycloakCompany)}
								></DeleteConfirmation>
							)}

							{showAddUserPopup && (
								<AddUser
									getCompanyUsers={() => getCompanyUsers(companyID)}
									successCallBackFunction="{this.bindUsers}"
									hideAddUser={handleCancelAddUser}
									showComponent={showAddUserPopup}
									companyId={selectedCompany}
									keycloakCompanyId={encryptStorage1.getItem(Constants.SelectedKeycloakCompany)}
								></AddUser>
							)}

							{showAssignRolesPopup && (
								<AssignRoles
									successCallBackFunction="{this.bindUsers}"
									companyId={selectedCompany}
									userRoles={selectedUserRoles}
									getCompanyUsers={() => getCompanyUsers(companyID)}
									hideAssignRoles={handleCancelAssignRoles}
									showComponent={showAssignRolesPopup}
									userID={userId}
									firstName={firstName}
									lastName={lastName}
									email={email}
								></AssignRoles>
							)}
						</div>
					</div>
				</div>
			)}
		</Translation>
	);
};

export default ManageUsers;
