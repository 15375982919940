import React, { useEffect, useRef, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from "react-i18next";
import settingsForm from "./PhotoCapture.settingsForm";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import moment from "moment";
import { createRoot } from "react-dom/client";
import { getSystemPropertyValue } from "commonFunctions";
/**
 This component allows user to capture photo (back camera or front) with additional information like the date and time the photo taken and 
 the location coordinates (latitude and longitude) of the user when taking it
 */

const PhotoCapture = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options, });

	const [t, i18n] = useTranslation();
	const errorRef = useRef();
	//stores user stream
	const imageRef = React.createRef();

	const [isModalOpen, setModalOpen] = useState(false);
	const [baseUrlField, setBaseUrlField] = useState("");
	const [coordinatesX, setCoordinatesX] = useState("");
	const [coordinatesY, setCoordinatesY] = useState("");
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [isLimitedCupature, setIsLimitedCupature] = useState();

	const handleNextImage = () => {
		const nextIndex = (currentImageIndex + 1) % myState.images.length;
		setCurrentImageIndex(nextIndex);
		setBaseUrlField(myState.images[nextIndex].imageRef);
		setCoordinatesX(myState.images[nextIndex].coordinates.latitude);
		setCoordinatesY(myState.images[nextIndex].coordinates.longitude);
	};

	const handlePreviousImage = () => {
		const prevIndex = (currentImageIndex - 1 + myState.images.length) % myState.images.length;
		setCurrentImageIndex(prevIndex);
		setBaseUrlField(myState.images[prevIndex].imageRef);
		setCoordinatesX(myState.images[prevIndex].coordinates.latitude);
		setCoordinatesY(myState.images[prevIndex].coordinates.longitude);
	};
	const handleDeleteImage = () => {
		if (myState.images.length === 0) return;

		const updatedImages = [...myState.images];
		updatedImages.splice(currentImageIndex, 1); // Remove the current image

		

		if (updatedImages.length > 0) {
			const nextIndex = currentImageIndex % updatedImages.length;
			setCurrentImageIndex(nextIndex);
			setBaseUrlField(updatedImages[nextIndex].imageRef);
			setCoordinatesX(updatedImages[nextIndex].coordinates.latitude);
			setCoordinatesY(updatedImages[nextIndex].coordinates.longitude);
		} else {
			
			handleCloseModal();
		}

		
		myState.images = updatedImages; // Update your state object
		setIsLimitedCupature(true);
		setMyState(myState);
		props.setComponentState(myState);
	};
	const handleOpenModal = () => {
		setModalOpen(true);
		if (myState["imageUrl"] != undefined && myState["imageUrl"] != "") {
			setBaseUrlField(myState["imageUrl"]);
			setCoordinatesX(JSON.parse(myState["coordinates"]).latitude);
			setCoordinatesY(JSON.parse(myState["coordinates"]).longitude);
		} else {
			if (myState.images[0].imageRef) {
				setBaseUrlField(myState.images[0].imageRef);
				setCoordinatesX(myState.images[0].coordinates.latitude);
				setCoordinatesY(myState.images[0].coordinates.longitude);
				setCurrentImageIndex(0); // Initialize index
			} else {
				const defaultData = JSON.parse(props.form._form.settings.data[props.component.key]);
				setBaseUrlField(defaultData.imageUrl);
				setCoordinatesX(JSON.parse(defaultData.coordinates).latitude);
				setCoordinatesY(JSON.parse(defaultData.coordinates).longitude);
				setCurrentImageIndex(0); // Initialize index
			}

		}

	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const gotToMaps = () => {
		var mapUrl = `https://www.google.com/maps/place/${coordinatesX},${coordinatesY}`;
		window.open(mapUrl, '_blank');
	}

	//on start camera button get user camera and save it in imageRef
	const getVideo = async () => {
		let state = { ...myState };
		if (state.startCamera === true) {
			state.imageRef.getTracks().forEach(track => track.stop());
			imageRef.current.srcObject = null;
			state.imageRef = "";
			state.thumbnail = "";
			state.startCamera = false;
		}
		var stream = null;

		if (imageRef.current) {
			try {
				// Initialize the video stream
				stream = await navigator.mediaDevices.getUserMedia({
					video: { facingMode: state["facingMode"] },
				});

				if (stream.active) {
					// Set the stream to the video element
					imageRef.current.srcObject = stream;

					// Add an event listener to know when the video is ready
					imageRef.current.onloadedmetadata = () => {
						imageRef.current.play(); // Start playing the video
						state.imageRef = stream;
						state.startCamera = true;
						setMyState(state);
						props.setComponentState(state);

					};
				}
			} catch (e) {
				console.error("Error accessing camera:", e);
				errorRef.current.innerText = t("Camera permission is required.");
			}
		} else {
			errorRef.current.innerText = t(
				"Location coordinates are invalid. Please provide valid coordinates."
			);
		}
	};

	//on clicking flip camera, camera direction is flipped (either back or front)
	const flipCamera = async (mode) => {
		let state = { ...myState };
		let oldStream = state.imageRef;
		state.imageRef.getTracks().forEach(track => track.stop());

		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: mode } });
			const prevTrack = oldStream.getVideoTracks()[0];
			const newTrack = stream.getVideoTracks()[0];
			oldStream.removeTrack(prevTrack);
			oldStream.addTrack(newTrack);
			state.newStream = stream;
			state.imageRef = oldStream;
			state.facingMode = mode;
			setMyState(state);
			props.setComponentState(state);
		} catch (e) {
			//  console.log(e);
			//  errorRef.current.innerText = t("Failed to flip camera.");
		}
	};
	//stop user stream by stopping all its tracks
	const stopCamera = () => {
		let state = { ...myState };
		state.showModal = false;
		if (state.startCamera === true) {
			state.imageRef.getTracks().forEach(track => track.stop());
			imageRef.current.srcObject = null;
			state.imageRef = "";
			state.thumbnail = "";
			state.startCamera = false;
		}
		state.datetime = "";
		setMyState(state);
		props.setComponentState(state);
	};

	useEffect(() => {
		// Retrieve the initial value from the component's data or default value
		if (props.form._data[props.component.key] && props.form._data[props.component.key].images) {
			let initialValue = props.form._data[props.component.key].images || props.component.defaultValue;

			if (initialValue) {
				// Initialize a new state object with the existing state
				let state = { ...myState };

				// Handle the initialValue as an array of images
				let images = Array.isArray(initialValue) ? initialValue : [initialValue];

				// Populate the state with the images array
				state.images = images.map((image) => ({
					imageUrl: image.imageUrl,
					imageRef: image.imageRef,
					thumbnail: image.thumbnail,
					datetime: image.datetime,
					typestorage: props.component.typestorage,
					withThumbnail: props.component.isSetThumbnail,
					resolutionThumbnail: props.component.fieldResolution,
					folderPath: props.component.folderField,
					thumbnailWidth:image.thumbnailWidth,
					thumbnailHeight:image.thumbnailHeight,
					coordinates: image.coordinates,
				}));

				// Set the updated state
				setMyState(state);

				// Optionally, propagate the component state
				props.setComponentState(state);
				props.onChange(state, null);
			}
		}
	
	  }, []);
	  


	//get location coordinates of the user who's capturing the photo
	const takePhoto = async () => {
		let state = { ...myState };

		if (navigator.geolocation) {
			const options = {
				enableHighAccuracy: false,
				maximumAge: Infinity,
			};

			function success(pos) {
				const crd = pos.coords;
				state.coordinates = {
					latitude: crd.latitude,
					longitude: crd.longitude,
				};

				navigator.geolocation.clearWatch(id);

				capturePhotoFunction(imageRef.current, state);
			}

			function error(err) {
				//	console.warn(`ERROR(${err.code}): ${err.message}`);
				state.coordinates = {
					latitude: -1,
					longitude: -1,
				};
				capturePhotoFunction(imageRef.current, state);
			}

			let id = navigator.geolocation.watchPosition(success, error, options);
		} else {
			state.coordinates = {
				latitude: -1,
				longitude: -1,
			};
			capturePhotoFunction(imageRef.current, state);
		}
	};

	//this function is used to capture photo of the user
	const capturePhotoFunction = async (imageRef, state) => {
		state.showModal = false;
		state.startCamera = false;
		const canvas = document.createElement("canvas");
		var thumbnailDataURL = "";
		var thumbnailWidth = 0;
		var thumbnailHeight = 0;
	
		canvas.width = imageRef.videoWidth;
		canvas.height = imageRef.videoHeight;
	
		// Get the 2D drawing context
		const context = canvas.getContext("2d");
	
		// Draw the video frame on the canvas
		context.drawImage(imageRef, 0, 0);
	
		const currentDateTime = moment().format("DD-MM-YYYY HH:mm:ss");
		const dateTimeText = `Date: ${currentDateTime}`;
		// Write coordinates on the image
		const text = `Lat: ${state.coordinates.latitude || "N/A"}, Lng: ${state.coordinates.longitude || "N/A"}`;
		context.font = "20px Cairo-Regular"; // Set font size and family
		context.fillStyle = "white"; // Text color
		context.fillText(text, 10, 30); // Write text at the specified position (x: 10, y: 30)
		context.fillText(dateTimeText, 10, 55);    // Date/time on second line
	
		// Convert the canvas to a data URL
		const imageDataURL = canvas.toDataURL("image/jpeg", parseFloat(state["quality"]));
	
		if (props.component.isSetThumbnail) {
			// Generate a thumbnail
			const thumbnailCanvas = document.createElement("canvas");
			thumbnailWidth = 200; // Double the resolution
			thumbnailHeight = (thumbnailWidth * imageRef.videoHeight) / imageRef.videoWidth; // Maintain aspect ratio
			thumbnailCanvas.width = thumbnailWidth;
			thumbnailCanvas.height = thumbnailHeight;
			
			const thumbnailContext = thumbnailCanvas.getContext("2d", { alpha: false });
			// Set text properties before any drawing
			thumbnailContext.font = "16px Cairo-Regular"; // Double the font size for the higher resolution
			thumbnailContext.textBaseline = "top";
			thumbnailContext.imageSmoothingEnabled = true;
			thumbnailContext.imageSmoothingQuality = "high";
			
			const thumbCoordText = `${state.coordinates.latitude || "N/A"},${state.coordinates.longitude || "N/A"}`;
			const thumbDateText = currentDateTime;
			
			// Draw the image
			thumbnailContext.drawImage(imageRef, 0, 0, thumbnailWidth, thumbnailHeight);
			
			// Draw text
			thumbnailContext.fillStyle = "white";
			thumbnailContext.fillText(thumbCoordText, 8, 8);
			thumbnailContext.fillText(thumbDateText, 8, 32);
			
			thumbnailDataURL = thumbnailCanvas.toDataURL("image/jpeg", (props.component.fieldResolution / 100));
		}
		const imageMetadata = {
			imageRef: imageDataURL,
			thumbnail: thumbnailDataURL,
			datetime: moment().format("DD-MM-YYYY hh:mm:ss"),
			typestorage: props.component.typestorage,
			withThumbnail: props.component.isSetThumbnail,
			resolutionThumbnail: props.component.fieldResolution,
			folderPath: props.component.folderField,
			thumbnailWidth,
			thumbnailHeight,
			coordinates: { latitude: state.coordinates.latitude, longitude: state.coordinates.longitude },
		};
	
		state.images = state.images || []; // Initialize array if not present
		state.images.push(imageMetadata); // Add new image
	
		// Update state and props
		setMyState(state);
		props.setComponentState(state);
	
		// Call onChange with updated images
		props.onChange(state, null);
	};
	


	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	useEffect(() => {
		let state = { ...myState };

		//always make sure form data has the component value
		if (props.component.validate.required && state.imageRef !== "") {
			props.onChange(state, null);
		}
	}, [props.form]);
	const hideModal = () => {
		let state = { ...myState };
		state.showModal = false;
		if (myState.startCamera === true) {
			myState.imageRef.getTracks().forEach(function (track) {
				track.stop();
			});
			imageRef.current.srcObject = null;
			state.imageRef = "";
			state.startCamera = false;
			setMyState(state);
			props.setComponentState(state);
		} else {
			setMyState(state);
			props.setComponentState(state);
		}
	};
	const isImageTaken = () => {

		return (myState["imageRef"] != undefined) ? myState["imageRef"].toString().startsWith("data") : myState["thumbnail"].toString().startsWith("data");
	};
	const capturePhoto = () => {
		let state = { ...myState };
		state.showModal = true;
		setMyState(state);
		props.setComponentState(state);
	};
	const myModal = (
		<Modal
			show={myState.showModal}
			onHide={() => {
				hideModal();
			}}
			fullscreen={true}
		>
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="ms-2">{t("Capture Photo")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.disabled !== true && (
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							display: myState["startCamera"] ? "block" : "none",
						}}
					>
						<div
							title={"Camera Mode"}
							style={{
								position: "absolute",
								right: 0,
								top: 0,
								cursor: "pointer",
								zIndex: 5,
							}}
							onClick={() => {
								flipCamera(myState["facingMode"] === "user" ? "environment" : "user");
							}}
						>
							<FontAwesomeIcon icon={"rotate"} />
						</div>
						<video autoPlay={true} playsInline={true} muted={true} width={"100%"} height={"100%"} style={{ objectFit: "fill" }} ref={imageRef} />
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{props.disabled !== true && (
					<div className="d-flex w-100 justify-content-between">
						<div></div>

						<button
							className="btn btn-primary btn-wizard-nav-submit takephoto"
							title={myState["startCamera"] ? t("Take Photo") : t("Retake Photo")}
							style={{ marginTop: "10px" }}
							onClick={() => takePhoto()}
						>
							{" "}
							<FiIcons.FiCamera />
						</button>
					</div>
				)}
				<div ref={errorRef} id="photo-error"></div>
			</Modal.Footer>
		</Modal>
	);

	useEffect(() => {
		if (myState["startCamera"] === false) {
			if (myState["showModal"] === true) {
				getVideo();
			}
		}
		if (myState?.images?.length >= props.component.fieldNumberOfImages) {
			setIsLimitedCupature(false);
		} else {
			setIsLimitedCupature(true);
		}

		if (myState?.images?.length >= props.component.fieldNumberOfImages) {
			setIsLimitedCupature(false);
		} else {
			setIsLimitedCupature(true);
		}
	}, [myState["startCamera"], myState["imageRef"], myState["showModal"]]);


	return (
		<>
			<div className="photoCapture">
				{myModal}
				<div style={{ position: "relative" }}>
					{!props.component.isHideOriginal && isImageTaken() && (
						<ul
							style={{
								paddingRight: "15px",
								backgroundColor: "black",
								opacity: 0.7,
								position: "absolute",
								top: "5px",
								left: "5px",
								color: "#fff",
							}}
						>
							<li>{myState.datetime}</li>
							{myState.coordinates?.longitude !== -1 && <li>{myState.coordinates.longitude + "," + myState.coordinates.latitude}</li>}
						</ul>
					)}
					{!props.component.isHideOriginal && isImageTaken() && <img width="100%" height="100px" style={{ marginTop: "5px" }} src={myState["imageRef"]} />}
				</div>

				{ }
				{props.component.isSetThumbnail && myState.images && myState.images.length > 0 && (
					<div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
						{myState.images.map((image, index) => (
							<img
								key={index}
								role="button"
								width="100px"
								height="100px"
								src={image.thumbnail}
								alt={`Thumbnail ${index + 1}`}
								onClick={() => handleOpenModal(image)} // Pass the image data to the modal handler
							/>
						))}
					</div>
				)}
				{props.disabled !== true && (
					<>
						{isLimitedCupature && (
							<button
								title={t("Capture Photo")}
								className="btn btn-primary btn-wizard-nav-submit"
								onClick={() => {
									capturePhoto();
								}}
								style={{ marginTop: "10px" }}
							>
								<FiIcons.FiCamera />{" "}
							</button>)}
					</>
				)}


			</div>

			<Modal
				show={isModalOpen}
				onHide={handleCloseModal}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("fullImage")}</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Container>
						<Row className="align-items-center">
							{/* Previous Icon */}
							<Col md="1" className="text-center">
								<FontAwesomeIcon
									icon={i18n.language == "en" ? "fa-solid fa-chevron-left" : "fa-solid fa-chevron-right"}
									size="2x"
									style={{ cursor: "pointer", color: "#00a862" }}
									onClick={handlePreviousImage}
								/>
							</Col>

							{/* Image */}
							<Col md="10" className="text-center">
								<img
									style={{ maxWidth: "100%", maxHeight: "500px" }}
									src={baseUrlField}
									alt={t("fullImageNotFound")}
								/>
							</Col>

							{/* Next Icon */}
							<Col md="1" className="text-center">
								<FontAwesomeIcon
									icon= {i18n.language == "en" ? "fa-solid fa-chevron-right" : "fa-solid fa-chevron-left"}
									size="2x"
									style={{ cursor: "pointer", color: "#00a862" }}
									onClick={handleNextImage}
								/>
							</Col>
						</Row>
						{/* Delete Button */}
						<Row className="mt-3">
							<Col md={12} className="text-center">
								<button
									className="btn btn-danger"
									onClick={handleDeleteImage}
								>
									{t("deleteImage")}
								</button>
							</Col>
						</Row>

						{/* Additional Information */}
						<Row className="mt-3">
							<Col md={12}>
								<ul>
									<li>{t("latitude")}: {coordinatesX}</li>
									<li>{t("longitude")}: {coordinatesY}</li>
									<li role="button" onClick={gotToMaps}>
										<FontAwesomeIcon
											icon="fa-solid fa-map-location-dot"
											size="lg"
											style={{ color: "#00a862" }}
										/>
									</li>
								</ul>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary" onClick={handleCloseModal}>
						{t("close")}
					</button>
				</Modal.Footer>
			</Modal>


		</>
	);
}

export default class Photo extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */
	constructor(component, options, data) {
		options["oldState"] = {
			targetWidth: 640,
			quality: getSystemPropertyValue("imageQuality"),
			imageRef: "",
			facingMode: "environment",
			isBack: false,
			startCamera: false,
			newStream: "",
			showModal: false,
			datetime: "",
			coordinates: { latitude: -1, longitude: -1 },
			showDetails: false,
			imageUrl: "",
			thumbnail: "",
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "photoCaptureCustomComp",
			label: "Capture Photo",
		});
	}
	static get builderInfo() {
		return {
			title: "PhotoCaptureNew",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Photo.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const root = createRoot(element);
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		let disabled = this.component.disabled;

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}

			},
			true
		);
		Utils.eachComponent(
			rootForm?.currentPanel?.components,
			function (component) {
				if (component.components) {
					Utils.eachComponent(
						component.components,
						function (component) {

							if (component.input) {
								if (component.key === key) {
									disabled = component.disabled
								}
							} else if (component.type === "columns") {
								Utils.eachComponent(
									component.columns,
									function (component2) {
										if (component2.input) {
											if (component2.key === key) {
												disabled = component2.disabled


											}
										}
									},
									true
								);
							}

						},
						true
					);
				}
				else {
					if (component.columns) {
						Utils.eachComponent(
							component.columns,
							function (component2) {
								if (component2.input) {
									if (component2.key === key) {

										disabled = component2.disabled


									}
								}
							},
							true
						);
					}
					else {
						if (component.input) {
							if (component.key === key) {
								disabled = component.disabled
							}
						}
					}
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);

			root.render(
				<PhotoCapture
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					disabled={disabled}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		};

		root.render(
			<PhotoCapture
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				disabled={disabled}
				form={rootForm}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}
}
