import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
	return baseEditForm(
		[
			{
				label: "Display",
				key: "display",
				weight: 0,
				components: [],
			},
			{
				label: "Data",
				key: "data",
				weight: 20,
				components: [
					{
						type: "textfield",
						label: "Request Url",
						key: "requestUrl",
						input: true,
						inline: true,
						validate: {
							required: true,
						},
					},
					{
						type: "textfield",
						label: "Data Path",
						key: "dataPath",
						input: true,
						inline: true,
					},
				
					{
						type: "textfield",
						label: "Label Path",
						key: "labelPath",
						input: true,
						inline: true,
						validate: {
							required: true,
						},
					},
					{
						type: "textfield",
						label: "Value Path",
						key: "valuePath",
						input: true,
						inline: true,
						validate: {
							required: true,
						},
					},
					{
						type: "textfield",
						label: "Initial Value",
						key: "initialValue",
						input: true,
						inline: true,
					},
					{
						type: "editgrid",
						label: "Components To fill",
						key: "componentsToFill",
						components: [
							{
								type: "textfield",
								label: "Field API",
								key: "fieldApi",
								input: true,
								inline: true,
								validate: {
									required: true,
								},
							},
						],
					},
					{
						type: "checkbox",
						label: "Add the 'others' option",
						key: "isOthers",
						input: true,
						inline: true,

					},
					
					{
						type: "checkbox",
						label: "Translate Data",
						key: "translateData",
						input: true,
						inline: true,
					},
					{
						type: "editgrid",
						label: "Keys To Add From Response",
						key: "keysToAdd",
						components: [
							{
								type: "textfield",
								label: "Field API",
								key: "fieldApi",
								input: true,
								inline: true,
								validate: {
									required: true,
								},
							},
						],
					},
					{
						type: "textfield",
						label: "Related Grid Api Key",
						key: "relatedGrid",
						input: true,
						inline: true,
					},
					{
						type: "textfield",
						label: "Initial Value",
						key: "initialValue",
						input: true,
						inline: true,
					},
					{
                        type: "checkbox",
                        label: "Add the DefaultValue option: 'initial value the first option in the list'",
                        key: "isDefaultValue",
                        input: true,
                        inline: true
                    },
                    {
                        type: "number",
                        label: "Default Value",
                        key: "fieldDefaultValue",
                        input: true,
                        inline: true,
                        defaultValue: 0,
                        conditional: {
                            json: {
                                "===": [
                                    { var: "data.isDefaultValue" }, true
                                ]
                            }
                        }
                    }
				],
			},
			{
				label: "Validation",
				key: "validation",
				weight: 20,
				components: [],
			},
			{
				label: "API",
				key: "api",
				weight: 30,
				components: [],
			},
			{
				label: "Logic",
				key: "logic",
				weight: 50,
				components: [],
			},
			{
				label: "Layout",
				key: "layout",
				weight: 60,
				components: [],
			},
		],
		...extend
	);
};
